<template>
  <div>
  <img v-for="img in props.images" :src="img.src" :alt="img.alt">
  </div>
</template>

<script setup>

const props = defineProps(
    {
      images: {
        type: Array,
        required: true
      }
    }
)

</script>
