// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.fa-check-circle {
  color: green;
}
.fa-times-circle {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/TickOrCross.vue"],"names":[],"mappings":";AAmBA;EACE,YAAY;AACd;AAEA;EACE,UAAU;AACZ","sourcesContent":["<template>\n  <i :class=\"{\n    'fa-check-circle': tick,\n    'fa-times-circle': !tick\n  }\" class=\"fa\"></i>\n</template>\n\n<script setup>\nconst props = defineProps(\n    {\n      tick: {\n        type: Boolean,\n        required: true\n      }\n    }\n)\n</script>\n\n<style>\n.fa-check-circle {\n  color: green;\n}\n\n.fa-times-circle {\n  color: red;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
