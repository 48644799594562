import tickCross from "tabulator-tables/src/js/modules/Format/defaults/formatters/tickCross";
import xero from "@/assets/icons/xero.png";


export default function (cell, formatterParams, onRendered) {
  const result = tickCross(cell, formatterParams, onRendered);

  if (cell.getElement().getAttribute('aria-checked') !== 'true') {
    return result;
  }

  const el = document.createElement('div');


  function link() {

    // const svg = '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16px" height="16px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink"><path style="opacity:0.986" fill="#09b8e3" d="M 4.5,-0.5 C 6.5,-0.5 8.5,-0.5 10.5,-0.5C 12.5554,0.558761 13.8888,2.22543 14.5,4.5C 14.7678,6.09903 14.4345,7.43236 13.5,8.5C 13.516,6.51595 12.516,5.51595 10.5,5.5C 7.88311,5.54394 5.21644,5.87727 2.5,6.5C 1.6587,5.99065 1.1587,6.65732 1,8.5C 0.50999,7.20677 0.343323,5.87344 0.5,4.5C 1.11123,2.22543 2.44456,0.558761 4.5,-0.5 Z"/><path style="opacity:0.991" fill="#17b9e3" d="M -0.5,4.5 C -0.166667,4.5 0.166667,4.5 0.5,4.5C 0.343323,5.87344 0.50999,7.20677 1,8.5C 1.1587,6.65732 1.6587,5.99065 2.5,6.5C 2.484,8.48405 3.484,9.48405 5.5,9.5C 8.24923,8.80665 10.9159,8.47332 13.5,8.5C 14.4345,7.43236 14.7678,6.09903 14.5,4.5C 14.8333,4.5 15.1667,4.5 15.5,4.5C 15.5,6.5 15.5,8.5 15.5,10.5C 14.1667,12.5 12.5,14.1667 10.5,15.5C 8.5,15.5 6.5,15.5 4.5,15.5C 2.5,14.1667 0.833333,12.5 -0.5,10.5C -0.5,8.5 -0.5,6.5 -0.5,4.5 Z"/><path style="opacity:0.98" fill="#8bdcf0" d="M 13.5,8.5 C 10.9159,8.47332 8.24923,8.80665 5.5,9.5C 3.484,9.48405 2.484,8.48405 2.5,6.5C 5.21644,5.87727 7.88311,5.54394 10.5,5.5C 12.516,5.51595 13.516,6.51595 13.5,8.5 Z"/></svg>'

    const a = document.createElement('a');
    a.href = cell.getValue();
    a.target = '_blank';

    const img = document.createElement('img');
    img.src = xero;
    a.appendChild(img);

    return a;
  }

  el.innerHTML = result;
  el.appendChild(link())

  return el;
}
