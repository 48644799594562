<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      class="companyOperatorLicensesPanel"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <a :href="link" class="companyOperatorLicensesPanel__link">
      <TrafficLightDisplay
          :footer="`${vehiclesAuthorised} / ${vehiclesSpecified}`"
          :rgb="rgb"
          header="Operator Licences"
      >
        <template v-slot:content>
          <div class="big">{{ trailersAuthorised }}</div>
          <div>{{ licenceType }}</div>
        </template>
      </TrafficLightDisplay>
    </a>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import TrafficLightDisplay from "@/components/TrafficLightDisplay.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const rgb = ref('rgb(255, 255, 255)');
const trailersAuthorised = ref(0);
const licenceType = ref('');
const vehiclesAuthorised = ref(0);
const vehiclesSpecified = ref(0);
const link = ref('#');

const emit = defineEmits(['removed']);

const {hydrate, hydrated} = useHydration(({
                                            rgb: _rgb,
                                            trailersAuthorised: _trailersAuthorised,
                                            licenceType: _licenceType,
                                            vehiclesAuthorised: _vehiclesAuthorised,
                                            vehiclesSpecified: _vehiclesSpecified,
                                            link: _link,
                                          }) => {
  rgb.value = _rgb;
  trailersAuthorised.value = _trailersAuthorised;
  licenceType.value = _licenceType;
  vehiclesAuthorised.value = _vehiclesAuthorised;
  vehiclesSpecified.value = _vehiclesSpecified;
  link.value = _link;
});

</script>

<style lang="scss">
.companyOperatorLicensesPanel {
  &__link {
    display: block;
    text-decoration: none;
  }
}
</style>
