import eventBus from "@/_core/services/eventBus";

export default function (args, cell, table) {
  let filters = [];
  args.forEach((filterItem) => {
    let value = cell.getData()[filterItem.valueField];
    value = typeof value === 'object' && value.hasOwnProperty('date') ? value.date.substring(0,10) : value;
    filters.push({
      column: filterItem.targetField,
      value: filterItem.hasOwnProperty('column') ? cell.getField() : value,
      type: filterItem.type
    });
  });

  eventBus.$emit('tabulator:filter', filters)
}
