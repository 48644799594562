<template>
    <div class="text-block">
        <ContentTitle :level="data.level" :title="data.title"></ContentTitle>
        <p>{{data.text}}</p>
    </div>
</template>
<script>
    import ContentTitle from '../elements/ContentTitle.vue';
    export default {
        name: 'textBlock',
        props : ['data'],
        components: {
            ContentTitle
        },
        data: function () {
            return {}
        },
    }
</script>