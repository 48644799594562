<template>
  <article class="dashboardPanel dashboardPanel--tabbed">
    <header class="dashboardPanel__header">
      <nav class="dashboardPanel__tabs">
        <div v-for="{id, props: {title}} in tabs" :key="id" class="dashboardPanel__tab">
          <button
              :class="{active: activeTab.id === id}"
              type="button"
              @click="setActiveTab(id)"
          >{{ title }}
          </button>
        </div>
      </nav>
    </header>
    <section class="dashboardPanel__contents">
      <Component :is="activeTab.name" :components="activeTab.components" noChrome=true v-bind="activeTab.props"/>
    </section>
  </article>
</template>
<script setup>
import {ref} from "vue";

const props = defineProps({
  tabs: {
    type: Array,
    default: [],
  },
});

const activeTab = ref(
    props.tabs[0]
);

const setActiveTab = (_id) => {
  activeTab.value = props.tabs.find(({id}) => id === _id);
};

const emit = defineEmits(['removed']);
</script>

<style lang="scss" scoped>
.dashboardPanel {
  &--tabbed {
    background-color: transparent;
    box-shadow: none;
  }

  &__contents {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.6);
  }

  &__header {
    background-color: transparent;
    padding: 0;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #000;

  }

  &__tab {
    height: 100%;

    button {
      padding: 0 12px;
      border: 0;
      border-radius: 0.5rem 0.5rem 0 0;
      background-color: #666;
      color: #ffffff;
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.6);

      &.active {
        background-color: #000;
        border-right: 2px solid #333;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.6);
      }

      &:hover {
        background-color: #333;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px var(--color-blue-100);
      }
    }
  }
}

</style>
