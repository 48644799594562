<template>
    <div class="content-table" :class="getClasses()">
        <div class="text-center" v-if="title !== ''">
            <h3>{{title}}</h3>
        </div>
        <table class="table">
            <tbody>
                <tr v-for="row in rows">
                    <td v-for="(value, field) in row" :colspan="row.length === 1 ? 2 : 1">
                        <span v-if="typeof value === 'object' && value != null">
                                <router-link v-if="value.href" v-bind:to="value.href" v-html="value.title"></router-link>
                                <component v-if="value.component" v-bind:props="value.component.props" :components="value.component.components" :class="value.component.classes" :is="value.component.name"></component>
                            </span>
                        <span v-else v-html="value"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
  import { commonProperties } from '../../mixins/commonProperties'

    export default {
        name: 'ContentTable',
        mixins: [commonProperties],
        watch: {
            rows (to, from){
                this.rows = to;
            },
        },
        methods: {
            initialState (){
                return {
                  rows: [],
                  classes: '',
                  title: ''
                }
            },
        },
        created() {
            this.rows = this.componentData.rows;
            this.title = this.componentData.title;
        }
    }
</script>
<style lang="scss">
</style>