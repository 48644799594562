<template>
  <div :class="classResolver(classes)">
    <div v-if="hasGeneralSearch" id="general-search-container" class="generalSearch">
      <label class="generalSearch__label" for="general-search">
        General Search
      </label>
      <input id="general-search" autofocus="autofocus" class="generalSearch__input" placeholder="General search..."
             type="search" @input="handleGeneralSearch"/>
    </div>
    <div :id="componentId" ref="tabulatorElement" class="table-sm thead-dark"/>
    <ItemSelector
        v-if="canSelectColumns"
        v-model:active="activeColumns"
        :available="availableColumns"
        title="Columns"
    />

  </div>
</template>
<script>
import {computed, onBeforeUnmount, onMounted, onUpdated, ref, watch} from "vue";
import Router from "@/_core/router";
import TabulatorColumnMapper from "@/services/TabulatorColumnMapper";
// noinspection ES6CheckImport
import Tabulator from 'tabulator-tables/src/js/core/Tabulator';
import {
  AjaxModule,
  EditModule,
  FilterModule,
  FormatModule,
  InteractionModule,
  MutatorModule,
  PageModule,
  PersistenceModule,
  PopupModule,
  ResizeColumnsModule,
  SelectRowModule,
  SortModule,
  TooltipModule,
} from 'tabulator-tables/src/js/core/modules/optional'
import tabulatorAjaxRequestViaApi from "@/services/tabulatorAjaxRequestViaApi";
import ItemSelector from "@/components/ItemSelector.vue";
import useApi from "@/_core/services/api";
import classResolver from "@/_core/services/classResolver";
import classes from "@/_core/components/_properties/classes";
import replaceUrlTags from "@/services/replaceUrlTags";
import resolveOnRowClickMethod from "@/services/resolveOnRowClickMethod";
import resolveOnCellClickMethod from "@/services/resolveOnCellClickMethod";
import Filter from "tabulator-tables/src/js/modules/Filter/Filter";
import eventBus from "@/_core/services/eventBus";
import clearSearchElementPolyfill from "@/services/clearSearchElementPolyfill";
import {queryListener, read, readUrlQueryParams, write} from "../../tabulator/persistTabulatorInQuery";

Filter.filters['between'] = () => {
};

Tabulator.registerModule([
  AjaxModule,
  EditModule,
  FilterModule,
  FormatModule,
  InteractionModule,
  MutatorModule,
  PageModule,
  ResizeColumnsModule,
  SortModule,
  TooltipModule,
  PopupModule,
  SelectRowModule,
  PersistenceModule
]);

// noinspection JSUnusedGlobalSymbols
export default {
  methods: {classResolver},
  components: {ItemSelector},
  props: {
    ...classes,
    columns: {
      type: Array,
      default: () => [],
    },
    hasGeneralSearch: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'fitColumns',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    persistence: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      required: true,
    },
    paginationSizes: {
      type: Array,
      default: () => [20, 50, 100],
    },
    initialSort: {
      type: Array,
      default: () => [],
    },
    initialHeaderFilter: {
      type: Array,
      default: () => [],
    },
    routeTemplateView: {
      type: String,
      default: ''
    },
    routeTemplateEdit: {
      type: String,
      default: ''
    },
    canSelectColumns: {
      type: Boolean,
      default: true,
    },
    activeColumns: {
      type: Array,
      default: () => [],
    },
    hiddenColumns: {
      type: Array,
      default: () => [],
    },
    saveLayoutRoute: {
      type: String,
      required: true,
    },
    viewId: {
      type: String,
      required: true,
    },
    immutableFilters: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    onRowClickActions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    onCellClickActions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    meta: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    componentId: {
      type: String,
      required: true,
    },
  },

  emits: ['tabulator:created', 'tabulator:filtered'],

  setup(props, {emit}) {
    const tabulatorElement = ref(null);
    const tabulator = ref(null);

    const api = useApi();

    const availableColumns = computed(() => props.columns
        .filter(({visible = true}) => visible)
        .map(({field, title}) => ({
          itemId: field,
          title,
        })));

    const activeColumns = ref(
        props.activeColumns.length ? props.activeColumns : availableColumns.value.map(
            ({itemId}) => itemId
        )
    );
    const columns = computed(() => {
      return mutateColumns(
          props.columns
              .filter(({field}) => !props.hiddenColumns.includes(field))
              .filter(({field}) => props.canSelectColumns ? activeColumns.value.includes(field) : true)
      );
    });

    if (tabulatorElement.value) {
      const urlParams = readUrlQueryParams();
      console.log(urlParams[`${tabulatorElement.value.id}--page`]);
    }

    const options = computed(() => {
      return {
        data: [],
        autoColumns: !Array.isArray(props.columns) || !props.columns.length,
        columns: columns.value,
        // maxHeight: '80vh',

        layout: props.layout,
        filterMode: 'remote',
        initialHeaderFilter: props.initialHeaderFilter,
        headerSort: false,
        initialSort: props.initialSort,
        ajaxRequestFunc: tabulatorAjaxRequestViaApi,
        ajaxURL: props.url,

        pagination: props.pagination,
        paginationMode: 'remote',
        paginationSize: 20,
        paginationSizeSelector: props.paginationSizes,
        paginationCounter: 'rows',

        persistenceMode: props.persistence,
        persistence: props.persistence ? {
          sort: true,
          headerFilter: true,
          page: true,
        } : false,
        persistenceWriterFunc: props.persistence ? write : null,
        persistenceReaderFunc: props.persistence ? read : null,


        sortMode: 'remote',
        initialFilter: props.immutableFilters.map(({field, condition: type, value}) => ({field, type, value})),
      }
    });

    const hasButtonView = computed(() => {
      return !!props.routeTemplateView;
    });

    const hasButtonEdit = computed(() => {
      return !!props.routeTemplateEdit;
    });


    function mutateColumns(columns) {
      function buttonColumn(icon, cellClick) {
        return {
          formatter: () => `<button class="btn" type="button"><i class="fa fa-${icon}"></i></button>`,
          width: 40,
          hozAlign: 'center',
          vertAlign: 'middle',
          cellClick,
          resizable: false,
          headerSort: false,
        }
      }

      let [..._columns] = columns.map(column => TabulatorColumnMapper.map(column));

      if (hasButtonView.value) {
        _columns.push(buttonColumn('eye', (e, cell) => {
          e.stopPropagation(); // This doesn't work - see https://github.com/olifolkerd/tabulator/issues/4174

          //cell.on('click', (e) => {
          //  e.stopPropagation();
          //});

          // Commented out - covered by rowClick event below  (stopPropagation fix doesn't work - see above
          //let url = replaceUrlTags(props.routeTemplateView, cell.getRow());
          //if (url.includes("https://") || url.includes("http://")) {
          //  window.open(url, '_blank'); // window.location.href = url;
          //} else {
          //  Router.push(url);
          //}
        },));
      }

      if (hasButtonEdit.value) {
        _columns.push(buttonColumn('edit', (e, cell) => {
          e.stopPropagation();  // This doesn't work - see https://github.com/olifolkerd/tabulator/issues/4174

          // NOTE WHEN WE COME TO ADD EDIT BUTTONS,  We'll need to find a way to stop the cellClick propagating,  but not sure this is possible with tabulator (see github link above), so we might end up needing to handle these buttons differently
          // NOTE 2: it seemed to be working before, but wasn't really - the propagation was being stopped by an error - "cell.on is not defined"
          //cell.on('click', (e) => {
          //  e.stopPropagation();
          //});
          let url = replaceUrlTags(props.routeTemplateEdit, cell.getRow())
          if (url.includes("https://") || url.includes("http://")) {
            window.open(url, '_blank'); // window.location.href = url;
          } else {
            Router.push(url);
          }
        },));
      }

      if (columns.length) {
        _columns[_columns.length - 1].resizable = false;
      }

      // Organize columns into groups  (see Tabulator docs for data format)
      let processedGroups = [];
      let groupedColumns = [];
      _columns.forEach(function (column, id, arr) {
        if (column.columnGroupTitle !== null) {
          if (!processedGroups.includes(column.columnGroupTitle)) {
            let colGroup = [column];
            _columns.forEach(function (column2, id2, arr) {
              if (column.columnGroupTitle === column2.columnGroupTitle && column.title != column2.title) {
                colGroup.push(column2);
              }
            });
            groupedColumns.push({
              title: column.columnGroupTitle,
              columns: colGroup,
              cssClass: 'tabulator-column-group-first tabulator-column-group-last'
            });
            processedGroups.push(column.columnGroupTitle);
          }
        } else {
          groupedColumns.push(column);
        }
      });
      _columns = groupedColumns;

      return _columns;
    }


    function createTable() {
      const _options = {
        ...options.value,
        selectableRows: hasButtonView.value,
      };

      tabulator.value = new Tabulator(
          tabulatorElement.value,
          _options,
      );

      // hashListener({...tabulator.value});

      tabulator.value.on('tableBuilt', () => {
        tabulator.value.setData();
        queryListener(tabulator.value);

      });

      function removePaddingFromTabulatorHeaders() {
        if (tabulatorElement.value instanceof HTMLElement) {
          tabulatorElement.value
              .querySelectorAll('.tabulator-header-filter > input')
              .forEach(el => el.style.removeProperty('padding'))
        }
      }

      //set width 100% on tabulator-col-content
      function setWidth100OnTabulatorColContent() {
        if (tabulatorElement.value instanceof HTMLElement) {
          tabulatorElement.value
              .querySelectorAll('.tabulator-col-content')
              .forEach(el => el.style.width = '100%')
        }
      }

      tabulator.value.on('renderComplete', removePaddingFromTabulatorHeaders);
      tabulator.value.on('renderComplete', setWidth100OnTabulatorColContent);
      tabulator.value.on('renderComplete', addStopPropagationToLinks);
      tabulator.value.on('renderComplete', () => clearSearchElementPolyfill(
          tabulatorElement.value?.querySelectorAll('.tabulator-header-filter > input[type="search"]'),
          (field) => tabulator.value.setHeaderFilterValue(field, ''),
      ));

      function addStopPropagationToLinks() {
        if (tabulatorElement.value instanceof HTMLElement) {
          tabulatorElement.value
              .querySelectorAll('.tabulator-cell a')
              .forEach(el => el.addEventListener('click', e => e.stopPropagation()));
        }
      }

      if (hasButtonView.value) {
        tabulator.value.on('rowClick', (e, row) => {
          e.preventDefault()

          let url = replaceUrlTags(props.routeTemplateView, row);

          console.log(url);
          if (url.match(/^https?:\/\/(www\.)?newtontrailers.com\//)) {
            window.location.href = url;
          } else if (url.includes("https://") || url.includes("http://")) {
            window.open(url, '_blank');
          } else {
            Router.push(url);
          }

        })
      }

      props.onRowClickActions.forEach(({action, data}) => {
        const method = resolveOnRowClickMethod(action);

        if (method) {
          tabulator.value.on('rowClick', (e, row) => {
            method(data, row, tabulator, e);
          })
        }

      })

      props.onCellClickActions.forEach(({action, data}) => {
        const method = resolveOnCellClickMethod(action);

        if (method) {
          tabulator.value.on('cellClick', (e, cell) => {
            method(data, cell, tabulator, e);
          })
        }

      })

      onUpdated(removePaddingFromTabulatorHeaders);

      emit('tabulator:created', tabulator.value);
    }

    function saveLayout() {
      api.post(props.saveLayoutRoute, {
        viewId: props.viewId,
        layout: activeColumns.value
      });
    }

    function addFiltersFromEventBus(filters) {
      const _filters = filters.map((filter) => ({field: filter.column, type: filter.type ?? '=', value: filter.value}));
      tabulator.value.on('dataFiltered', f => emit('tabulator:filtered', f));
      tabulator.value.setFilter(_filters);
    }

    watch(() => props.activeColumns, () => {
      activeColumns.value = props.activeColumns;
      if (tabulator.value) {
        tabulator.value.setColumns(columns.value);
      }
    })

    watch(activeColumns, () => {
      if (tabulator.value) {
        tabulator.value.setColumns(columns.value);
      }
    })

    watch(activeColumns, () => {
      if (tabulator.value) {
        tabulator.value.setColumns(columns.value);
        saveLayout();
      }
    })

    onMounted(function () {
      createTable();
      eventBus.$on('tabulator:filter', addFiltersFromEventBus);
    })

    onBeforeUnmount(function () {
      eventBus.$off('tabulator:filter', addFiltersFromEventBus);
    })

    function handleGeneralSearch({target: {value}}) {
      if (tabulator.value) {
        tabulator.value.setFilter([{field: '_general', type: 'like', value}]);
      }
    }

    return {
      tabulatorElement,
      hasButtonView,
      hasButtonEdit,
      options,
      activeColumns,
      availableColumns,
      handleGeneralSearch
    }
  },
}
</script>

<style lang="scss">
@import "tabulator-tables/dist/css/tabulator_bootstrap5.min.css";

.tabulator {
  font-size: 0.9em;
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.25);
}

.generalSearch {
  margin-bottom: 1em;

  &__label {
    display: block;
    font-weight: bold;
  }

  &__input {
    border-radius: .25rem;
  }
}
</style>
