export default function (cell, formatterParams = {}) {
  let value = cell.getValue();

  if (!value) {
    return '';
  }

  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  const {numberFormatterOptions = {}} = formatterParams;

  if (numberFormatterOptions.style === 'percent') {
    value = value / 100;
  }

  return value.toLocaleString(undefined, numberFormatterOptions);
}
