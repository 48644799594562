
<template>
  <div class="linkField" :class="field.classes">
    <div v-if="field.linkType === 'external'"><a :href="field.value"></a>{{ field.linkTitle }}</div>
    <div v-if="field.linkType === 'internal'"> <router-link v-bind:to="field.value">{{ field.linkTitle }}</router-link></div>
  </div>
</template>

<script>
export default {
  name: 'linkField',
  props : [
    'props'
  ],
  data: function () {
    return {
      field : {
        linkType: 'internal',
        value: '',
        linkTitle: '',
        classes: ''
      }
    };
  },
  created() {
    this.field = this.props.field;
  }
}
</script>