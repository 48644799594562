<template>
  <div class="data-content">
    <div id="table-responsive" class="vue__simpledatatable table-responsive col-12">
      <table class="table table-responsive">
        <thead>
        <tr>
          <td v-for="(value, field) in tableHeaders" v-html="value"></td>
          <td>#</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in data" v-bind:class='{active: row.active === "1"}'>
          <td v-for="(value, field) in row" :class="rowClickable ? 'clickable' : 'no-click'" @click="viewRow(row.Ref)">
            {{ value }}
          </td>
          <td>
            <button class="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Edit"
                    @click="edit(row.Ref)">
              <i class="fal fw fa-edit"></i>
            </button>
            <button v-if="row.manageButton" class="btn"
                    data-placement="top"
                    data-toggle="tooltip" title="Change User State" v-bind:class='{"btn-warning": row.active === "1", "btn-success": row.active === "0"}'>
              <i class="fal fw" v-bind:class='{"fa-pause": row.active === "1", "fa-play": row.active === "0"}'></i>
            </button>
            <button class="btn btn-danger" data-placement="top" data-toggle="tooltip" title="Delete"
                    @click="deleteItem(row.Ref)">
              <i class="fal fw fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {Api} from '../../services/api'

function initialState() {
  return {
    dataSource: '',
    data: [],
    idField: 'ref',
    tableHeaders: [],
    page: 1,
    itemsPerPage: 20,
    offset: 0,
    totalResults: 0,
    totalPages: 1,
    filterParams: '',
    basePath: '',
    rowClickable: true
  }
}

export default {
  name: 'SimpleDataTable',
  props: [
    'props',
    'componentData'
  ],
  data: function () {
    return initialState();
  },
  watch: {
    $route(to, from) {
      this.resetWindow();
    },
    props(to, from) {
      this.dataSource = to.dataSrc;
      this.getData();
    },
    componentData(to, from) {
      this.basePath = to.basePath;
    },
    filters(to, from) {
      if (to) {
        //map object to array so we can map params consistently
        var output = Object.entries(to).map(([key, value]) => ({key, value}));
        this.filterParams = '&' + output.map(function (e) {
          return e.key + '=' + e.value;
        }).join('&');
      } else {
        this.filterParams = '';
      }
      this.getData();
    }
  },
  computed: {
    filters() {
      return this.$store.getters.filterParams
    }
  },
  methods: {
    resetWindow: function () {
      Object.assign(this.$data, initialState());
      this.$store.commit('addFilterParams', {})
    },
    viewRow: function (ref) {
      if (this.rowClickable) {
        this.$router.push(this.basePath + '/' + ref);
      }
    },
    async getData() {
      var queryString = this.buildQueryString();
      var response = await Api.get(this.dataSource + queryString)
          .then(response => {
            this.data = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalResults = response.data.totalData;
            this.tableHeaders = response.data.tableHeader;
          })
          .catch(error => {
            console.log(error);
            this.data = []
          });
    },
    buildQueryString() {
      var queryString = '';
      if (this.dataSource.indexOf('page') === -1) {
        queryString = queryString + 'page=' + this.page;
      }
      if (this.dataSource.indexOf('offset') === -1) {
        queryString = queryString + '&offset=' + this.offset;
      }
      if (this.dataSource.indexOf('limit') === -1) {
        queryString = queryString + '&limit=' + this.itemsPerPage;
      }
      if (this.dataSource.indexOf('?') === -1) {
        queryString = '?' + queryString
      } else {
        queryString = '&' + queryString
      }
      return queryString + this.filterParams
    },
    edit($id) {
      this.$router.push(this.basePath + '/' + $id + '/edit');
    },
    deleteItem($id) {
      //@todo a confirm popup.
      this.$router.push(this.basePath + '/' + $id + '/delete');
    }
  },
  created() {
    this.dataSource = this.props.dataSrc;
    if (typeof (this.props) !== 'undefined' && typeof (this.props.allowViewClick) !== 'undefined') {
      this.rowClickable = this.props.allowViewClick;
    }
    if (this.componentData.basePath) {
      this.basePath = this.componentData.basePath;
    } else {
      this.basePath = this.$router.currentRoute.value.path;
    }
    this.getData();
  }
}
</script>
<style lang="scss">
</style>
