export default function (cell, formatterParams, onRendered) {
  const value = cell.getValue();
  const {sales_order, signed_sales_order, salesperson} = value;
  let icon = '<i class="fa fa-fw fa-exclamation-triangle text-warning" title="Awaiting Invoice"></i>';

  if (!sales_order && !signed_sales_order) {
    icon = '<i class="fa fa-fw fa-times-circle text-danger"></i>';
  } else if (signed_sales_order) {
    icon = '<i class="fa fa-fw fa-check-circle text-success"></i>'
  }

  const el = document.createElement('div');
  el.classList.add('d-flex', 'flex-column', 'align-items-center');

  let html = `<div>${icon}</div>`;
  if (salesperson) {
    html += `<div>${salesperson}</div>`;
  }

  el.innerHTML = html;
  return el;
}
