<template>
  <div :class="getClasses" class="repeatingFields">
    <div v-for="(item, index) in field.value" :key="index" class="input-group row category">
      <input v-model="field.value[index].name" :name="'category_'+index" :placeholder="groupPlaceholder"
             class="form-control col-sm-12"
             type="text">
      <div v-for="(question, qindex) in item.questions" :key="qindex" class="form-row questions col-sm-12">
        <div class="input-group col-sm-10">
          <input v-model="field.value[index].questions[qindex]"
                 :class="{'form-input--error': $v.field.value[index].questions[qindex].$error}"
                 :name="'category_'+index+'_question_'+qindex" class="form-control col-sm-10"
                 placeholder="Question" type="text" @change="escapeText(index, qindex, $event)">
          <input v-model="field.value[index].scores[qindex]"
                 :class="{'form-input--error': $v.field.value[index].scores[qindex].$error}"
                 :name="'category_'+index+'_scores_'+qindex"
                 class="form-control col-sm-2" placeholder="Score"
                 type="text" @input="$v.field.value[index].scores[qindex].$touch()">
          {{ $v }}
        </div>
        <span v-if="$v.field.value[index].scores[qindex].$error" class="input-error error col-sm-10"><small>These fields are required.</small></span>
        <span class="input-group-btn col-sm-2">
            <button class="btn btn-success" tabindex="-1" type="button" @click="questionAdd(index)"><i
                class="fa fa-plus"></i></button>
            <button v-if="isEditable(index, qindex)" class="btn btn-danger" tabindex="-1" type="button"
                    @click="questionRemove(index, qindex)"><i class="fa fa-minus"></i></button>
          </span>
      </div>
      <span v-if="numCats > minimumCategories" class="input-group-btn">
          <button class="btn btn-danger" tabindex="-1" type="button" @click="categoryRemove(index)"><i
              class="fa fa-minus"></i> Remove category</button>
        </span>
    </div>
    <span v-if="!maximumCategories || numCats < maximumCategories" class="input-group-btn">
      <button class="btn btn-success" tabindex="-1" type="button" @click="categoryAdd()"><i class="fa fa-plus"></i> Add category</button>
    </span>
  </div>
</template>

<script>
import {commonProperties} from '../../mixins/commonProperties'
import {required} from "@vuelidate/validators";
import {useVuelidate} from '@vuelidate/core';

export default {
  name: 'RepeatingFieldGroupField',
  mixins: [commonProperties],
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return this.repeatingFieldValidations;
  },
  methods: {
    initialState() {
      return {
        field: {},
        numCats: 1,
        minimumCategories: 1,
        maximumCategories: null,
        minimumQuestions: 1,
        groupPlaceholder: 'Category name',
        fieldValidations: {},
      }
    },
    escapeText(index, qindex, event) {
      this.field.value[index].questions[qindex] = event.target.value.replace(/"/g, '&quot;');
      this.$v.field.value[index].questions[qindex].$touch()
    },
    isEditable(catIndex, qIndex) {
      let numQs = this.numCategoryQuestions(catIndex, qIndex);
      return (!this.field.value[catIndex].isEdit[qIndex] && numQs > 1);
    },
    numCategoryQuestions(catIndex, qIndex) {
      let numQs = 0;
      if (typeof this.field.value[catIndex].questions === 'object') {
        numQs = Object.keys(this.field.value[catIndex].questions).length;
      } else {
        numQs = this.field.value[catIndex].questions.length
      }
      return numQs;
    },
    categoryAdd() {
      if (this.maximumCategories) {
        if (this.numCats >= this.maximumCategories) {
          return;
        }
      }
      this.numCats++;
      this.$set(this.field.value, this.field.value.length, {
        "name": "",
        "questions": [''],
        "scores": [''],
        "isEdit": ['']
      });
      this.repeatingValidations();
      this.$forceUpdate();
    },
    categoryRemove(catIndex) {
      if (this.minimumCategories) {
        if (this.numCats <= this.minimumCategories) {
          return;
        }
      }
      this.numCats--;
      this.field.value.splice(catIndex, 1);
      this.fieldValidations.splice(catIndex, 1);
      this.repeatingValidations();
      this.$forceUpdate();
    },
    questionAdd(catIndex) {
      if (typeof this.field.value[catIndex].questions === 'object') {
        let lastId = Object.keys(this.field.value[catIndex].questions)[Object.keys(this.field.value[catIndex].questions).length - 1];
        let newKey = (parseFloat(lastId) + 1)
        this.$set(this.field.value[catIndex].questions, newKey, "");
        this.$set(this.field.value[catIndex].scores, newKey, "");
        this.$set(this.field.value[catIndex].isEdit, newKey, false);
      } else {
        this.$set(this.field.value[catIndex].questions, this.field.value[catIndex].questions.length, "");
      }

      this.repeatingValidations();
      this.$forceUpdate();
    },
    questionRemove(catIndex, questIndex) {
      let numQs = this.numCategoryQuestions(catIndex, questIndex);
      if (numQs > 1) {
        if (this.field.value[catIndex].questions.splice instanceof Function) {
          this.field.value[catIndex].questions.splice(questIndex, 1);
          this.field.value[catIndex].scores.splice(questIndex, 1);
        } else {
          delete this.field.value[catIndex].questions[questIndex];
          delete this.field.value[catIndex].scores[questIndex];
        }
        delete this.fieldValidations[catIndex].questions[questIndex];
        delete this.fieldValidations[catIndex].scores[questIndex];
        this.repeatingValidations();
        this.$forceUpdate();
      }
    },
    repeatingValidations() {
      this.fieldValidations = {};
      for (let catIndex in this.field.value) {
        if (typeof this.fieldValidations[catIndex] === 'undefined') {
          this.fieldValidations[catIndex] = {};
          this.fieldValidations[catIndex].name = "";
        }
        if (typeof this.field.value[catIndex].questions !== 'undefined') {
          for (let qIndex in this.field.value[catIndex].questions) {
            if (typeof this.fieldValidations[catIndex].questions === 'undefined') {
              this.fieldValidations[catIndex].questions = {};
              this.fieldValidations[catIndex].scores = {};
            }
            if (typeof this.fieldValidations[catIndex].questions[qIndex] === 'undefined') {
              this.fieldValidations[catIndex].questions[qIndex] = {};
              this.fieldValidations[catIndex].scores[qIndex] = {};
              this.fieldValidations[catIndex].questions[qIndex].required = required;
              this.fieldValidations[catIndex].scores[qIndex].required = required;
              //check validations as we had to rebuild the data.
            }
          }
        }
      }
    }
  },
  computed: {
    repeatingFieldValidations() {
      return {
        field: {
          value: this.fieldValidations
        }
      }
    }
  },
  created() {
    if (this.props.field) {
      this.field = this.props.field;
      if (!this.field.value) {
        this.$set(this.field.value, this.field.value.length, {
          "name": "",
          "questions": [''],
          "scores": [''],
          "isEdit": ['']
        });
      }
      if (this.field.maximumGroupRepeats) {
        this.maximumCategories = this.field.maximumGroupRepeats
      }
      if (this.field.groupPlaceholder) {
        this.groupPlaceholder = this.field.groupPlaceholder
      }
      this.numCats = this.field.value.length;
      this.repeatingValidations();
    }
  }
}
</script>
<style lang="scss">
.toClone {
  padding: 20px;
  margin-bottom: 50px;
  background: #fff;

  .bmd-form-group {
    > .input-group {
      input {
        width: 100%
      }

      .clone-wrapper {
        width: 100%;

        .toClone {
          padding: 5px;
          margin-bottom: 10px;

          input {
            float: left;
            width: 50%;
          }
        }
      }

      > .input-group-btn {
        width: 100%;
      }
    }
  }
}
</style>
