<template>
  <button class="btn" :class="getClasses()" @click="followLink(tileLink)" :disabled="disabled">
    <span><i v-if="icon" v-bind:class="['fal', 'fa-fw', 'fa-'+icon]"></i> {{ text }}</span>
  </button>
</template>
<script>
import { commonProperties } from '../../mixins/commonProperties'
import {Api} from "../../services/api";
import {apiFunctions} from "../../mixins/apiFunctions";

export default {
  name: 'SimpleButton',
  mixins: [commonProperties,apiFunctions],
  watch: {
    props (to, from){
      this.tileLink = to.route;
      this.icon = to.icon;
      this.text = to.text;
      this.disabled = to.disabled;
    },
  },
  methods: {
    initialState (){
      return {
        buttonLink: '',
        icon: false,
        text: '',
        title: '',
        asynch: null,
        disabled: false
      }
    },
    async followLink(){
      if(this.asynch){
        this.makeRequest()
      } else {
        this.$router.push(this.tileLink);
      }
    },
    async makeRequest(){
      Api.request({
        url: this.tileLink,
        method: this.asynch,
        data: '{"bob": "builder"}',
      }).then(response => {
        this.processResponse(response, () => {
          this.formIsSubmitting = false;
        });
      }).catch(error => {

      });
    }
  },
  created() {
    this.tileLink = this.props.route;
    this.icon = this.props.icon ? this.props.icon : false;
    this.text = this.props.text ? this.props.text : '';
    this.asynch = this.props.asynch ? this.props.asynch : null;
    this.disabled = this.props.disabled ? this.props.disabled : false;
  }
}
</script>