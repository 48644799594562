<template>
  <article :class="{
      'dashboardPanel--loading': loading,
      'dashboardPanel--empty': !hydrated,
      'dashboardPanel--minimized': minimized,
      'dashboardPanel--noChrome': noChrome,
  }" class="dashboardPanel">
    <header v-if="!noChrome" class="dashboardPanel__header">
      <div v-if="title" class="dashboardPanel__title">{{ title }}</div>
      <div class="dashboardPanel__actions">
        <!--        <button class="btn btn-link" type="button" @click="minimize">-->
        <!--          <i :class="minimized ? 'fa-window-maximize' : 'fa-window-minimize'" class="fa"></i>-->
        <!--        </button>-->
        <button class="btn btn-link" type="button" @click="refresh"><i class="fa fa-refresh"></i></button>
        <button class="btn btn-link" type="button" @click="remove"><i class="fa fa-times"></i></button>
      </div>
    </header>
    <section class="dashboardPanel__contents">
      <loading-spinner v-if="loading"/>
      <slot v-else-if="hydrated"/>
    </section>
  </article>
</template>

<script setup>
import {computed, defineProps, onMounted, ref,} from "vue";
import useApi from "@/_core/services/api";
import LoadingSpinner from "@/_core/components/partials/LoadingSpinner.vue";

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  refreshRoute: {
    type: String,
    default: '',
  },
  refreshParams: {
    type: Object,
    default: () => {
    },
  },
  hydrated: {
    type: Boolean,
    default: false,
    required: false,
  },
  noChrome: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const refreshRoute = computed(() => props.refreshRoute.replace(
    /\{(\w+)\}/g,
    (match, p1) => props.refreshParams[p1] || ''
));

const api = useApi();
const loading = ref(true);
const minimized = ref(false);

const emit = defineEmits(['refreshed', 'removed']);

function remove() {
  emit('removed');
}

function refresh() {
  loading.value = true;

  api.get(refreshRoute.value).then(({data}) => {
    emit('refreshed', data);
    loading.value = false;
  })
}

function minimize() {
  minimized.value = !minimized.value;
}

onMounted(() => {
  if (props.refreshRoute && props.refreshParams) {
    loading.value = true;

    api.get(refreshRoute.value).then(({data}) => {
      emit('refreshed', data);
      loading.value = false;
    })
  }
})

</script>

<style lang="scss">
.dashboardPanel {
  $self: &;

  height: 100%;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .6);

  &--noChrome {
    box-shadow: none;
  }

  &__header {
    display: flex;
    height: 38px;
    align-items: center;
    padding: 0 0 0 12px;

    background-color: #333333;
    color: #ffffff;
    font-weight: bold;
  }

  &__actions {
    margin-left: auto;

    .btn-link {
      color: #ffffff;

      &:hover {
        color: #999999;
      }
    }
  }

  &__contents {
    height: calc(100% - 38px);
    padding: 12px;
    background-color: #fff;
    overflow: scroll;
    cursor: auto;

    #{ $self }--noChrome > & {
      padding: 0;
    }
  }

  &--empty {
    .dashboardPanel__contents {
      height: 30px;
    }
  }

  &--minimized {
    .dashboardPanel__contents {
      display: none;
    }
  }
}
</style>
