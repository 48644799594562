import {Api} from "../services/api";
// import {store} from "../services/store";
import eventBus from "../services/eventBus";
import paramsInHash from "../services/paramsInHash";
import Global from "../services/global";

const hash = require('object-hash');

export const formFunctions = {

  props: [
    'filters'
  ],
  mixins: [
    paramsInHash
  ],
  watch: {
    submittedData(to, from) {
      this.convertToFilters();
    },
    triggerSubmit: {
      handler(to, from) {
        //on change, update the store.
        if (to) {
          if (typeof this.formDetails.id !== 'undefined') {
            if (to[this.formDetails.id].active) {
              this.submit();
            }
          }
        }
      },
      deep: true
    },
    '$store.state.triggerCallback': function () {
      var callback = this.$store.getters.triggerCallback;
      if (callback) {
        //prevent double submissions
        this.$store.commit('setCallbackTrigger', null)
        //fire event
        this.formIsSubmitting = true;
        Api.request({
          url: callback.callback,
          method: callback.method,
          data: '{"' + callback.watch + '": ' + callback.value + '}',
        }).then(response => {
          this.processResponse(response, () => {
            this.formIsSubmitting = false;
          });
        }).catch(error => {
          this.processError(error);
        });
      }
    }
  },
  computed: {
    triggerSubmit() {
      return this.$store.getters.FormSubmitTrigger
    },
    hash: () => hash,
  },
  methods: {
    convertToFilters() {
      const data = {...this.submittedData};
      Object.entries(data).forEach(([key, value]) => {
        !value && delete data[key];
      });
      if (this.persistFilters) {
        const params = new URLSearchParams(data);
        this.paramsToHash(params.toString());
        this.$store.commit('addFilterParams', Object.fromEntries(params));
      }
      if (this.isShared) {
        eventBus.$emit("sharedFilters", data)
      }
    },
    getFieldsetSetting(fieldset, setting) {
      if (this.formFields[fieldset].fieldset_settings) {
        return this.formFields[fieldset].fieldset_settings[setting] ? this.formFields[fieldset].fieldset_settings[setting] : '';
      } else {
        return '';
      }
    },
    async getFormData() {
      var response = await Api.get(this.dataSource)
        .then(response => {
          this.processResponse(response);
          this.$set(this, 'formFields', response.data.form.fields)
          this.formDetails = response.data.form.details;
          if (typeof this.formDetails.verb !== 'undefined') {
            this.method = this.formDetails.verb;
          }
          if (typeof this.formDetails.isFormData !== 'undefined') {
            this.isFormData = this.formDetails.isFormData;
          }
          this.setFormSubmitTrigger();
          this.$store.commit('addCallbackData', response.data.form.callBackData);
          if (!this.formDetails.submit || this.formDetails.submit === '') {
            this.formDetails.submit = 'Submit';
          }
          if (typeof this.componentKey != "undefined") {
            this.componentKey = this.componentKey + 1;
          }
        })
        .catch(error => {
          console.log(error);
          this.data = []
        });
    },
    clearFormFields() {
      this.paramsToHash({});
      this.$store.commit('addFilterParams', {});
      Object.entries(this.$refs).forEach(components => components.forEach(component => {
        if (typeof component === 'string') {
          const element = document.getElementById(component);
          if (element) {
            element.value = null;
          }
        }
      }))
      this.myFormFields = this.formFields;
    },
    setFormSubmitTrigger() {
      let formSubmitInfo = {};
      formSubmitInfo.id = this.formDetails.id;
      formSubmitInfo.active = false;
      formSubmitInfo.fields = this.myFormFields;
      this.$store.commit('formSubmitTrigger', formSubmitInfo);
    },
    submit() {
      Global.checkAuthenticated(this.$router.currentRoute.value.path)
        .then(response => {
          //if we can, build the page

          this.formIsSubmitting = true;
          let data = this.buildSubmitDataObject();
          //allow us to build the submit object without submitting the form directly.
          if (this.buildSubmitOnly) {
            this.formIsSubmitting = false;
            return data
          }
          let invalid = false;
          //loop over $refs to check for invalid form fields.
          for (let field in this.$refs) {
            if (typeof this.$refs[field][0] !== 'undefined' && typeof this.$refs[field][0].$v !== 'undefined') {
              this.$refs[field][0].$v.$touch()
              if (this.$refs[field][0].$v.$invalid) {
                invalid = true;
              }
            }
          }
          if (invalid) {
            this.formIsSubmitting = false;
            this.formMessages = ''; //forcibly reset messages.
            this.formMessages = "<span class='error' data-event='" + this.randomString() + "'>There are errors in your submission, please check the highlighted red fields.</span>";
            this.formMessageType = 'error';
            this.setFormSubmitTrigger();
          } else {
            //loop over the data and check for files
            let asFormData = false;
            let fileFields = [];
            for (const fieldName in data) {
              if (typeof data[fieldName] !== 'undefined' && typeof data[fieldName].file !== 'undefined') {
                asFormData = true;
                fileFields.push(fieldName);
              }
            }
            let formRequest = {
              url: this.formDetails.action,
              method: this.method,
            }
            if (this.isFormData || asFormData) {
              var formData = new FormData();
              //get all file fields and append the field data.
              for (var i = 0; i < fileFields.length; i++) {
                formData.append(fileFields[i], data[fileFields[i]].file);

                delete data[fileFields[i]]; //remove the file data for general data.
              }
              //loop over the rest of the data and ass as form data.
              for (const field_name in data) {
                formData.append(field_name, data[field_name]);
              }
              formRequest.data = formData;
              formRequest.headers = {
                'content-type': 'multipart/form-data',
                "Authorization": "Bearer " + store.getters.authToken
              }
            } else {
              formRequest.data = data;
            }

            Api.request(formRequest).then(response => {
              this.processResponse(response, () => {
                this.formIsSubmitting = false; // NOT GETTING TRIGGERED SOMETIMES, LEADING TO SPINNER!
                this.setFormSubmitTrigger();
              });
              if (typeof this.pollAction != "undefined" && typeof response.data.eventId != 'undefined') {
                this.pollAction(response.data.eventId);
              }
              if (typeof this.onSubmissionComplete != "undefined") {
                this.onSubmissionComplete(response.data);
              }
            }).catch(error => {
              this.processError(error);
            });
          }

        })
        .catch(error => {
          console.log(error);
        });

    },
    buildSubmitDataObject() {

      //the form we want is simply name.value
      var data = {};
      if (typeof this.myFormFields === 'undefined') {
        this.myFormFields = this.formFields;
      }
      Object.entries(this.myFormFields).forEach((entry) => {
        const [fieldName, fieldData] = entry;
        if (fieldName.includes('fieldset')) {
          Object.entries(fieldData).forEach((entry) => {
            const [fieldsetName, fieldSetField] = entry;
            if (fieldsetName.includes('fieldGroup')) {
              Object.entries(fieldSetField).forEach((entry) => {
                const [fieldGroupFieldName, fieldGroupField] = entry;
                data = this.buildSubmitDataFromFields(fieldGroupField, data);
              });
            } else {
              data = this.buildSubmitDataFromFields(fieldSetField, data);
            }
          });
        } else {
          if (fieldName.includes('fieldGroup')) {
            Object.entries(fieldData).forEach((entry) => {
              const [fieldGroupFieldName, fieldGroupField] = entry;
              data = this.buildSubmitDataFromFields(fieldGroupField, data);
            });
          } else {
            data = this.buildSubmitDataFromFields(fieldData, data);
          }
        }
      });

      // clean string data by trimming excess whitespace
      for (var prop in data) {
        if (Object.prototype.hasOwnProperty.call(data, prop)) {
          if (typeof data[prop] === 'string') {
            data[prop] = data[prop].trim();
          }
        }
      }

      this.submittedData = data;
      return data;
    },
    buildSubmitDataFromFields(fieldData, data) {
      if (fieldData.hasOwnProperty('name')) {
        //prevent sending link or markup fields in submit
        if (fieldData.type === 'link' || fieldData.type === 'markup') {
          return data;
        }
        if (fieldData.inputType === 'file') {
          data[fieldData.name] = {}
          data[fieldData.name].type = 'file';
          data[fieldData.name].file = fieldData.file;
          return data;
        }
        if (fieldData.value && fieldData.value.hasOwnProperty('key')) {
          data[fieldData.name] = fieldData.value.key;
        } else {
          //we need to ignore blanks for filters
          if (this.filters && fieldData.value) {
            data[fieldData.name] = fieldData.value;
          } else {
            data[fieldData.name] = fieldData.value;
          }
        }
      }
      return data;
    },


  },
}
