<template>
  <i :class="props.class" style="'color: '+ colour"></i>
</template>

<script setup>

const props = defineProps(
    {
      class: {
        type: String,
        required: true
      },
      colour: {
        type: String,
        required: false
      }
    }
)

</script>
