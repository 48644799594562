import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'attempt-refresh';

const useAttemptRefreshStore = defineStore(STORE, () => {
  const attemptRefresh = ref(true);

  function setAttemptRefresh(value) {
    attemptRefresh.value = value;
  }

  return {attemptRefresh, setAttemptRefresh};

});

export default useAttemptRefreshStore;
