<template>
    <div :class="field.classes" v-html="field.value" :id="field.id"></div>
</template>

<script>
export default {
  name: 'markupField',
  props : [
    'props'
  ],
  data: function () {
    return {
      field : {
        value: '',
      }
    };
  },
  created() {
    this.field = this.props.field;
  }
}
</script>