export default function (elements, clearField) {
  elements.forEach((input) => {
    function createIcon() {
      function handleClick(e) {
        e.stopPropagation();
        if (input.value) {
          const field = icon.closest('.tabulator-col')?.getAttribute('tabulator-field');
          if (field) {
            clearField(field)
          }
        }
      }

      const icon = document.createElement('i');

      icon.className = 'fa fa-times';
      icon.style.position = 'absolute';
      icon.style.right = '5px';
      icon.style.top = '50%';
      icon.style.transform = 'translateY(-50%)';
      icon.style.cursor = 'pointer';
      icon.addEventListener('click', handleClick);

      return icon;
    }

    const icon = createIcon();
    const parent = input.parentElement;

    parent.style.position = 'relative';
    parent.appendChild(icon);

    input.type = 'text';
  })
}
