<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      class="companyContact"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <div>
      <div v-if="logoUrl" class="companyContact__logo">
        <img ref="logoElement" :alt="`${companyName} logo`" :src="logoUrl" @error="removeLogo">
      </div>

      <ContactDetails
          :addressCountry="address?.country"
          :addressLine1="address?.address_1"
          :addressLine2="address?.address_2"
          :addressLine3="address?.city"
          :addressPostcode="address?.postcode"
          :addressTown="address?.state"
          :businessName="companyName"
          :contactLink="contact?.link"
          :contactName="contact?.name"
          :emailAddress="contact?.email"
          :fax="contact?.fax"
          :mobilePhone="contact?.phone_numbers.MOBILE"
          :telephone="contact?.phone_numbers.WORK"
          class="companyContact__contact"
      />

      <p v-if="website" class="companyContact__website"><a :href="website" target="_blank">{{ websiteLabel }}</a>
      </p>
    </div>
  </DashboardPanel>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import ContactDetails from "@/components/ContactDetails.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const logoElement = ref(null);

function removeLogo() {
  if (logoElement.value) {
    logoElement.value.remove();
  }
}

const companyName = ref('');
const logoUrl = ref('');
const contact = ref(null);
const address = ref(null);
const website = ref('');

const websiteLabel = computed(() => {
  if (!website.value) {
    return '';
  }

  const url = new URL(website.value);
  return url.hostname;
});

const emit = defineEmits(['removed']);

const {hydrate, hydrated} = useHydration(({
                                            companyName: _companyName,
                                            logoUrl: _logoUrl,
                                            contact: _contact,
                                            address: _address,
                                            website: _website,
                                          }) => {
  logoUrl.value = _logoUrl;
  companyName.value = _companyName;
  contact.value = _contact;
  address.value = _address;
  website.value = _website;
});

</script>

<style lang="scss">
.companyMainContact {
  &___logo {
    max-width: 70%;
  }
}
</style>
