const hash = require('object-hash');
export const commonProperties = {
    props : [
        'props',
        'componentData',
        'componentId'
    ],
    data: function () {
        return this.initialState();
    },
    watch: {
        $route(to, from) {
            this.resetWindow();
        }
    },
    methods: {
        initialState () {
            return {
                classes: '',
                compWidth: 0,
            }
        },
        getClasses(){
            if(this.classes){
                return this.classes; //allow for component defaults
            }
            return '';
        },
        randomString(len) {
            let text = " "
            let chars = "abcdefghijklmnopqrstuvwxyz"

            for( let i=0; i < len; i++ ) {
                text += chars.charAt(Math.floor(Math.random() * chars.length))
            }

            return text
        },
        toggleSlide(){
            let id = this.getAttribute('data-category-id');
            let open = this.getAttribute('data-open');
            if (open === "true"){
                slideUp(document.getElementById(id));
                this.setAttribute('data-open','false');
            } else {
                slideDown(document.getElementById(id));
                this.setAttribute('data-open','true');
            }
        },
        resetWindow: function () {
            Object.assign(this.$data, this.initialState());
        },
        reloadComponent(){
            this.$forceUpdate();
        },
        objectToArray(object){
            return Object.keys(object).map(key => object[key]);
        },
        getComponentId(){
          return this.componentId;
        },
        isJSON(str) {
            try {
                return (JSON.parse(str) && !!str);
            } catch (e) {
                return false;
            }
        }
    },
    computed: {
        console: () => console,
        hash: () => hash,
    },
    created(){
        if(typeof(this.props) !== 'undefined' && typeof(this.props.dataSrc) !== 'undefined') {
            this.dataSource = this.props.dataSrc;
        }
        if(this.componentData){
            this.classes = this.componentData.classes;
        }
    }
}