<template>
  <a v-if="isExternal()"
     :href="href"
     :target="target"
     @click="$emit('click')"
  >
    <menu-link-label :icon="icon" :title="title"/>
  </a>
  <router-link
      v-else
      :target="target"
      :to="href"
      @click="$emit('click')"
  >
    <menu-link-label :icon="icon" :title="title"/>
  </router-link>

</template>

<script setup>

import MenuLinkLabel from "@/_core/components/nav/MenuLinkLabel.vue";

const props = defineProps({
  href: {
    type: String,
    required: true
  },
  target: {
    type: String,
    default: '_self'
  },
  icon: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['click']);

function isExternal() {
  return props.href.match(/^https?:\/\//);
}
</script>
