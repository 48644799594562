export default function (cell) {
  if (cell.getValue() === null) {
    return '';
  }
  const {colour = '#000', rate = 0, content = [], animate = true} = cell.getValue();
  
  const rateSecs = `${(0.6 + (100 - rate / 100) * .012).toFixed(2)}s`;

  const container = document.createElement('div');
  container.classList.add('beatingHeart');

  if (animate) {
    container.classList.add('beatingHeart--animate');
  }

  container.style.setProperty('--bh-color', colour);
  container.style.setProperty('--bh-rate', rateSecs);

  const contentContainer = document.createElement('div');
  contentContainer.classList.add('beatingHeart__content');

  content.forEach(item => {
    const itemContainer = document.createElement('div');
    itemContainer.innerHTML = item;
    contentContainer.appendChild(itemContainer);
  })

  container.innerHTML = `
    <svg id="heart" fill="currentColor" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"
         class="beatingHeart__heart">
      <filter id="bevel" filterUnits="objectBoundingBox" height="150%" width="150%" x="-10%" y="-10%">
        <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="3"></feGaussianBlur>
        <feSpecularLighting in="blur" lighting-color="#444" result="specOut" specularConstant="0.5"
                            specularExponent="10" surfaceScale="5">
          <fePointLight x="-5000" y="-10000" z="20000"></fePointLight>
        </feSpecularLighting>
        <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut2"></feComposite>
        <feComposite in="SourceGraphic" in2="specOut2" k1="0" k2="1" k3="1" k4="0" operator="arithmetic"
                     result="litPaint"></feComposite>
      </filter>
      <path
        d="M 65,29 C 59,19 49,12 37,12 20,12 7,25 7,42 7,75 25,80 65,118 105,80 123,75 123,42 123,25 110,12 93,12 81,12 71,19 65,29 z"
        filter="url(#bevel)"></path>
    </svg>
`
  container.appendChild(contentContainer)

  return container;
}
