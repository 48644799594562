<template>
  <nav id="rightSidebar" >
    <div class="opener-wrapper">
      <div class="opener" @click="showSidebar"><i v-if="openIcon" v-bind:class="['fal', 'fa-fw', 'fa-'+openIcon]"></i></div>
    </div>
    <div class="sidebar-wrapper h-100">
      <div class="closer-wrapper">
        <div class="closer" @click="hideSidebar"><i v-if="closeIcon" v-bind:class="['fal', 'fa-fw', 'fa-'+closeIcon]"></i></div>
      </div>
      <component :componentId="pageComponent.componentId" :class="pageComponent.classes" :componentData="pageComponent" :components="pageComponent.components" :fixedWidth="pageComponent.fixedWidth" :is="pageComponent.name" :key="index" v-bind:props="pageComponent.props" v-for="(pageComponent, index) in pageComponents"></component>
    </div>
  </nav>
</template>
<script>
import NavList from '../nav/NavList.vue';
import { commonProperties } from '../../mixins/commonProperties'
import { componentFunctions } from '../../mixins/componentFunctions'

export default {
  name: 'TheRightSidebar',
  props : [
    'props',
  ],
  mixins: [commonProperties, componentFunctions],
  components: {
    NavList,
  },
  watch: {
    menuItems (to, from){
      this.providedMenuItems = to;
    },
    $route(to, from) {
      this.hideSidebar();
      this.resetWindow();
    }
  },
  methods: {
    initialState (){
      return {
        providedMenuItems: [],
        openIcon: 'stream',
        closeIcon: 'times',
        pageComponents: '',
        html: document.getElementsByTagName("html"),
        sidebar: document.getElementsByClassName("sidebar-wrapper"),
      }
    },
    getMenuItems() {
      this.providedMenuItems = this.props.menuItems;
    },
    showSidebar(){
      this.html[0].classList.add("right-sidebar-open");
      this.sidebar[0].classList.add("open");
    },
    hideSidebar(){
      if(this.html.length && this.html[0]) {
        this.html[0].classList.remove("right-sidebar-open");
      }
      if(this.sidebar.length) {
        this.sidebar[0].classList.remove("open");
      }
    }
  },
  created (){
    this.hideSidebar();
    this.getMenuItems();
    if(typeof this.props.openIcon !== 'undefined'){
      this.openIcon = this.props.openIcon;
    }
    if(typeof this.props.closeIcon !== 'undefined'){
      this.closeIcon = this.props.closeIcon;
    }
  }
};
</script>
<style lang="scss">
html{
  &.right-sidebar-open{
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  }
}
#rightSidebar{
  .opener{
    color: $c-white;
    cursor: pointer;
    padding: 10px;
    font-size: 25px;
    text-align: right;
  }
  .sidebar-wrapper {
    display: none;
    position: absolute;
    right: -500px;;
    top: 0;
    width: 500px;
    max-width:100%;
    flex: 0 0 500px;
    background: $brand--c1;
    animation: slide 0.5s forwards;
    animation-delay: 0s;
    &.open{
      display: flex;
    }
    .closer-wrapper {
      position: absolute;
      right: 0;
      .closer {
        color: $brand--c1--hover-text;
        padding: 20px;
        font-size: 25px;
        cursor: pointer;
      }
    }
    @keyframes slide {
      100% { right: 0; }
    }
    .menu{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .group {
        width: 100%;
        text-align: center;
        &:hover{
          background: none;
        }
        a{
          color: $brand--c1--hover-text;
          &:hover{
            color: $brand--c2;
          }
        }
      }
    }
  }
}
</style>