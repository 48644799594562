<template>
  <div class="beatingHeartJourney">
    <BeatingHeart v-for="(color, ix) in hearts"
                  :key="color"
                  :animate="false"
                  :class="{active: activeIx === ix}"
                  :colour="color"
    />
  </div>
</template>
<script setup>
import BeatingHeart from "@/components/BeatingHeart.vue";
import {computed} from "vue";

const props = defineProps({
  activeColor: {
    type: String,
    required: false,
    default: '',
  }
});

const activeIx = computed(() => {
  const [r, , b] = props.activeColor.match(/\w{1,2}/g).map(h => parseInt(h, 16));

  if (r === 0) {
    if (b === 0) {
      return 0;
    }

    if (b <= 64) {
      return 1;
    }

    if (b <= 128) {
      return 2;
    }

    if (b <= 192) {
      return 3;
    }

    if (b <= 255) {
      return 4;
    }
  }

  if (r <= 64) {
    return 5;
  }

  if (r <= 128) {
    return 6;
  }

  if (r <= 192) {
    return 7;
  }

  if (r <= 255) {
    return 8;
  }

  return 0;
});

const hearts = [
  'rgb(0, 0, 0)',
  'rgb(0, 0, 64)',
  'rgb(0, 0, 128)',
  'rgb(0, 0, 192)',
  'rgb(0, 0, 255)',
  'rgb(64, 0, 192)',
  'rgb(128, 0, 128)',
  'rgb(192, 0, 64)',
  'rgb(255, 0, 0)',
]

</script>

<style lang="scss" scoped>
.beatingHeartJourney {
  display: flex;

  .beatingHeart {
    margin-left: -50px;
    width: 100px;
    min-width: 0;

    &.active {
      transform: scale(1.3);
      transform-origin: bottom;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
