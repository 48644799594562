<template>
  <nav
      :class="classes"
      class="simpleDropdownMenu flex align-items-end simple-dropdown-nav simple-dropdown-nav__admin"
  >
    <div class="menu-group">
      <button
          :class="({
            collapsed,
          })"
          aria-expanded="false"
          class="simpleDropdownMenu__toggle"
          data-bs-toggle="collapse"
          @click.prevent="collapsed = !collapsed"
      ><i
          v-if="menuIcon"
          :class="['fal', 'fa-fw', `fa-${menuIcon}`]"
      ></i
      >{{ menuTitle }}
      </button>
      <ul v-if="!collapsed && menuItems.length" class="list-unstyled simpleDropdownMenu__dropdown">
        <li v-for="group in menuItems">
          <router-link
              :target="group.target ? group.target : '_self'"
              :to="group.href"
          ><i
              v-if="group.icon"
              :class="['fal', 'fa-fw', `fa-${group.icon}`]"></i>
            {{ group.title }}
          </router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import commonProps from '@/_core/components/_properties/common';

defineProps({
  ...commonProps,
  menuItems: {
    type: Array,
    default: () => [],
  },
  menuIcon: {
    type: String,
    default: '',
  },
  menuTitle: {
    type: String,
    required: 'Menu',
  }
})

const collapsed = ref(true);

</script>

<style lang="scss">
.simpleDropdownMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__toggle {
    background-color: transparent;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $brand--c1;
    color: $brand--c1;

    &.collapsed {
      border-color: transparent;
    }

    &:hover {
      background: #eee;
    }
  }

  .menu-group {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
    font-weight: 300;

    a, button {
      padding: 15px 45px 15px 25px;
      display: block;
      text-decoration: none;
      position: relative;

      &.dropdown-toggle {
        &:after {
          display: block;
          top: 25px;
          right: 25px;
          position: absolute;
        }
      }
    }

    i {
      margin-right: 15px;
    }

    &__dropdown {
      background: #ddd;
      position: absolute;
      width: 100%;
      z-index: 9;
      margin: 0;
      display: flex;
      flex-direction: column;

      li {
        list-style: none;
        padding: 10px 20px;

        &:hover {
          background: $brand--c1;
          cursor: pointer;

          a {
            color: $brand--c1--hover-text;
          }
        }

        a {
          padding: 0;
          color: $brand--c1;
        }
      }
    }
  }

  &.simple-dropdown-nav__admin {
    position: relative;

    .menu-group {
      > a {
        border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;

        &.collapsed {
          border-color: transparent;
        }
      }

      ul {
        border-bottom: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        background: $c-white;
        border-color: $brand--c1;
      }
    }
  }

  .simpleDropdownMenu__dropdown {
    z-index: 10000;
    position: relative;
  }
}



@media (max-width: 1024px) {
  .simple-dropdown-nav.simple-dropdown-nav__admin .menu-group > a,
  .simple-dropdown-nav .menu-group ul li a {
    font-size: 0;
    padding: 15px;
  }

  .simple-dropdown-nav.simple-dropdown-nav__admin .menu-group > a i,
  .simple-dropdown-nav .menu-group ul li a i {
    font-size: 25px;
    margin-right: 0;
  }

  .simple-dropdown-nav.simple-dropdown-nav__admin {
    width: auto !important;
  }

  .simple-dropdown-nav .menu-group a.dropdown-toggle:after {
    display: none;
  }
}
</style>
