<template>
  <div class="error-wrapper">
    <div class="text">
      An Error Occurred...
    </div>
    <div class="svg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 160C328.8 160 336 167.2 336 176V304C336 312.8 328.8 320 320 320C311.2 320 304 312.8 304 304V176C304 167.2 311.2 160 320 160zM344 384C344 397.3 333.3 408 320 408C306.7 408 296 397.3 296 384C296 370.7 306.7 360 320 360C333.3 360 344 370.7 344 384zM272 32C331.5 32 384.1 61.55 416 106.8C430.5 99.87 446.8 96 464 96C525.9 96 576 146.1 576 208C576 218.7 574.5 228.1 571.7 238.8C612.3 260.2 640 302.9 640 352C640 422.7 582.7 480 512 480H144C64.47 480 0 415.5 0 336C0 273.2 40.15 219.9 96.17 200.1C100.3 106.6 177.4 32 272 32zM272 64C194.6 64 131.5 125 128.1 201.5C127.6 214.6 119.1 225.1 106.8 230.3C63.18 245.7 32 287.2 32 336C32 397.9 82.14 448 144 448H512C565 448 608 405 608 352C608 315.2 587.3 283.2 556.8 267.1C543.4 259.1 536.8 244.5 540.9 229.1C542.9 223 544 215.7 544 208C544 163.8 508.2 128 464 128C451.7 128 440.1 130.8 429.7 135.7C415.7 142.4 398.8 137.9 389.8 125.2C363.7 88.12 320.7 64 272 64V64z"/></svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingError',
  data: function () {
    return {};
  },
}
</script>
<style lang="scss">
.error-wrapper {
  background: #b03939;;
  color: $c-white;
  padding: 10px;
  margin-bottom: 10px;
  .text{
    width: 200px;
    display: inline-block;
  }
  .svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    svg {
      background: transparent !important;
      width: 25px;
      height: 25px;
      fill: $c-white;
    }
  }
}
</style>