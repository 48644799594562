import {Enumify} from 'enumify';

class TabulatorFormatter extends Enumify {
  static ARRAY = new TabulatorFormatter();
  static BEATING_HEART = new TabulatorFormatter();
  static CHECK_STATUS = new TabulatorFormatter();
  static CONCATENATE = new TabulatorFormatter();
  static CONDITION = new TabulatorFormatter();
  static DATE_DUE = new TabulatorFormatter();
  static EMAIL = new TabulatorFormatter();
  static FINANCE_ESTIMATE = new TabulatorFormatter();
  static FINANCE_ESTIMATE_STATUS = new TabulatorFormatter();
  static FONT_AWESOME_ICON = new TabulatorFormatter();
  static IMAGE = new TabulatorFormatter();
  static IMAGE_MULTIPLE = new TabulatorFormatter();
  static LINK = new TabulatorFormatter();
  static NUMBER = new TabulatorFormatter();
  static PO_STATUS = new TabulatorFormatter();
  static SALES_ORDER_STATUS = new TabulatorFormatter();
  static SHORT_NUMBER = new TabulatorFormatter();
  static TELEPHONE = new TabulatorFormatter();
  static TEXT_BLOCK = new TabulatorFormatter();
  static XERO = new TabulatorFormatter();
  static _ = this.closeEnum();
}

export default TabulatorFormatter;
