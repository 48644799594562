import useMessageStore from "@/_core/store/useMessageStore";
import useTokenStore from "@/_core/store/useTokenStore";
import {useRouter} from "vue-router";
import eventBus from "@/_core/services/eventBus";

function useApiResponseProcessor(refreshCallback = () => {
}) {
  const router = useRouter();
  const {addMessage} = useMessageStore()

  async function processResponse({data}) {
    function processMessages() {
      Object.values(data.messages || {}).forEach(addMessage);
    }

    function updateAccessToken() {
      const {setAccessToken, setRefreshToken} = useTokenStore()
      if (data.access_token) {
        setAccessToken(data.access_token);
      }
      if (data.refresh_token) {
        setRefreshToken(data.refresh_token);
      }
    }

    function hasExternalRedirect() {
      return data.hasOwnProperty('redirectToExternal');
    }

    function processExternalRedirect() {
      const {redirectToExternal} = data;
      window.location = redirectToExternal;
    }

    function hasGlobalRefresh() {
      return data.hasOwnProperty('refreshGlobal');
    }

    function hasLocalRefresh() {
      return data.hasOwnProperty('refresh');
    }

    function processGlobalRefresh() {
      eventBus.$emit("refreshGlobal")
    }

    function hasTitle() {
      return data.hasOwnProperty('metadata') && data.hasOwnProperty('metadata');
    }

    function processTitle() {
      const {metadata: {title}} = data;
      document.title = title;
    }

    function hasInternalRedirect() {
      return data.hasOwnProperty('redirect');
    }

    async function processInternalRedirect() {
      const {redirect} = data;
      if (redirect) {
        await router.push(redirect);
      }
    }

    updateAccessToken();
    processMessages();

    if (hasExternalRedirect()) {
      return processExternalRedirect()
    }

    if (hasInternalRedirect()) {
      return processInternalRedirect();
    }

    if (hasGlobalRefresh()) {
      return processGlobalRefresh();
    }

    if (hasLocalRefresh()) {
      return refreshCallback();
    }

    if (hasTitle()) {
      processTitle();
    }

    return data;
  }

  async function processError(error) {
    console.error(error);

    const messages = [];

    if (typeof error?.response?.data?.messages !== 'undefined') {
      Object.values(error.response.data.messages).forEach(({content}) => {
        messages.push({
          content: `<span class="error">${content}</span><br/>`,
          type: 'error'
        });
      });
    }

    return messages;
  }


  return {processResponse, processError}
}


export default useApiResponseProcessor
