<template>
  <iframe class="iframe" :class="getClasses()" :src="src" :title="title" @load="resizeIframe()"></iframe>
</template>
<script>
  import { commonProperties } from '../../mixins/commonProperties'

    export default {
        name: 'Iframe',
        mixins: [commonProperties],
        watch: {
            rows (to, from){
                this.rows = to;
            },
        },
        methods: {
            initialState (){
                return {
                  src: '',
                  title: ''
                }
            },
            resizeIframe(e) {
              var clickedElement = e.target;
              //obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
            }
        },
        created() {
            this.src = this.componentData.props.src;
            this.title = this.componentData.props.title;
        },
    }
</script>
<style lang="scss">
</style>