<template>
  <div class="form-field-container file-field-container" :class="classes">
    <div :class="[label ? 'form-group' : 'hidden-field-wrapper', {'form-group--error': $v.field.value.$error}]">
      <label v-if="label" class="form__label" :for="name" :class="inputType !== 'date' ? 'bmd-label-floating' : ''">{{label}}</label>
      <input
          @input="$v.value.$touch()"
          :class="{'form-input--error': $v.value.$error}"
          v-on:change="watchFileInput"
          :disabled="!!disabled"
          class="form-control form__input"
          :type="inputType"
          :name="name"
          :placeholder="placeholder"
          :value="value"
          :id="id"/>
    </div>
  </div>
</template>

<script setup>
import {fieldFunctions} from "../../mixins/fieldFunctions";
  import {computed, ref} from "vue";
  import classResolver from "@/_core/services/classResolver";
  import classesProp from '@/_core/components/_properties/classes';
  import Fieldset from "./Fieldset.vue";

  const props = defineProps({
    ...classesProp,
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 10
    },
    cols: {
      type: Number,
      default: 100
    },
    type: {
      type: String,
      default: 'input'
    },
    enable_editor: {
      type: Boolean,
      default: false
      },
    disabled: {
      type: Boolean,
      default: false
  },
    validations: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Boolean, null],
      default: null,
    },
    help: {
      type: String,
      default: '',
  }
  });

</script>