<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId: visitId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <p>{{ notes }}</p>
  </DashboardPanel>
</template>
<script setup>
import {defineProps, ref,} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const props = defineProps({
  visitId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const notes = ref([]);

const {hydrated, hydrate} = useHydration(({notes: _notes}) => {
  notes.value = _notes;
});

</script>
