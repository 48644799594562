export default function (cell) {
  if (cell.getValue() === null) {
    return '';
  }
  const {colour = 'inherit', class: cls = ''} = cell.getValue();

  console.log({colour, cls})

  const icon = document.createElement('i');
  icon.setAttribute('class', cls.trim());
  icon.style.color = colour;

  return icon;
}
