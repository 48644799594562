import classes from './classes'

export default {
  componentId: {
    type: String,
    required: true,
  },
  componentData: {
    type: Object,
    default: () => ({})
  },
  ...classes
}
