<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      class="companyBeatingHeartPanel"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <div class="companyBeatingHeartPanel__inner">
      <div class="companyBeatingHeartPanel__heart">
        <BeatingHeart
            v-if="beatingHeart"
            :colour="beatingHeart.colour"
            :content="beatingHeart.content"
            :rate="beatingHeart.rate"
        />
      </div>
    </div>
    <div class="companyBeatingHeartPanel__journey">
      <BeatingHeartJourney
          :active-color="beatingHeart.colour"
      />
    </div>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import BeatingHeart from "@/components/BeatingHeart.vue";
import BeatingHeartJourney from "@/components/BeatingHeartJourney.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const beatingHeart = ref(null);

const {hydrate, hydrated} = useHydration(({
                                            beating_heart: _beating_heart,
                                          }) => {
  beatingHeart.value = _beating_heart;
});

</script>

<style lang="scss" scoped>
.companyBeatingHeartPanel {
  &__inner {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__heart {
    width: 50%;
    min-width: 175px;
  }

  .beatingHeartJourney {
    margin-top: .5em;
  }

}
</style>

