import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'collapse-loaded';

const useCollapseLoadedStore = defineStore(STORE, () => {
  const collapseLoaded = ref(false);

  function setCollapseLoaded(value) {
    collapseLoaded.value = value;
  }

  return {collapseLoaded, setCollapseLoaded};

});

export default useCollapseLoadedStore;
