export default function (cell, formatterParams, onRendered) {
  const value = cell.getValue();
  const {count, status} = value;
  let icon = '';

  switch (status.toUpperCase()) {
    case 'PAID':
      icon = '<i class="fa fa-fw fa-check-circle text-success" title="Paid"></i>'
      break;
    case 'INVOICED':
      icon = '<i class="fa fa-fw fa-check-circle text-warning" title="Invoiced"></i>'
      break;
    case 'AWAITING_INVOICE':
      icon = '<i class="fa fa-fw fa-exclamation-triangle text-warning" title="Awaiting Invoice"></i>'
      break;
    case 'NONE':
      icon = `<i 
                class="fa fa-fw fa-times-circle text-danger"
                title= "No PO";
             ></i>`;
      break;
    default:
      icon = '<i class="fa fa-fw fa-times-circle text-danger"></i>'
      break;
  }

  const el = document.createElement('div');
  el.classList.add('d-flex', 'flex-column', 'align-items-center');


  el.innerHTML = `<div>${icon}</div><div>${count}</div>`;
  return el;
}
