const createdFilter = function (cell, onRendered, success, cancel, editorParams) {
  function buildValues(e) {
    const values = {
      start: start.value,
      end: end.value,
      search: search.value,
    };

    success(values);
  }

  const container = document.createElement("div");
  container.classList.add("created-filter");

  const searchContainer = document.createElement("div");
  searchContainer.classList.add("search-filter");

  const search = document.createElement("input");
  search.setAttribute("type", "text");
  search.setAttribute("placeholder", "type name here");

  const datesContainer = document.createElement("div");
  datesContainer.classList.add("min-max-filter");

  //create and style inputs
  const start = document.createElement("input");
  start.setAttribute("type", "date");
  start.setAttribute("placeholder", "Min");

  const end = document.createElement("input");
  end.setAttribute("type", "date");
  end.setAttribute("placeholder", "Max");

  search.addEventListener("change", buildValues);
  start.addEventListener("change", buildValues);
  end.addEventListener("change", buildValues);

  searchContainer.appendChild(search);
  datesContainer.appendChild(start);
  datesContainer.appendChild(end);

  container.appendChild(searchContainer);
  container.appendChild(datesContainer);

  return container;
};

export default createdFilter;
