<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      class="companyNotesPanel"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <p class="companyNotesPanel__notes">{{ notes }}</p>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const notes = ref([]);

const {hydrated, hydrate} = useHydration(({notes: _notes}) => {
  notes.value = _notes;
});

</script>

<style lang="scss">
.companyNotesPanel {
  &__notes {
    white-space: pre;
  }
}
</style>
