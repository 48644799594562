<template>
    <div class="card icarus-card-tile" :class="classes">
      <div class="card-header">
        <h3 v-html="title" class="card-title"></h3>
        <div class="d-flex">
          <component :componentId="supportComponent.componentId" :class="supportComponent.classes"
                     :componentData="supportComponent" :props="supportComponent.props"
                     :components="supportComponent.components" :fixedWidth="supportComponent.fixedWidth"
                     :is="supportComponent.name" :key="index" v-bind:filters="filters"
                     v-for="(supportComponent, index) in supComponents"></component>
          <a class="btn card-refresh-button" @click="loadContent()" v-html="refreshButton" v-if="refreshButton !== null"></a>
        </div>
      </div>
      <div class="card-body">
        <component v-bind:props="pageComponent.props" :componentId="pageComponent.componentId" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></component>
      </div>
    </div>
</template>
<script>

import {componentFunctions} from "../../mixins/componentFunctions";
import commonProperties from "@/_core/components/_properties/common";
import Global from "@/_core/services/global";
import axios from "axios";
import Api from "@/_core/services/api";

function initialState (){
    return {
      pageComponents: '',
      classes: '',
      supComponents: '',
      contentSrc: '',
      refreshButton: null,
    }
}

export default {
    name: 'CardTile',
    mixins: [
      commonProperties,
      componentFunctions,
    ],
    props : [
      'props',
      'components',
      'componentData'
    ],
    data: function () {
      return initialState();
    },
    watch: {
      $route (to, from){
          this.resetWindow();
      },
      components (to, from){
          this.pageComponents = to;
      }
    },
    methods: {
      resetWindow: function (){
          Object.assign(this.$data, initialState());
      },
      loadContent() {
        Global.checkAuthenticated(this.$router.currentRoute.path).then((response) => {
          this.getSrcData();
        });
      },
      async getSrcData() {
        Api.get(this.contentSrc+'?='+Date.now())
          .then(response => {
            this.pageComponents = response.data.components;
          })
          .catch(error => {
              console.log(error);
          });
      },
    },
    created(){
      this.title = this.componentData.props.title;
      this.resetWindow();
        this.getComponents();
      if(this.componentData){
        this.classes = this.componentData.classes;
      }
      if (this.contentSrc = this.props.contentSrc) {
        this.loadContent();
      }
      if (typeof this.props.refreshButton !== 'undefined') {
        this.refreshButton = this.props.refreshButton;
      }
    }
};
</script>
<style lang="scss">
</style>