<template>
<div class="icarus-offcanvas-wrapper">
  <div class="icarus-offcanvas-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
    <i class="fas fa-cog"></i>
  </div>

  <div class="offcanvas offcanvas-end icarus-offcanvas" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
      <h3 class="icarus-offcanvas-title mb-0" v-html="title"></h3>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <component v-bind:props="pageComponent.props" :componentId="pageComponent.componentId" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></component>
    </div>
  </div>
</div>
</template>
<script>
  import { commonProperties } from '../../mixins/commonProperties'
  import eventBus from "../../services/eventBus";

    export default {
      name: 'Offcanvas',
      props : [
        'props',
        'components',
        'componentData'
      ],
      watch: {
        components (to, from){
          this.pageComponents = to;
        }
      },
      mixins: [commonProperties],
      methods: {
        initialState (){
            return {
              title: '',
              pageComponents: '',
              classes: '',
            }
        },
        getComponents() {
          this.pageComponents = this.components;
        }
      },
      created() {
        this.title = this.componentData.props.title;
        this.getComponents();
        if(this.componentData){
          this.classes = this.componentData.classes;
        }
      },
    }
</script>
<style lang="scss">


</style>