<template>
  <div class="tabFilter">
    <ul class="list-unstyled list-inline tabFilter__filters">
      <li
          v-for="tab in tabs"
          :key="tab.label"
          :class="tab.label === activeTab ? 'tabFilter--active' : ''"
          class="list-inline-item tabFilter__filter"
      >
        <button
            class="btn btn-link"
            type="button"
            @click="handleTabClick(tab)"
        >
          {{ tab.label }}
        </button>
      </li>
    </ul>
    <DynamicComponents :components="components" class="tabFilter__components"/>
  </div>
</template>

<script setup>
import eventBus from "@/_core/services/eventBus";
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import {ref} from "vue";

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  components: {
    type: Array,
    required: true,
  }
});

const activeTab = ref(props.tabs[0].label);

function handleTabClick({label, column, value}) {
  activeTab.value = label

  eventBus.$emit('tabulator:filter', [{
    column: column,
    value: value,
  }]);

}

</script>

<style lang="scss">
.tabFilter {
  &__filters {
    margin-bottom: 0;

  }

  &__filter {
    background-color: #6e7677;
    margin-right: 0 !important;
    border-right: 3px solid #888f90;

    &:last-child {
      border-right: none;
    }

    .btn-link {
      text-decoration: none;
      color: #fff;
    }

    &.tabFilter--active {
      background-color: #565656;
    }
  }

}
</style>
