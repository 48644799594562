<template>
    <div :class="classes" class="link-tile" @click="handleClick">
        <div v-if="icon" class="icon">
            <i :class="['fal', 'fa-fw', `fa-${icon}`]"></i>
        </div>
        <div class="text">
            {{ text }}
        </div>
    </div>
</template>
<script setup>
import {defineProps} from 'vue';
import commonProps from '@/_core/components/_properties/common'
import {useRouter} from "vue-router";

const props = defineProps({
  ...commonProps,
  route: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    required: true,
  },
  external: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: '_blank',
  }
})
const router = useRouter();

async function handleClick() {
  if (!props.external) {
    return await router.push(props.route);
  }

  window.open(props.route, props.target);
}
</script>

<style lang="scss">
$link-tile-icon-color: $brand--c1 !default;

.link-tile {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  cursor: pointer;

  .icon {
    font-size: 35px;
    color: $link-tile-icon-color;
  }

  .text {
    font-size: 14px;
  }
}
</style>
