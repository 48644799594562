<template>
  <div class="contactDetails">
    <div v-if="contactName" class="contactDetails__name mb-4"><strong>
      <a v-if="contactLink" :href="contactLink">{{ contactName }}</a>
      <span v-else>{{ contactName }}</span>
    </strong></div>

    <div class="contactDetails__address mb-4">
      <div v-if="businessName">{{ businessName }}</div>
      <div v-if="addressLine1">{{ addressLine1 }}</div>
      <div v-if="addressLine2">{{ addressLine2 }}</div>
      <div v-if="addressLine3">{{ addressLine3 }}</div>
      <div v-if="addressTown">{{ addressTown }}</div>
      <div v-if="addressPostcode">{{ addressPostcode }}</div>
      <div v-if="addressCountry">{{ addressCountry }}</div>
    </div>

    <div class="contactDetails__numbers mb-4">

      <div v-if="emailAddress"><i class="fa fa-fw fa-envelope"></i> <a href="`mailto:${emailAddress}`">{{
          emailAddress
        }}</a></div>
      <div v-if="telephone"><i class="fa  fa-fw fa-phone"></i> <a
          :href="linkedTelephone.href">{{ linkedTelephone.label }}</a></div>
      <div v-if="mobilePhone"><i class="fa fa-fw  fa-mobile-retro"></i> <a
          :href="linkedMobilePhone.href">{{ linkedMobilePhone.label }}</a></div>
      <div v-if="fax"><i class="fa fa-fw  fa-fax"></i> <a :href="linkedFax.href">{{ linkedFax.label }}</a></div>
    </div>
  </div>

</template>

<script setup>

import telLinker from "@/services/telLinker";
import {computed} from "vue";

const props = defineProps({
  contactName: {
    type: String,
    default: '',
  },
  contactLink: {
    type: String,
    default: '',
  },
  businessName: {
    type: String,
    default: '',
  },
  addressLine1: {
    type: String,
    default: '',
  },
  addressLine2: {
    type: String,
    default: '',
  },
  addressLine3: {
    type: String,
    default: '',
  },
  addressTown: {
    type: String,
    default: '',
  },
  addressPostcode: {
    type: String,
    default: '',
  },
  addressCountry: {
    type: String,
    default: '',
  },
  emailAddress: {
    type: String,
    default: '',
  },
  telephone: {
    type: String,
    default: '',
  },
  fax: {
    type: String,
    default: '',
  },
  mobilePhone: {
    type: String,
    default: '',
  },
})

const linkedTelephone = computed(() => props.telephone ? telLinker(props.telephone) : '');
const linkedMobilePhone = computed(() => props.mobilePhone ? telLinker(props.mobilePhone) : '');
const linkedFax = computed(() => props.fax ? telLinker(props.fax) : '')
</script>
