<template>
    <div class="simple-table" :class="classes">
        <div class="text-center" v-if="title !== ''">
            <h3>{{title}}</h3>
        </div>
        <table class="table">
            <thead>
            <tr>
                <td v-for="(value, field) in tableHeaders" v-html="value"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in data" v-bind:class='{active: row.active === "1"}'>
                <td v-for="(value, field) in row" :colspan="row.length === 1 ? 2 : 1">
                    <span v-if="typeof value === 'object' && value != null">
                                <router-link v-if="value.href" v-bind:to="value.href" v-html="value.title"></router-link>
                                <component v-if="value.component" v-bind:props="value.component.props" :components="value.component.components" :class="value.component.classes" :is="value.component.name"></component>
                            </span>
                    <span v-else v-html="value"></span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import {Api} from '../../services/api'
    import { commonProperties } from '../../mixins/commonProperties'

    export default {
        name: 'SimpleTable',
        mixins: [commonProperties],
        watch: {
            props (to, from){
                this.dataSource = to.dataSrc;
                this.getData();
            },
        },
        methods: {
            initialState (){
                return {
                  dataSource: '',
                  data: [],
                  tableHeaders: [],
                  title: ''
                }
            },
            async getData(){
                var response = await Api.get(this.dataSource)
                    .then(response => {
                        this.data = response.data.rows;
                        this.tableHeaders = response.data.tableHeader;
                    })
                    .catch(error => {
                        console.log(error);
                        this.data = []
                    });
            },
        },
        created() {
            this.dataSource = this.props.dataSrc;
            if(this.componentData){
                this.title = this.componentData.title;
            }
            this.getData();
        }
    }
</script>
<style lang="scss">
.simple-table {
  table {
    &.table {
      table-layout: fixed;
      tbody {
        tr {
          background: none;
        }
      }
      td {
        padding: .4rem;
      }
    }
  }
}
</style>