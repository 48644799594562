function classResolver(classes) {
  if (typeof classes === 'string') {
    return classes;
  } else if (typeof classes === 'object') {
    let {..._classes} = classes;
    if (!Array.isArray(classes)) {
      _classes = Object.entries(classes).map(([cls, active]) => active ? cls : null).filter(value => !!value);
    }

    return _classes.join(' ');
  }

  return '';
}

export default classResolver;
