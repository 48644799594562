<template>
  <button :class="classes" :disabled="disabled" class="btn" @click="followLink(buttonLink)">
    <i v-if="icon" v-bind:class="['fal', 'fa-fw', 'fa-'+icon]"></i> {{ text }}
  </button>
</template>
<script>

import useApi from "../../services/api";
import {ref} from "vue";
import {useRouter} from "vue-router";
import commonProperties from "@/_core/components/_properties/common";

export default {
  props: {
    ...commonProperties,
    rowData: {
      type: Object,
      default: () => ({}),
    },
    route: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    event: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customData: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const api = useApi();
    const router = useRouter();

    const icon = ref(props.icon);
    const classes = ref(props.classes);

    async function followLink() {
      let url = props.route;
      if (props.event !== '') {
        url = `${url}/${props.event}`;
      }
      console.log(props);
console.log('ROW DATA:');
      let rowParams = {};
      if (props.rowData ?? null) rowParams = props.rowData;
      if (props.customData.row ?? null) rowParams = props.customData.row;

      let replacements = url.match(/[^{}]*.(?=})/g); // find/replace parameters in the url e.g. {MSMID} etc.
      if (replacements && replacements.length) {
        replacements.forEach((x, i) => url = url.replace("{" + x + "}", rowParams[x]));
        url = url.replace("[", "");
        url = url.replace("]", "");
      }

      if (props.componentData.verb === 'post') {
        icon.value = 'spinner fa-spin';
        api.post(url)
            .then(response => {
              console.log(response);
              icon.value = 'check';
              classes.value = 'btn-success'
              this.processResponse(response, () => {

              });
            })
            .catch(error => {
                  classes.value = 'btn-error'
                }
            );
      } else if (props.target === '_blank') {
        window.open(url, '_blank');
      } else {
        router.push(url);
      }
    }

    return {
      classes,
      followLink
    }
  },
}
</script>
