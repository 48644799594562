<template>
  <button :class="getClasses()" @click="followLink(tileLink)" >
      <i v-bind:class="['icon', 'fal', 'fa-fw', 'fa-'+icon]"></i>{{ text }}
  </button>
</template>
<script>

import { commonProperties } from '../../mixins/commonProperties'

export default {
  name: 'SimpleTable',
  mixins: [commonProperties],
  watch: {
    props (to, from){
      this.tileLink = to.route;
      this.icon = to.icon;
      this.text = to.text;
    },
  },
  methods: {
    initialState (){
      return {
        tileLink: '',
        icon: [],
        text: [],
        title: ''
      }
    },
    async followLink(){
      this.$router.push(this.tileLink);
    },
  },
  created() {
    this.tileLink = this.props.route;
    this.icon = this.props.icon;
    this.text = this.props.text;
  }
}
</script>
<style lang="scss">

</style>