<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <ul class="list-unstyled list-inline">
      <li v-for="({url, service, icon}) in socials">
        <a :href="url" :title="service" target="_blank">
          <i :class="`fa-${icon}`" class="fa"></i>
        </a>
      </li>
    </ul>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);
const socials = ref([]);

const {hydrate, hydrated} = useHydration(({socials: _socials}) => {
  socials.value = _socials;
});

</script>
