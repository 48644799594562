<template>
  <div class="dashboardView">
    <GridLayout
        :cols="columns"
        :layout="layout"
        class="dashboardView__panels"
        responsive
    >
      <template
          v-for="({props: {componentName, ...props}, i, h, w, x, y, active}) in layout"
      >
        <GridItem
            v-if="active"
            :h="h"
            :i="i"
            :w="w"
            :x="x"
            :y="y"
            @moved="onMove"
            @resized="onResize"
        >
          <Component
              :is="componentName"
              v-bind="props"
              @removed="removePanel(i)"
          />
        </GridItem>
      </template>
    </GridLayout>

    <ItemSelector
        v-if="canSelectPanels"
        v-model:active="activePanels"
        :available="availablePanels"
        title="Panels"
    />

  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import {GridItem, GridLayout} from 'vue-grid-layout-v3';
import ItemSelector from "@/components/ItemSelector.vue";
import useApi from "@/_core/services/api";

const api = useApi();

const props = defineProps({
  panels: {
    type: Array,
    default: () => [],
  },
  activePanels: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Object,
    default: () => ({
      lg: 6,
      md: 6,
      sm: 6,
      xs: 1,
      xxs: 1,
    }),
  },
  viewId: {
    type: String,
    required: this,
  },
  canSelectPanels: {
    type: Boolean,
    default: true,
  },
  saveLayoutRoute: {
    type: String,
    required: true,
  }
});

function removePanel(panelId) {
  activePanels.value = activePanels.value.filter(_panelId => _panelId !== panelId);
}

const activePanels = ref(props.activePanels.filter(
    panel => props.panels.find(({props: {panelId}}) => panelId === panel)?.props.active || false
));
const availablePanels = computed(() => props.panels.map(({props: {panelId, title}}) => ({
  itemId: panelId,
  title,
})));
const layout = ref([]);

function initLayout() {
  layout.value = props.panels
      .map(({position, ...panel}) => {
            const {..._panel} = panel;
            const {panelId} = panel.props
            _panel.i = panelId
            _panel.active = activePanels.value.includes(panelId)
            _panel.h = position.h;
            _panel.w = position.w;
            _panel.x = position.x;
            _panel.y = position.y;

            return _panel;
          }
      );
}

function onResize() {
  saveLayout();

}

function onMove(i, x, y) {
  saveLayout();
}

async function saveLayout() {
  api.post(props.saveLayoutRoute, {
    viewId: props.viewId,
    layout: layout.value.map(({i, h, w, x, y}) => ({
      id: i,
      active: activePanels.value.includes(i),
      h,
      w,
      x,
      y,
    }))
  });
}

function updateLayout() {
  layout.value.forEach(({i}, ix) => {
    layout.value[ix].active = activePanels.value.includes(i);
  });
}

watch(activePanels, () => {
  updateLayout();

  saveLayout();
});

onMounted(initLayout);


</script>

<style lang="scss">
.dashboardView {
  position: relative;
}
</style>
