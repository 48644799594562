<template>
  <div :class="classes"
       class="basic-link"
       @click="followLink(route)"
       v-html="text"
  />
</template>

<script setup>
import classesProp from '@/_core/components/_properties/classes';
import {useRouter} from "vue-router";

const props = defineProps({
  ...classesProp,
  route: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});
const router = useRouter();

async function followLink() {

  if (props.route.match(/^https?:\/\//)) {
    window.location.href = props.route;
    return;
  }

  await router.push(props.route);
}

</script>

<style lang="scss">
.basic-link {
  text-align: left;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
