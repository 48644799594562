<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId: parcelforceShipmentId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <HistoryList :history="history"/>
  </DashboardPanel>
</template>
<script setup>
import {defineProps, ref,} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import HistoryList from "@/components/HistoryList.vue";

const props = defineProps({
  parcelforceShipmentId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const history = ref([]);

const {hydrate, hydrated} = useHydration(({history: _history}) => {
  history.value = _history;
});


</script>

