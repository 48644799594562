<template>
  <div class="progressbar-wrapper">
    <div class="d-flex text progressbar-labels" v-if="showLabels">
      <div class="progressbar-label col" :class="{ 'text-end': index === stages.length-1, 'text-center': index > 0 && index < stages.length-1 }" v-for="(stage, index) in stages">
        {{ stage }}
      </div>
    </div>
    <div class="progress" role="progressbar" aria-label="Animated striped example" :aria-valuenow="getShadedPercentage()" aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{width:getShadedPercentage(true)}">{{ getShadedPercentage(true) }}</div>
    </div>
  </div>
</template>
<script>
import { commonProperties } from '../../mixins/commonProperties'

export default {
  name: 'ProgressBar',
  mixins: [commonProperties],
  watch: {
    props (to, from){
      this.activeStage = to.activeStage;
      this.stages = to.stages;
      this.showLabels = to.showLabels;
    },
  },
  methods: {
    initialState (){
      return {
        activeStage: 0,
        stages: ['Start', 'Middle', 'End'],
        showLabels: true,
        barWidth: 0,
      }
    },

    getShadedPercentage(addPercSymbol=false){
      let shaded = (100*(this.activeStage/(this.stages.length)));
      if (shaded == 0) shaded = 1;
      return shaded + (addPercSymbol?'%':'');
    },

    getLabelWidth(){
      return (100/(this.stages.length)) + '%';
    }
  },
  created() {
    this.activeStage = this.props.activeStage;
    this.stages = this.props.stages;
    this.showLabels = this.props.showLabels;
    this.barWidth = this.getShadedPercentage();
  }
}
</script>
<style lang="scss">
  .progressbar-wrapper {
    padding: 40px 0;
  }
 .progressbar-labels {
   padding: 8px 0;
   font-size: 1.1rem;
   font-weight: bold;
 }
 .progress {
   height: 30px;
 }
</style>