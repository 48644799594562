export default function (cell, formatterParams) {
  let value = (cell.getValue() || '');

  if (!value) {
    return '';
  }

  const href = `mailto:${value}`;

  return `<a href="${href}">${value}</a>`;
}
