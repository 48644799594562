<template>
  <fieldset class="form-fieldset" :class="classes">
    <legend v-html="legend"/>
    <DynamicComponents :components="components"/> <!-- Inputs handled in here -->
  </fieldset>
</template>

<script setup>
import classesProp from '@/_core/components/_properties/classes';
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";

const props = defineProps({
  ...classesProp,
  id: {
    type: String,
    required: false
  },
  legend: {
    type: String,
    default: ''
  },
  components: {
    type: Array,
    required: true
  },
});

</script>
