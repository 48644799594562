<template>
    <div id="content-wrapper" class="content">
        <div class="secondary-components">
            <DynamicComponents :components="components"/>
        </div>
        <div id="content-inner">
            <DynamicComponents :components="supportComponents"/>
        </div>
    </div>
</template>
<script setup>
import {defineProps, onMounted, ref} from 'vue';
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import useApi from "@/_core/services/api";
import useCollapseLoadedStore from "@/_core/store/useCollapseLoadedStore";

const props = defineProps({
  components: {
    type: Array,
    default: () => [],
  },
  supportComponents: {
    type: Array,
    default: () => [],
  },
  filters: {
    type: Object,
    default: () => ({}),
  },
  dataSource: {
    type: String,
    default: ''
  }
})

const components = ref(props.components);

const api = useApi();
const {collapseLoaded, setCollapseLoaded} = useCollapseLoadedStore();

async function getContentData() {
  if (!props.dataSource) {
    return;
  }
  const glue = props.dataSource.indexOf('?') === -1 ? '?' : '&';
  const url = `${props.dataSource}${glue}${props.filters.constructed || ''}`;

  try {
    const response = await api.get(url)
    setCollapseLoaded(false);
    components.value = response.data.components;
    contentReload();
  } catch (error) {
    console.error(error);
  }
  ;
}

function contentReload() {
  setTimeout(() => {
    if (!collapseLoaded) {
      setCollapseLoaded(true);
      const questionCategories = document.getElementsByClassName("category-row-wrapper");
      const questionCategoriesLength = questionCategories.length;
      if (questionCategoriesLength <= 0) {
        setCollapseLoaded(false)
      }

      for (let i = 0; i < questionCategoriesLength; i++) {
        questionCategories[i].addEventListener("click", function () {
          const id = this.getAttribute('data-category-id');
          const open = this.getAttribute('data-open');
          const element = document.getElementById(id);
          if (open === "true") {
            element.style.height = `0px`;
            this.setAttribute('data-open', 'false');
          } else {
            element.style.height = `${element.scrollHeight}px`;
            this.setAttribute('data-open', 'true');
          }
        });
      }
    }
  }, 1000);
}

onMounted(getContentData)
</script>
