<template>
    <div class="row m-0">
        <div class="col-sm-12 ">
            <nav>
                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a v-for="(pageComponent, index) in pageComponents" :key="index" class="nav-item nav-link" :class="pageComponent.active ? 'active' : ''" :id="pageComponent.id + '-tab'" data-toggle="tab" :aria-controls="'#' + pageComponent.id" :aria-selected="pageComponent.active ? 'true' : 'false'" :href="'#' + pageComponent.id" role="tab">{{pageComponent.label}}</a>
                </div>
            </nav>
            <div class="tab-content col-sm-12" id="nav-tabContent">
                <component :componentId="pageComponent.componentId" :props="pageComponent.props" :components="pageComponent.components" :componentData="pageComponent" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" :key="index" v-for="(pageComponent, index) in pageComponents"></component>
            </div>
        </div>
    </div>
</template>
<script>

    function initialState (){
        return {
            pageComponents: '',
        }
    }

    export default {
        name: 'TabsContainer',
        props : [
            'components'
        ],
        data: function () {
            return initialState();
        },
        watch: {
            $route (to, from){
                this.resetWindow();
            },
            components (to, from){
                this.pageComponents = to;
            }
        },
        methods: {
            getComponents() {
                this.pageComponents = this.components;
            },
            resetWindow: function (){
                Object.assign(this.$data, initialState());
            },
        },
        created(){
            this.getComponents();
        }
    };
</script>
<style lang="scss">
</style>