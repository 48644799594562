<template>
    <div :class="classes" class="contentTile">
        <div v-if="title" class="title" v-html="wrappedTitle"/>
        <div v-if="parsedContent" class="content">
            <template v-if="typeof parsedContent === 'object' && parsedContent !== null">
                <component :is="parsedContent.component.name"
                           v-if="parsedContent.component"
                           :class="parsedContent.component.classes"
                           :components="parsedContent.component.components"
                           v-bind="parsedContent.component.props"/>
            </template>
            <p v-else v-html="parsedContent"/>
        </div>
    </div>
</template>
<script setup>
import {computed, defineProps} from 'vue';
import commonProps from '@/_core/components/_properties/common';

const props = defineProps({
  ...commonProps,
  header: {
    type: Number,
    default: 3
  },
  title: {
    type: String,
    required: true,
  },
  content: {
    type: [String, Object],
    default: '',
  }
})
const wrappedTitle = computed(() => `<h${props.header}>${props.title}</h${props.header}>`);
const parsedContent = computed(() => {
  if (!props.content) {
    return '';
  }
  try {
    return {
      component: JSON.parse(props.content)
    };
  } catch (e) {
    return props.content;
  }
})
</script>
