import {useVuelidate} from '@vuelidate/core';
import {maxLength, minLength, required} from "@vuelidate/validators";

const hash = require('object-hash');

export const fieldFunctions = {
  props: [
    'props',
  ],
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return this.builtFieldValidations
  },
  computed: {
    builtFieldValidations() {
      return {
        field: {
          value: this.enabledValidations
        }
      }
    },

    hash: () => hash,
  },
  watch: {
    fieldValidations(to, from) {
      //compare object hashes to make sure they are different before we rebuild the validations.
      if (hash(to) !== hash(from)) {
        this.constructValidations();
      }
    }
  },
  methods: {
    constructValidations() {
      this.enabledValidations = {};
      if (this.fieldValidations) {
        for (const index in this.fieldValidations) {
          switch (this.fieldValidations[index].method) {
            case "required":
              this.enabledValidations.required = required;
              break;
            case "minLength":
              this.enabledValidations.minLength = minLength(this.fieldValidations[index].min);
              break;
            case "maxLength":
              this.enabledValidations.maxLength = maxLength(this.fieldValidations[index].max);
              break;
          }
        }
      }
    },
    updateCallbackForTriggers(e) {
      if (this.field.callback) {
        this.$store.commit('setCallbackTrigger', {
          callback: this.field.callback,
          method: this.field.callbackMethod,
          watch: this.field.name,
          value: this.field.value
        });
      }
      this.v$.field.value.$touch()
    },
    initialiseField() {

      if (typeof (this.field.validations) !== 'undefined') {
        this.fieldValidations = this.field.validations;
      }

      //make sure we set checkboxes to 0 if we have no value.
      if (this.field.inputType === 'checkbox') {
        if (!this.field.value) {
          this.field.value = 0;
        }
      }

      if (!this.field.value && this.field.value !== 0) {
        this.field['value'] ='';
      }
    }
  },
  created() {
    this.initialiseField();
  },
}
