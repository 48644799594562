<template>
  <div class="form-filters">
    <Form v-bind:props="pageComponent.props" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></Form>
  </div>
</template>

<script>
//We also allow the api to inject custom components to handle form alterations on the fly, e.g. call backs, custom methods and so on.
import { apiFunctions } from '../../mixins/apiFunctions';
import { classFunctions } from '../../mixins/classFunctions';
import { commonProperties } from '../../mixins/commonProperties'
import { componentFunctions } from "../../mixins/componentFunctions";
import { formFunctions } from "../../mixins/formFunctions";

export default {
  name: 'FilterForm',
  mixins: [
    apiFunctions,
    classFunctions,
    commonProperties,
    componentFunctions,
    formFunctions
  ],
  methods : {
    initialState (){
      return {}
    }
  },
};
</script>

<style lang="scss">
</style>