<template>
    <div :class="classes" class="table__basic-table">
        <div v-if="title !== ''" class="table__basic-table-title text-center"><h3>{{ title }}</h3></div>
        <table class="table">
            <thead>
            <tr>
                <td v-for="(value, field) in tableHeaders" v-html="value"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in data" v-bind:class='{active: row.active === "1"}'>
                <td v-for="(value, field) in row" :colspan="row.length === 1 ? 2 : 1">
            <span v-if="typeof value === 'object' && value != null">
                  <router-link v-if="value.href" v-bind:to="value.href" v-html="value.title"></router-link>
                  <component :is="value.component.name" v-if="value.component"
                             :class="value.component.classes" :components="value.component.components"
                             v-bind:props="value.component.props"></component>
              </span>
                    <span v-else v-html="value"></span>
                </td>
                <td v-if="typeof pageComponents !== 'undefined'">
                    {{ console.log(pageComponents) }}
                    <component
                            :is="pageComponent.name"
                            v-for="(pageComponent, index) in pageComponents"
                            :key="index"
                            :class="pageComponent.classes"
                            :componentData="pageComponent"
                            :componentId="pageComponent.componentId"
                            :components="pageComponent.components"
                            :fixedWidth="pageComponent.fixedWidth"
                            :rowData="row"
                            v-bind:props="pageComponent.props"></component>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {commonProperties} from '../../mixins/commonProperties'
import {componentFunctions} from "../../mixins/componentFunctions";

export default {
  name: 'BasicTable',
  mixins: [
    commonProperties,
    componentFunctions
  ],
  watch: {
    props(to, from) {
      this.data = to.data.rows;
      this.tableHeaders = to.data.tableHeader;
    },
    componentData(to, from) {
      this.basePath = to.basePath;
    },
  },
  methods: {
    initialState() {
      return {
        data: [],
        tableHeaders: [],
        title: '',
        pageComponents: '',
      }
    },
  },
  created() {
    this.data = this.props.data.rows;
    this.tableHeaders = this.props.data.tableHeader;
    if (this.componentData) {
      this.title = this.componentData.title;
    }
    this.getComponents();
  },
  computed: {
    console: () => console,
    window: () => window,
  }
}
</script>
<style lang="scss">
table {
  &.table {
    table-layout: fixed;

    tbody {
      tr {
        background: none;
      }
    }

    td {
      word-wrap: break-word;
      white-space: normal;
      padding: $table--cell--padding;
      font-size: $table--cell--size;
    }
  }
}
</style>
