function randomString(len) {

  let text = " "
  let chars = "abcdefghijklmnopqrstuvwxyz"

  for( let i=0; i < len; i++ ) {
    text += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return text
}

export default randomString


