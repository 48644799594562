const props = {
  components: {
    type: Array,
    default: () => [],
  },
  supportComponents: {
    type: Array,
    default: () => [],
  }
};

function buildQueryString(filters = {}, dataSource = '') {
  const params = new URLSearchParams();

  if (dataSource.indexOf('page') === -1) {
    params.append('page', this.page)
  }
  if (dataSource.indexOf('offset') === -1) {
    params.append('offset', this.offset)
  }
  if (dataSource.indexOf('limit') === -1) {
    params.append('limit', this.itemsPerPage)
  }

  Object.entries(filters).forEach(([key, value]) => params.append(key, value));

  return (`${dataSource.indexOf('?') === -1 ? '?' : '&'}${params.toString()}`);
}


export {props, buildQueryString};


export const componentFunctions = {
  props,
  // watch(() => ) : {
  //   components: {
  //     handler(to) {
  //       this.pageComponents = to;
  //     },
  //     deep: true,
  //   },
  //   supportComponents: {
  //     handler(to) {
  //
  //       this.supComponents = to;
  //     },
  //     deep: true,
  //   }
  // },
  methods: {
    getComponents() {
      if (this.componentData && this.componentData.supportComponents) {
        this.supComponents = this.componentData.supportComponents;
      }
      this.pageComponents = this.components;
    },
    detectComponentWidth(e) {
      let container = document.getElementById(this.getComponentId());
      this.compWidth = container !== null ? container.offsetWidth : this.compWidth;
    },
    buildQueryString,

    addParamsToLocation(queryString) {
      history.pushState({}, null, this.$route.path + queryString)
    }
  },
  created() {
    window.addEventListener("resize", this.detectComponentWidth);
    this.detectComponentWidth();
    this.getComponents();
  },
  unmounted() {
    window.removeEventListener("resize", this.detectComponentWidth);
  },
}
