import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'api-error';

const useApiErrorStore = defineStore(STORE, () => {
  const apiError = ref(null);

  function setApiError(_apiError) {
    apiError.value = _apiError;
  }

  return {
    setApiError,
    apiError
  };

});

export default useApiErrorStore;
