<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId: parcelforceShipmentId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <dt>Tracking</dt>
      <dd>{{ tracking }}</dd>

      <dt>Company</dt>
      <dd>{{ company }}</dd>

      <dt>Collection</dt>
      <dd>{{ collection }}</dd>

      <dt>Service Code</dt>
      <dd>{{ service_code }}</dd>

      <dt>Saturday</dt>
      <dd>
        <TickOrCross :tick="saturday"/>
      </dd>

      <dt>Compensation</dt>
      <dd>{{ compensation }}</dd>

      <template v-if="weight">
        <dt>Weight</dt>
        <dd>{{ weight }}</dd>
      </template>

      <template v-if="references.length">

        <dt>References</dt>
        <dd>
          <ul class="list-unstyled pl-0">
            <li v-for="reference in references" :key="reference">{{ reference }}</li>
          </ul>
        </dd>
      </template>
      <template v-if="instructions.length">

        <dt>Instructions</dt>
        <dd>
          <ul>
            <li v-for="instruction in instructions" :key="instruction">{{ instruction }}</li>
          </ul>
        </dd>
      </template>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref,} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import TickOrCross from "@/components/TickOrCross.vue";

const props = defineProps({
  parcelforceShipmentId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const tracking = ref('');
const company = ref('');

const collection = ref('');
const service_code = ref('');
const saturday = ref(false);
const compensation = ref('');
const weight = ref('');
const references = ref([]);
const instructions = ref([]);

const {hydrate, hydrated} = useHydration(({
                                            tracking: _tracking,
                                            company: _company,
                                            collection: _collection,
                                            service_code: _service_code,
                                            saturday: _saturday,
                                            compensation: _compensation,
                                            weight: _weight,
                                            references: _references,
                                            instructions: _instructions,
                                          }) => {
  tracking.value = _tracking;
  company.value = _company;
  collection.value = _collection;
  service_code.value = _service_code;
  saturday.value = _saturday;
  compensation.value = _compensation;
  weight.value = _weight;
  references.value = _references;
  instructions.value = _instructions;
});

</script>
