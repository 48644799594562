<template>
    <div id="footer" class="w-100" :class="getClasses()">
      <component :componentId="pageComponent.componentId" :class="pageComponent.classes" :componentData="pageComponent" :components="pageComponent.components" :fixedWidth="pageComponent.fixedWidth" :is="pageComponent.name" :key="index" v-bind:props="pageComponent.props" v-for="(pageComponent, index) in pageComponents"></component>
    </div>
</template>
<script>
  import { commonProperties } from '../../mixins/commonProperties'
  import { componentFunctions } from '../../mixins/componentFunctions'

    export default {
        name: 'FooterBar',
        mixins: [commonProperties, componentFunctions],
    };
</script>