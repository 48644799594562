<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <dt>Dpd Ref.</dt>
      <dd>{{ refDpd }}</dd>

      <dt>Company</dt>
      <dd>{{ company }}</dd>

      <dt>Collection</dt>
      <dd>{{ collection }}</dd>

      <template v-if="refOurs.length">
        <dt>Our Refs.</dt>
        <dd>
          <ul class="list-unstyled pl-0">
            <li v-for="reference in refOurs" :key="reference">{{ reference }}</li>
          </ul>
        </dd>
      </template>

      <dt>Liability</dt>
      <TickOrCross :tick="liability"/>
      <dd></dd>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import TickOrCross from "@/components/TickOrCross.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const refDpd = ref('');
const company = ref('');
const collection = ref('');
const refOurs = ref('');
const liability = ref(false);

const emit = defineEmits(['removed']);


const {hydrate, hydrated} = useHydration(({
                                            refDpd: _refDpd,
                                            company: _company,
                                            collection: _collection,
                                            refOurs: _refOurs,
                                            liability: _liability,
                                          }) => {
  refDpd.value = _refDpd;
  company.value = _company;
  collection.value = _collection;
  refOurs.value = _refOurs;
  liability.value = _liability;
});

</script>
