<template>
  <div class="form-field-container datepicker-field-container" :class="classes">
    <div :class="[
               (inputType !== 'hidden' || label) ? 'form-group' : 'hidden-field-wrapper',
            ]">
      <label v-if="label"
             :class="inputType !== 'date' ? 'bmd-label-floating' : ''"
             :for="fieldName"
             class="form__label">{{ label }}{{ required ? '*' : '' }}</label>
      <div v-if="icon" class="input-group-addon" v-html="icon"></div>
      <VueDatePicker
          v-model="fieldValue"
          @update:model-value="saveValue()"
          :class="{'form-input--error': !valid && isSubmitted}"
          :placeholder="placeholder"
          :format="format"
          :enable-time-picker="false"
          :disabled="disabled"
      ></VueDatePicker>

      <span v-if="!valid && isSubmitted" class="error text-error">{{ validationError }}</span>
    </div>
    <div v-if="help" class="form-text">{{ help }}</div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import classesProp from '@/_core/components/_properties/classes';
import useFormStore from "@/_core/store/useFormStore";
import formFields from "../_properties/formFields";
import isValid from "@/_core/services/isValid";
import VueDatePicker from '@vuepic/vue-datepicker';

let valid = ref(true);
let validationError = ref('');

const props = defineProps({
  ...classesProp,
  ...formFields,
  inputType: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'input'
  },
  icon: {
    type: String,
    default: '',
  },
});

const fieldValue = ref(props.value)

const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const classes = computed(() => classResolver({
  [props.classes]: true,
  'form-check-wrapper': props.inputType === 'checkbox',
}))


const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue() {
  if (fieldValue.value) {
    if (typeof fieldValue.value === 'string') {
      let dateParts = fieldValue.value.split('-');
      console.log(dateParts);
      fieldValue.value = new Date(dateParts[0],dateParts[1]-1,dateParts[2],12,0,0);
      console.log(fieldValue.value);
    }
    fieldValue.value = fieldValue.value.toISOString().split('T')[0];
  }
  const {validCheck, validError} = isValid(props.fieldName, props.validations, fieldValue.value);
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, fieldValue.value, validCheck, validationError);
}

onMounted(() => { // Initialise field in formStore - important
  saveValue();
})

</script>


