<template>
  <div :class="classes" class="display-table__wrapper">
    <div v-if="title" class="display-table__title">
      <h2>{{ title }}</h2>
    </div>
    <div v-if="data" class="display-table__content">
      <div v-for="(value) in data" class="display-table__row">
        <div class="display-table__row-title" v-html="value.title"></div>
        <div v-if="value.content" class="display-table__row-content" v-html="value.content"></div>
        <component :is="JSON.parse(value.component.replace('\\', '')).name" v-if="value.component"
                   :class="JSON.parse(value.component.replace('\\', '')).classes"
                   :components="JSON.parse(value.component.replace('\\', '')).components"
                   class="display-table__row-content"
                   v-bind:props="JSON.parse(value.component.replace('\\', '')).props"
                   @refresh="reloadComponent"></component>
      </div>
    </div>
  </div>
</template>

<script setup>

import classes from "@/_core/components/_properties/classes";

const props = defineProps({
  ...classes,
  title: {
    type: String,
    default: ''
  },
  data: {
    type: Array,
    default: []
  }

})
</script>

<style lang="scss">
.display-table {
  &__wrapper {
    display: flex;
    align-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    .display-table {
      &__title {

      }

      &__content {
        .display-table {
          &__row {
            flex-direction: row;
            display: flex;
            width: 100%;
            align-content: center;

            .display-table {
              &__row-title {
                width: 25%;
              }

              &__row-content {
                width: 75%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
