<template>
  <div class="form-field-container radiogroup-field-container" :class="classes">
    <label v-if="label" :for="id" class="form-label">{{ label }}</label>
    <div v-for="(option, index) in options" class="form-check">
      <input
          class="form-check-input"
          type="radio"
          :name="fieldName"
          :value="option.key"
          :checked="option.key === value"
          :disabled="disabled"
          :class="{'form-input--error': !valid && isSubmitted}"
          :id="fieldName + '_' + option.key"
          @change="saveValue()"
          v-model="fieldValue"
      >
      <label class="form-check-label" :for="fieldName + '_' + option.key">
        {{ option.value }}
      </label>
    </div>
    <div v-if="help" class="form-text">{{ help }}</div>
  </div>
</template>


<script setup>
import {computed, onMounted, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import classesProp from '@/_core/components/_properties/classes';
import useFormStore from "@/_core/store/useFormStore";
import formFields from "../_properties/formFields";
import isValid from "@/_core/services/isValid";

let valid = ref(true);
let validationError = ref('');

const props = defineProps({
  ...classesProp,
  ...formFields,
  checked: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array,
    required: true
  },
});

const fieldValue = ref(props.value)

const classes = computed(() => classResolver({
  [props.classes]: true,
}))

const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue() {
  const {validCheck, validError} = isValid(props.fieldName, props.validations, fieldValue.value);
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, fieldValue.value, validCheck, validationError);
  }

onMounted(() => { // Initialise field in formStore - important
  saveValue();
})

</script>
