let writing = false;

export function readUrlQueryParams() {
  const result = {};
  const param = window.location.search.substring(1);
  const params = param.split('&').filter(value => !!value);

  params.forEach(function (set) {
    const paramSet = set.split('=');
    if (typeof (paramSet[1]) !== 'undefined') {
      result[paramSet[0]] = paramSet[1];
    }
  });
  return result;
}

function shouldWriteData(type, data) {
  if (writing) {
    return false;
  }

  return !isDefault(type, data);
}

function isDefault(type, data) {
  switch (type) {
    case 'sort':
    case 'filter':
    case 'headerFilter':
      return data.length === 0;
    case 'page':
      return data.paginationSize === 50 && data.paginationInitialPage === 1;
  }

  return false;

}


export function write(id, type, data) {
  const params = readUrlQueryParams();

  if (shouldWriteData(type, data)) {
    params[key(id, type)] = encodeURIComponent(JSON.stringify(data));
  }

  if (isDefault(type, data)) {
    delete params[key(id, type)];
  }

  const newQuery = [];
  Object.entries(params).forEach(([key, value]) => {
    newQuery.push(`${key}=${value}`);
  });
  if (newQuery.length) {
    writing = true;
    const [href,] = window.location.href.split('?');
    const newUrl = `${href}?${newQuery.join('&')}`;
    history.replaceState({}, '', newUrl);
    window.dispatchEvent(new Event('filterwrite', {
      newUrl,
      oldUrl: window.location.href,
    }));
    writing = false;
  }
}

export function read(id, type) {
  const params = readUrlQueryParams();
  const _key = key(id, type);
  if (hasKey(params, _key)) {
    return JSON.parse(decodeURIComponent(params[_key]));
  }

  return false;
}

function key(id, type) {
  return `${id}--${type}`;
}

function tabulatorId(tabulator) {
  return `tabulator-${tabulator.options.persistenceID || (tabulator.element.getAttribute("id") || "")}`;
}

function hasKey(params, key) {
  return typeof params[key] !== 'undefined';
}

export function queryListener(tabulator) {
  function handleQueryChange() {
    const params = readUrlQueryParams();
    const id = tabulatorId(tabulator);

    function listenFor(_key, setCallback, clearCallback) {
      if (hasKey(params, _key) && setCallback) {
        return setCallback(JSON.parse(decodeURIComponent(params[_key])));
      }

      if (clearCallback) {
        clearCallback();
      }
    }

    function listenForHeaderFilters() {
      const _key = key(id, 'headerFilter');
      if (hasKey(params, _key)) {
        const filters = JSON.parse(decodeURIComponent(params[_key]));

        return filters.forEach(({field, value}) => {
          tabulator.setHeaderFilterValue(field, value);
        });
      }

      tabulator.clearHeaderFilter();
    }

    function listenForPage() {
      const _key = key(id, 'page');
      if (hasKey(params, _key)) {
        const {
          paginationSize = tabulator.options.pageSize || 10,
          paginationInitialPage = 1
        } = JSON.parse(decodeURIComponent(params[_key]));

        tabulator.setPageSize(paginationSize);
        tabulator.setPage(paginationInitialPage);

        return;
      }

      tabulator.setPageSize(tabulator.options.paginationSize || 10);
      tabulator.setPage(1);
    }

    listenFor(key(id, 'sort'), tabulator.setSort, tabulator.clearSort)
    listenForPage(key(id, 'page'))
    listenForHeaderFilters();

    tabulator.setData();
  }

  window.addEventListener('filterwrite', writing ? handleQueryChange() : null);
}
