<template>
  <div id="content-wrapper" class="content h-100">
    <div class="secondary-components">
      <component :is="supportComponent.name" v-for="(supportComponent, index) in supComponents"
                 :key="index" :class="supportComponent.classes"
                 :componentData="supportComponent" :componentId="pageComponent.componentId"
                 :components="supportComponent.components" :fixedWidth="supportComponent.fixedWidth" :props="supportComponent.props"
                 v-bind:filters="filters"></component>
    </div>
    <div id="content-inner">
      <component :is="pageComponent.name" v-for="(pageComponent, index) in pageComponents" :key="index"
                 :class="pageComponent.classes" :componentData="pageComponent" :componentId="pageComponent.componentId"
                 :components="pageComponent.components" :fixedWidth="pageComponent.fixedWidth"
                 :props="pageComponent.props"></component>
    </div>
  </div>
</template>
<script>
import {commonProperties} from '../../mixins/commonProperties'
import {componentFunctions} from "../../mixins/componentFunctions";

export default {
  name: 'FilterableContent',
  mixins: [
    commonProperties,
    componentFunctions
  ],
  watch: {
    dataSource(to, from) {
      this.dataSource = to;
      //this.getData();
    },
    componentData(to, from) {
      this.basePath = to.basePath;
    },
    filters: {
      handler(to, from) {
        this.$router.push(this.$router.currentRoute.value.path + '?' + this.filters.constructed);
      },
      deep: true
    }
  },
  methods: {
    initialState() {
      return {
        pageComponents: '',
        supComponents: '',
        filters: {
          constructed: ''
        },
        dataSource: this.$route.fullPath
      }
    },
    async getData() {
      //at this level, we want to navigate to filtered content directly.
      //this.$router.push(this.$route.path + this.filters.constructed);
    },
  },
  created() {
    this.dataSource = this.props.dataSrc;
  }
};
</script>
<style lang="scss">
</style>
