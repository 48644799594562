<template>
  <div class="menu-group">
    <a v-if="group.links" aria-expanded="false" class="dropdown-toggle"
       data-bs-toggle="collapse" role="button" v-bind:aria-controls="group.href.replace('/', '').replace(/\./g, '')"
       v-bind:href="'#' + group.href.replace('/', '').replace(/\./g, '')"><i v-if="group.icon"
                                                                             v-bind:class="['fal', 'fa-fw', 'fa-'+group.icon]"></i>
      <span
          class="parentLink">{{ group.title }}</span></a>
    <ul v-if="group.links" v-bind:id="group.href.replace('/', '').replace(/\./g, '')" class="collapse list-unstyled">
      <li class="dropdown-wrapper">
        <ul class="dropdown">
          <li v-for="link in group.links">
            <a v-if="link.links" v-bind:id="link.title"
               aria-expanded="false" class="dropdown-toggle" data-bs-toggle="collapse" role="button"
               v-bind:aria-controls="link.href.replace('/', '').replace(/\./g, '')"
               v-bind:href="'#' + link.href.replace('/', '').replace(/\./g, '')"><i
                v-if="link.icon" v-bind:class="['fal', 'fa-fw', 'fa-'+link.icon]"></i> <span
                class="parentLink">{{ link.title }}</span></a>
            <ul v-if="link.links" v-bind:id="link.href.replace('/', '').replace(/\./g, '')">
              <li v-for="subLink in link.links">
                <menu-link v-bind="subLink"></menu-link>
              </li>
            </ul>
            <menu-link v-else v-bind="link"></menu-link>
          </li>
        </ul>
      </li>
    </ul>
    <menu-link v-else v-bind="group" @click="checkReload(group.href)"></menu-link>
  </div>
</template>
<script>
import MenuLink from "@/_core/components/nav/MenuLink.vue";

export default {
  name: 'NavList',
  components: {MenuLink},
  props: ['group'],
  data: function () {
    return {}
  },
  methods: {
    checkReload(newPath) {
      if (newPath == this.$route.path) {
        this.$router.go(); // refresh the page if the route hasn't changed
      }
    }
  },
}

//$('body').on('show.bs.collapse','.collapse', function() {
//    $('.show').collapse('hide');
//});

</script>
