function isValid(fieldName, validations, value) {

  console.log('START VALIDATION:');

  let validCheck = true;
  let validError = '';

  validations.forEach((validation) => {
    if (validation.method == 'required' && (!value || !(value.trim()))) {
      validCheck = false;
      validError = 'This is a required field';
    }
  });

  return {validCheck, validError}
}


export default isValid
