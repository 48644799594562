import dateTime from "tabulator-tables/src/js/modules/Format/defaults/formatters/datetime";
import tickCross from "tabulator-tables/src/js/modules/Format/defaults/formatters/tickCross";

export default function (cell, formatterParams, onRendered) {
  const value = cell.getValue();
  if (!value) {
    return '';
  }

  const date = new Date(value);
  const formattedDate = dateTime(cell, {
    'inputFormat': 'iso',
    'outputFormat': 'MM/yyyy',
  }, onRendered);

  const el = document.createElement('div');
  el.innerHTML = `<span>${formattedDate}</span>`;

  if (date > new Date()) {
    return el;
  }


  const dummyCell = {
    getValue: () => false,
    getElement: () => document.createElement('span'),
  }

  const cross = tickCross(dummyCell, {})

  el.innerHTML = `${el.innerHTML} ${cross}`;

  return el;
}
