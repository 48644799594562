<template>
    <button type="button" class="col-md-2 btn btn-success" v-on:click="downloadFile()"><i class="fa fa-download"></i></button>
</template>

<script>
    import {Api} from '../../services/api'

    export default {
        name: 'DownloadButton',
        props : [
            'props'
        ],
        data: function () {
            return {
                ref: '',
                type: ''
            };
        },
        methods: {
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
            },
            downloadFile(){
                //get document data
                Api.get('/documents/' + this.ref + '/download?type=' + this.type).then(documentData => {
                    //get the file blob
                    Api.get(documentData.data.path, {responseType: 'arraybuffer', headers: {
                            'Content-Type': documentData.data.contentType,
                            'Accept': documentData.data.contentType
                        }}).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {type: documentData.data.contentType}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', documentData.data.name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                });
            },
        },
        created() {
            this.ref = this.props.ref;
            this.type = this.props.type;
        }
    }
</script>
<style lang="scss">
    .hidden-field-wrapper{
        display: none;
    }
</style>