import replaceUrlTags from "@/services/replaceUrlTags";

export default function (cell, formatterParams, onRendered) {
  const value = cell.getValue();

  const el = document.createElement('div');
  el.classList.add('d-flex', 'flex-column', 'align-items-center');
  if (!value) {
    const {createRoute} = formatterParams;

    if (createRoute) {
      const href = replaceUrlTags(createRoute, cell.getRow());
      const a = document.createElement('a');
      a.href = href;
      a.innerHTML = `<i class="fas fa-file-lines text-info"></i>`;
      a.addEventListener('click', (e) => {
        e.stopPropagation();
      });

      el.appendChild(a);
    }

    return el;
  }


  let icon = 'times';
  let color = 'warning';

  switch (value.toUpperCase()) {
    case 'ACCEPTED':
      icon = 'check';
      color = 'success';
      break;
    case 'LOST':
      color = 'disabled';
      break;
    case 'CASH':
    case 'REJECTED':
      color = 'info';
      break;
    case 'FINANCE':
    case 'BROKER':
    case 'QUOTE':
      break;
    case 'ESTIMATE':
    default:
      color = 'danger';
      break;
  }

  let iconEl = `<i class="fas fa-${icon}-circle text-${color}"></i>`;


  el.innerHTML = `<div>${iconEl}</div><div>${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}</div>`;
  return el;
}
