<template>
  <div :class="classes" class="form-group">
    <DynamicComponents :components="components"/> <!-- Inputs handled in here -->
  </div>
</template>

<script setup>

import classesProp from '@/_core/components/_properties/classes';
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";


const props = defineProps({
  ...classesProp,
  id: {
    type: String,
    required: false
  },
  legend: {
    type: String,
    default: ''
  },
  components: {
    type: Array,
    required: true
  },
});

</script>
