import {useRoute} from "vue-router";

function useParamsInHash() {
  const route = useRoute();

  function paramsFromHash() {
    const hash = location.hash.substring(1);
    const params = {}
    for (const [key, value] of new URLSearchParams(hash)) {
      params[key] = value;
    }

    return params;
  }

  function paramsToHash(params) {
    history.pushState({}, null, `${route.path}#${(new URLSearchParams(params).toString())}`);
  }

  return {paramsToHash, paramsFromHash}
}

export default useParamsInHash
