<template>
    <div :class="componentData.active ? 'show active' : ''" class="tab-pane fade" :id="componentData.id" role="tabpanel" :aria-labelledby="componentData.label">
        <component :componentId="pageComponent.componentId" :props="pageComponent.props" :components="pageComponent.components" :componentData="pageComponent" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></component>
    </div>
</template>
<script>

    function initialState (){
        return {
            pageComponents: '',
        }
    }

    export default {
        name: 'Tab',
        props : [
            'components',
            'componentData'
        ],
        data: function () {
            return initialState();
        },
        watch: {
            $route (to, from){
                this.resetWindow();
            },
            components (to, from){
                this.pageComponents = to;
            }
        },
        methods: {
            getComponents() {
                this.pageComponents = this.components;
            },
            resetWindow: function (){
                Object.assign(this.$data, initialState());
            },
        },
        created(){
            this.getComponents();
        }
    };
</script>
<style lang="scss">
</style>