import {useRoute} from "vue-router";

const getPathClass = () => {
  const route = useRoute();
  const {path} = route;

  return path.replaceAll('/', ' ');
};

const addHtmlClass = (className) => {
  let el = document.getElementsByTagName("html")[0];
  el.classList.add(className);
};

const removeHtmlClass = (className) => {
  let el = document.getElementsByTagName("html")[0];
  el.classList.remove(className)
};

const isCollapsible = (enable, is_collapsed) => {
  let classes = '';
  if (enable) {
    classes = 'collapse'
  }
  if (!is_collapsed) {
    classes += ' show'
  }
  return classes;
}


const classFunctions = {
  methods: {
    getPathClass,
    addHtmlClass,
    removeHtmlClass,
    isCollapsible,
  }
}

export {classFunctions, getPathClass, addHtmlClass, removeHtmlClass, isCollapsible}

