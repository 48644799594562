import App from '../../App.vue'

import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:catchAll(.*)',
      name: 'All Requests',
      component: App
    }
  ]
})
export default router;
