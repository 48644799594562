<template>
  <div class="form-field-container checkbox-field-container" :class="classes">
    <div class="form-check">
      <input
        :id="id"
        :disabled="disabled"
        :name="fieldName"
        type="checkbox"
        class="form-check-input"
        :class="{'form-input--error': !valid && isSubmitted}"
        @change="saveValue()"
        v-model="fieldValue"
      />
      <label v-if="label" :for="id" class="form-check-label">{{ label }}</label>
      <span v-if="!valid && isSubmitted" class="error text-error">{{ validationError }}</span>
    </div>
    <div v-if="help" class="form-text">{{ help }}</div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import classesProp from '@/_core/components/_properties/classes';
import useFormStore from "@/_core/store/useFormStore";
import formFields from "../_properties/formFields";
import isValid from "@/_core/services/isValid";

let valid = ref(true);
let validationError = ref('');

const props = defineProps({
  ...classesProp,
  ...formFields,
  inputType: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'input'
  },
  switchOff: {
    type: String,
    default: '',
  },
  switchOn: {
    type: String,
    default: '',
  },
  checked: {
    type: Boolean,
    default: false
  },
});

const fieldValue = ref(props.value)

const classes = computed(() => classResolver({
  [props.classes]: true,
  'form-check-wrapper': props.inputType === 'checkbox',
}))

const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue() {

  console.log('CHECKBOX VALUE:');
  console.log(fieldValue.value);

  const {validCheck, validError} = isValid(props.fieldName, props.validations, fieldValue.value);
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, fieldValue.value, validCheck, validationError);
}

onMounted(() => { // Initialise field in formStore - important
  saveValue();
})

</script>


