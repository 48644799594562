export default function (cell, {lines = 5}) {
  let value = (cell.getValue() || '');

  if (!value) {
    return '';
  }

  const linesArray = value.split('\n');

  return `${linesArray.slice(0, lines).join('<br>')}${linesArray.length > lines ? '<br>...' : ''}`;
}
