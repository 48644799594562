// import Vue from 'vue';
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import 'es6-promise/auto'
import router from '@/_core/router/index.js'
import moment from 'moment'
import {createApp} from "vue";
import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import "bootstrap/dist/js/bootstrap.bundle.js";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import App from "./App.vue";
// import VueFormulate from '@braid/vue-formulate';
// import FormulateVueSelectPlugin from '@cone2875/vue-formulate-select';
// import FormulateVueDatetimePlugin from '@cone2875/vue-formulate-datetime';
// Vue.use(VueFormulate, {
//   plugins: [
//     FormulateVueSelectPlugin,
//     FormulateVueDatetimePlugin,
//   ]
// });
import {defaultConfig as formkitConfig, plugin as formkit} from "@formkit/vue";

import * as luxon from "luxon";

window.luxon = luxon;

const requireComponent = require.context(
  './_core/components', // The relative path of the components' folder
  true, // Whether to look in sub-folders
  /[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
);

const requireCustomComponent = require.context(
  './components', // The relative path of the components' folder
  true, // Whether to look in sub-folders
  /[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
);

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(router);

app.component('VueDatePicker', VueDatePicker);

app.use(formkit, formkitConfig({
  theme: 'genesis'
}))

importComponents(app, requireComponent); //import the core components
importComponents(app, requireCustomComponent); //allow projects to override base components

app.config.globalProperties.$baseApi = ``;
app.config.globalProperties.$moment = moment
// app.config.globalProperties.$store = store

// app.config.globalProperties.$router = router

function handleFirstTab(e) {
  if (e.keyCode === 9) { // the "I am a keyboard user" key
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

function importComponents(app, requireComponent) {
  requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName); // Get component config

    // Get PascalCase name of component, Gets the file name regardless of folder depth
    const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));

    // Register component globally
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    app.component(
      componentName,
      componentConfig.default || componentConfig
    )
  });
}

window.addEventListener('keydown', handleFirstTab);

app.mount('#app');
