<template>
  <nav class="pagination-wrapper" v-if="showPagination" aria-label="Page navigation example">
    <div class="row m-0">
      <div class="col">
        <p>Showing page {{page}} of {{totalPages}} | Total results: {{totalResults}}</p>
      </div>
      <div class="col">
        <ul class="pagination justify-content-end">
          <li class="page-item" :class='{"disabled": page === 1}'>
            <a class="page-link" href="#" tabindex="-1" v-on:click.prevent.stop="firstPage">First</a>
          </li>
          <li class="page-item" :class='{"disabled": page === 1}'>
            <a class="page-link" href="#" tabindex="-1" v-on:click.prevent.stop="previousPage">Previous</a>
          </li>
          <li class="page-item" v-if="(page - 2) >= 1">
            <a class="page-link pageNo" href="#" v-on:click.prevent.stop="pageNumber((page - 2))">{{page - 2}}</a>
          </li>
          <li class="page-item" v-if="(page - 1) >= 1">
            <a class="page-link pageNo" href="#" v-on:click.prevent.stop="pageNumber((page - 1))">{{page - 1}}</a>
          </li>
          <li class="page-item">
            <span class="page-link pageNo active" href="#">{{page}}</span>
          </li>
          <li class="page-item" v-if="(page + 1) <= totalPages">
            <a class="page-link pageNo" href="#" v-on:click.prevent.stop="pageNumber((page + 1))">{{page + 1}}</a>
          </li>
          <li class="page-item" v-if="(page + 2) <= totalPages">
            <a class="page-link pageNo" href="#" v-on:click.prevent.stop="pageNumber((page + 2))">{{page + 2}}</a>
          </li>
          <li class="page-item" :class='{"disabled": page === totalPages}'>
            <a class="page-link" href="#" v-on:click.prevent.stop="nextPage">Next</a>
          </li>
          <li class="page-item" :class='{"disabled": page === totalPages}'>
            <a class="page-link" href="#" tabindex="-1" v-on:click.prevent.stop="lastPage">Last</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { commonProperties } from '../../mixins/commonProperties'
import { componentFunctions } from "../../mixins/componentFunctions";

export default {
  name: 'Pagination',
  mixins: [
    commonProperties,
    componentFunctions
  ],
  props: [ 'changePageFunc', 'props' ],
  methods: {
    initialState (){
      return {
        page: 1,
        itemsPerPage: 20,
        offset: 0,
        totalResults: 0,
        totalPages: 1,
        showPagination: false,
      }
    },
    changePage: function () {
      // Do your stuff
      this.changePageFunc(this.page, this.offset);
    },
    firstPage(){
      this.page = 1;
      this.offset = 0;
      this.changePage();
    },
    nextPage(){
      this.page++;
      this.offset = (this.offset + this.itemsPerPage);
      this.changePage();
    },
    previousPage(){
      this.page--;
      this.offset = (this.offset - this.itemsPerPage);
      this.changePage();
    },
    lastPage(){
      this.page = this.totalPages;
      this.offset = (this.itemsPerPage * this.totalPages) - this.itemsPerPage;
      this.changePage();
    },
    pageNumber(pageNumber){
      if(this.page !== pageNumber) {
        this.page = pageNumber;
        this.offset = (pageNumber * this.itemsPerPage) - this.itemsPerPage;
        this.changePage();
      }
    },
  },
  created() {
    if(typeof this.props.currentPage !== 'undefined'){
      this.page = this.props.currentPage;
    }
    if(typeof this.props.itemsPerPage !== 'undefined'){
      this.itemsPerPage = this.props.itemsPerPage;
    }
    if(typeof this.props.offset !== 'undefined'){
      this.offset = this.props.offset;
    }
    if(typeof this.props.totalResults !== 'undefined'){
      this.totalResults = this.props.totalResults;
    }
    if(typeof this.props.totalPages !== 'undefined'){
      this.totalPages = this.props.totalPages;
    }
    if(typeof this.props.showPagination !== 'undefined'){
      this.showPagination = this.props.showPagination;
    }
  }
}
</script>
<style lang="scss">
</style>