import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'filters';

const useFilterStore = defineStore(STORE, () => {
  const filterParams = ref([]);

  function setFilterParams(params) {
    filterParams.value = params;
  }

  return {filterParams, setFilterParams};
});

export default useFilterStore;
