<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <ContactDetails
        :addressCountry="addressCountry"
        :addressLine1="addressLine1"
        :addressLine2="addressLine2"
        :addressLine3="addressLine3"
        :addressPostcode="addressPostcode"
        :addressTown="addressTown"
        :businessName="businessName"
        :contactName="contactName"
        :mobile="mobile"
        :telephone="telephone"
    />

    <dl>
      <dt>Notification to</dt>
      <dd>{{ email }}</dd>
    </dl>

    <dl v-if="instructions">
      <dt>Instructions</dt>
      <dd>{{ instructions }}</dd>
    </dl>

    <dl>
      <dt>Collection on Delivery</dt>
      <dd>
        <tick-or-cross :tick="collectionOnDelivery"/>
      </dd>
    </dl>


  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import ContactDetails from "@/components/ContactDetails.vue";
import TickOrCross from "@/components/TickOrCross.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const addressCountry = ref('');
const addressLine1 = ref('');
const addressLine2 = ref('');
const addressLine3 = ref('');
const addressPostcode = ref('');
const addressTown = ref('');
const businessName = ref('');
const contactName = ref('');
const mobile = ref('');
const telephone = ref('');
const email = ref('');
const instructions = ref('');
const collectionOnDelivery = ref(false);

const {hydrate, hydrated} = useHydration(({
                                            address_country: _addressCountry,
                                            address_line_1: _addressLine1,
                                            address_line_2: _addressLine2,
                                            address_line_3: _addressLine3,
                                            address_postcode: _addressPostcode,
                                            address_town: _addressTown,
                                            business_name: _businessName,
                                            contact_name: _contactName,
                                            mobile: _mobile,
                                            telephone: _telephone,
                                            email: _email,
                                            instructions: _instructions,
                                            collection_on_delivery: _collectionOnDelivery,
                                          }) => {
  addressCountry.value = _addressCountry;
  addressLine1.value = _addressLine1;
  addressLine2.value = _addressLine2;
  addressLine3.value = _addressLine3;
  addressPostcode.value = _addressPostcode;
  addressTown.value = _addressTown;
  businessName.value = _businessName;
  contactName.value = _contactName;
  mobile.value = _mobile;
  telephone.value = _telephone;
  email.value = _email;
  instructions.value = _instructions;
  collectionOnDelivery.value = _collectionOnDelivery;
});

</script>
