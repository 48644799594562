const minMaxMoneyFilter = function (cell, onRendered, success, cancel, editorParams) {
  function buildValues(e) {
    const values = {};
    if (start.value) {
      values.start = 100 * (start.value.replace(/,/g, ""));
    }
    if (end.value) {
      values.end = 100 * (end.value.replace(/,/g, ""));
    }

    success(values);
  }

  const container = document.createElement("div");
  container.classList.add("min-max-filter");

  //create and style inputs
  const start = document.createElement("input");
  //start.setAttribute("type", "number");
  start.setAttribute("placeholder", "£min");

  const end = document.createElement("input");
  //end.setAttribute("type", "number");
  end.setAttribute("placeholder", "£max");

  start.addEventListener("change", buildValues);
  end.addEventListener("change", buildValues);

  container.appendChild(start);
  container.appendChild(end);

  return container;
};

export default minMaxMoneyFilter;
