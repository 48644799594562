<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId: visitId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <template v-for="{type, name, url} in links" :key="`${type}${name}`">
        <dt>{{ type }}</dt>
        <dd v-if="url"><a :href="url">{{ name }}</a></dd>
        <dd v-else>{{ name }}</dd>
      </template>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref,} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const emit = defineEmits(['removed']);

const props = defineProps({
  visitId: {
    type: Number,
    required: true,
  },
});

const links = ref([]);

const {hydrated, hydrate} = useHydration(({links: _links}) => {
  links.value = _links;
});

</script>
