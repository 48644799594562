<template>
  <div :class="classes" class="title-tile">
    <span v-html="wrappedTitle"/>
    <div v-if="breadcrumbs.length" class="breadcrumbs">
      <ul>
        <li v-for="(crumb, key) of breadcrumbs" :key="`${crumb.href}--${crumb.title}`">
          <router-link v-if="crumb.href" v-bind:to="crumb.href" v-html="crumb.title"/>
          <a v-else href="#" v-html="crumb.title"/>
          <span v-if="key !== Object.keys(breadcrumbs).length - 1" class="divider">/</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';
import commonProps from '@/_core/components/_properties/common';

const props = defineProps({
  ...commonProps,
  breadcrumbs: {
    type: Object,
    default: () => ({})
  },
  header: {
    type: Number,
    default: 3
  },
  title: {
    type: String,
    required: true,
  }
})

const wrappedTitle = computed(() => `<h${props.header}>${props.title}</h${props.header}>`)
</script>

<style lang="scss">
.title-tile {
  background: $c-white;

  .breadcrumbs {
    overflow: auto;
    font-size: 0.7rem;

    ul {
      margin: 0;
      list-style: none;
      padding-left: 0;

      li {
        float: left;

        .divider {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
