<template>
    <div class="formulate">
        <FormKit v-model:value="model" type="form" @submit="submit">
            <FormKitSchema :data="model" :schema="transformedSchema"/>
        </FormKit>

    </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import commonProps from '@/_core/components/_properties/common'
import useApi from "@/_core/services/api";
import moment from "moment";

const props = defineProps({
  ...commonProps,
  components: {
    type: Array,
    default: [],
  },
  model: {
    type: Object,
    default: () => ({}),
  },
  schema: {
    type: Array,
    required: true,
  },
  submitRoute: {
    type: String,
    default: '',
  }
});

const model = ref(transformModel(props.model));

function transformModel(model) {
  const {..._model} = model;

  Object.entries(model).forEach(([key, value]) => {
    if (value) {
      const _schema = Array.from(props.schema);
      const {$formkit} = _schema.find((item) => item.name === key) || {};
      switch ($formkit) {
        case 'datetime-local':
          _model[key] = moment(value, moment.ISO_8601).format('YYYY-MM-DDTHH:mm')
          break;
      }
    }
  })

  return _model;
}

function transformSchema() {
  const [..._schema] = props.schema;

  return _schema;
}

const transformedSchema = computed(transformSchema);


async function submit(data) {
  const api = useApi();

  const response = await api.post(props.submitRoute, data);

  console.log(response);
}


</script>

<style lang="scss">
.formulate {
  padding: 1em;
}

//@import 'vue-select/src/scss/vue-select';
//@import 'vue-datetime/dist/vue-datetime.css';
</style>
