import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'messages';

const useMessageStore = defineStore(STORE, () => {
  const messages = ref([]);

  function addMessage({type, content, icon, timeout}) {
    messages.value.push({
      type: type,
      message: content,
      icon: icon,
      time: Date.now(),
      timeout: timeout
    })
  }

  function removeMessage(data) {
    messages.value.splice(data, 1);
  }

  function clearMessages() {
    messages.value = [];
  }

  return {addMessage, removeMessage, clearMessages, messages};

}, {
  persist: true
});

export default useMessageStore;
