export default function (tel) {
  let _tel = tel;
  const href = `tel:${_tel}`;

  let countryCode = '';
  if (_tel.match(/^00/)) {
    countryCode = `+${_tel.slice(0, 5)} `;
    _tel = _tel.slice(4);
  }

  const label = [
    countryCode,
    ..._tel.split('').toSpliced(4, 0, ' ').toSpliced(8, 0, ' ')]
    .join('')

  return {
    href,
    label,
  }
}

