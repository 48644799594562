<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <dt>Parcels</dt>
      <dd>{{ parcels }}</dd>

      <dt>Total Weight</dt>
      <dd>{{ weight }}kg</dd>

      <dt>Total Value</dt>
      <dd>{{ value }}</dd>

      <dt>Description</dt>
      <dd>{{ description }}</dd>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const parcels = ref(0);
const weight = ref(0);
const value = ref('');
const description = ref('');

const {hydrate, hydrated} = useHydration(({
                                            parcels: _parcels,
                                            total_weight: _weight,
                                            total_value: _value,
                                            description: _description,
                                          }) => {
  parcels.value = _parcels;
  weight.value = _weight;
  value.value = _value;
  description.value = _description;
});

</script>
