<template>
  <div :class="getClasses" v-html="content"></div>
</template>
<script>
import { commonProperties } from '../../mixins/commonProperties'

export default {
  name: 'ContentHtml',
  mixins: [commonProperties],
  methods: {
    initialState (){
      return {
        content: ""
      }
    }
  },
  created() {
    this.content = this.props.content;
  }
}
</script>