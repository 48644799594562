<template>
    <div id="pageContent" :class="classResolver(pageContentClasses)">
        <div id="pageContentInner" :class="classResolver(classes)">
            <DynamicComponents :components="components"/>
        </div>
    </div>
</template>


<script setup>
import eventBus from "@/_core/services/eventBus";
import {defineProps, onMounted, ref} from "vue";
import commonProps from '@/_core/components/_properties/common'
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import classResolver from "@/_core/services/classResolver";


const pageComponents = ref('');
const componentKey = ref(1);


const props = defineProps({
  ...commonProps,
  pageContentClasses: {
    type: String,
    default: '',
  },
  components: {
    type: Array,
    default: () => ([]),
  }

});

onMounted(() => {
  eventBus.$on('refreshGlobal', () => {
    componentKey.value++;
  });
});
</script>
