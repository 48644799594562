<template>
  <div class="editor">
    <component :is="'AutoKeyDevAreas'" v-if="field.name === 'key_dev_areas'" :key="'AutoKeyDevAreas'"
               v-bind:props="field"></component>
    <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
      <div class="editor__menubar">

        <button class="menubar__button" @click="commands.bold">
          <bold-icon></bold-icon>
        </button>
        <button class="menubar__button" @click="commands.italic">
          <italic-icon></italic-icon>
        </button>
        <button class="menubar__button" @click="commands.strike">
          <strike-icon></strike-icon>
        </button>
        <button class="menubar__button" @click="commands.underline">
          <underline-icon></underline-icon>
        </button>
        <button class="menubar__button" @click="commands.code">
          <code-icon></code-icon>
        </button>
        <button class="menubar__button" @click="commands.paragraph">
          <paragraph-icon></paragraph-icon>
        </button>
        <button class="menubar__button" @click="commands.heading({ level: 1 })">H1</button>
        <button class="menubar__button" @click="commands.heading({ level: 2 })">H2</button>
        <button class="menubar__button" @click="commands.heading({ level: 3 })">H3</button>
        <button class="menubar__button" @click="commands.bullet_list">
          <ul-icon></ul-icon>
        </button>
        <button class="menubar__button" @click="commands.ordered_list">
          <ol-icon></ol-icon>
        </button>
        <button class="menubar__button" @click="commands.blockquote">
          <quote-icon></quote-icon>
        </button>
        <button class="menubar__button" @click="commands.code_block">
          <code-icon></code-icon>
        </button>
        <button class="menubar__button" @click="commands.horizontal_rule">
          <hr-icon></hr-icon>
        </button>
        <button class="menubar__button" @click="commands.undo">
          <undo-icon></undo-icon>
        </button>
        <button class="menubar__button" @click="commands.redo">
          <redo-icon></redo-icon>
        </button>

      </div>
    </editor-menu-bar>

    <editor-content :doc="field.value" :editor="editor" class="editor__content"/>
  </div>
</template>

<script>
const hash = require('object-hash');

import {Editor, EditorContent} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
  name: 'TipTapEditor',
  components: {
    EditorContent,
  },
  props: [
    'props'
  ],
  data() {
    console.dir(this.props);
    return {
      editor: new Editor({
        extensions: [
          StarterKit,
        ],
      }),
      field: {
        value: '',
        placeholder: '',
      }
    }
  },
  computed: {
    editorContent() {
      return this.editor.getHTML();
    },
    globalContent() {
      return this.field.value;
    },
    hash: () => hash,
  },
  watch: {
    editorContent(to, from) {
      this.field.value = to;
    },
    globalContent(to, from) {
      if (typeof to !== 'undefined') {
        if (hash(to) !== hash(this.editorContent)) {
          this.editor.setContent(this.field.value);
        }
      }
    }
  },
  created() {
    this.$set(this, 'field', this.props);
    this.editor.setContent(this.field.value);
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss">
.editor {
  position: relative;
  margin: 0 auto 5rem auto;

  .menubar {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: .25em;

    &__button {
      min-width: 30px;
    }
  }

  &__content {

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
      caret-color: currentColor;
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $c-black;
      color: $c-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      padding: 0.2rem 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($c-black, 0.1);
      color: rgba($c-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($c-black, 0.1);
      color: rgba($c-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td, th {
        min-width: 1em;
        border: 2px solid $c-grey;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }

  }

  .ProseMirror {
    &:focus {
      outline: none;
    }

    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: 0;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;

    p.is-editor-empty:first-child::before {
      content: attr(data-empty-text);
      float: left;
      color: #adb5bd;
      pointer-events: none;
      height: 0;
    }
  }
}
</style>
