import image from 'tabulator-tables/src/js/modules/Format/defaults/formatters/image.js'

export default function (cell, formatterParams, onRendered) {
  const img = image(cell, formatterParams, onRendered);

  img.addEventListener('error', () => img.remove());

  return img;

}
