import link from "tabulator-tables/src/js/modules/Format/defaults/formatters/link";

export default function (cell, formatterParams, onRendered) {

  const el = link.call(this, cell, formatterParams, onRendered);

  if (el === '&nbsp;') {
    return el;
  }

  el.innerHTML = el.innerHTML.replace(/^(https?:\/\/)?(www\.)?/, '');
  el.innerHTML = el.innerHTML.replace(/\/$/, '');

  return el;
}
