import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'tokens';

const useTokenStore = defineStore(STORE, () => {
  const accessToken = ref('');
  const refreshToken = ref('');

  function setAccessToken(_accessToken) {
    accessToken.value = _accessToken;
  }

  function setRefreshToken(_refreshToken) {
    refreshToken.value = _refreshToken;
  }

  function clearAccessToken() {
    accessToken.value = '';
  }

  function clearRefreshToken() {
    refreshToken.value = '';
  }

  function clearAllTokens() {
    clearAccessToken();
    clearRefreshToken()
  }

  return {
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    clearAccessToken,
    clearRefreshToken,
    clearAllTokens
  };

}, {
  persist: true
});

export default useTokenStore;
