<template>
  <div :class="classes" class="list-tile">
    <div v-if="title" class="title" v-html="wrappedTitle"/>
    <ul class="list-content">
      <li v-for="item in content">{{ item }}</li>
    </ul>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';
import commonProps from '@/_core/components/_properties/common'

const props = defineProps({
  ...commonProps,
  header: {
    type: Number,
    default: 3,
  },
  title: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  }
})

const wrappedTitle = computed(() => `<h${props.header}>${props.title}</h${props.header}>`);

</script>
