<template>
  <div v-if="!closed" class="buildNumber">
    <button class="buildNumber__close btn" type="button" @click="close">
      <span class="fa fa-stack">
        <i class="fa fa-solid fa-circle"></i>
        <i class="fa fa-solid fa-times fa-stack-1x"></i>
      </span>
    </button>
    <label class="buildNumber__label">Build:</label>
    <span class="buildNumber__content">{{ buildNumber }}</span>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import useApi from "@/_core/services/api";

const props = defineProps({
  buildNumber: {
    type: String,
    required: true
  }
});

const closed = ref(false);

function close() {
  closed.value = true;
}

const api = useApi();

onMounted(async () => {
  const {data: {build}} = await api.get('/debug/build');

  if (build !== props.buildNumber) {
    throw new Error(`IO / Icarus build number mismatch (IO: ${build} / Icarus: ${props.buildNumber})`)
  }
});

</script>
<style lang="scss" scoped>
.buildNumber {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: transparentize($brand--c1, 0.2);
  border-top-left-radius: 6px;
  color: #fff;
  min-width: 100px;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.2);
  padding: 6px;

  &__close {
    color: #f00;
    position: absolute;
    left: -14px;
    top: -13px;
    padding: 0;

    .fa-stack {
      font-size: 0.6em;
    }

    .fa-circle {
      font-size: 1.4em;

    }

    .fa-times {
      color: #fff;
      line-height: 1.6em;
    }

    &:hover {
      color: #f00;

      .fa-circle {
        color: darken(#f00, 10%);
      }
    }
  }

  &__label {
    font-weight: bold;
    margin-right: 3px;
  }
}
</style>
