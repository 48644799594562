<template>
  <aside :class="{'itemSelector--in': isIn}" class="itemSelector">
    <div class="itemSelector__handle" @click="toggleIn">
      <div class="itemSelector__title">{{ title }}</div>
    </div>
    <ul class="itemSelector__items">
      <li v-for="{itemId, title} in available" :key="itemId" @click="toggleItem(itemId)">
        <i v-if="active.includes(itemId)" class="fa fa-check-square"></i>
        <i v-else class="fa fa-square-o"></i>
        <label :for="itemId.toLowerCase()">{{ title }}</label>
      </li>
    </ul>
  </aside>
</template>

<script setup>
import {ref, toRef} from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  available: {
    type: Array,
    default: [],
  },
  active: {
    type: Array,
    default: [],
  },
});

const isIn = ref(false)
const active = toRef(props, 'active');
const emit = defineEmits(['update:active']);

function toggleItem(itemId) {
  const [..._active] = active.value;
  _active.findIndex(_itemId => _itemId === itemId) === -1
      ? _active.push(itemId)
      : _active.splice(_active.findIndex(_itemId => _itemId === itemId), 1);

  emit('update:active', _active)
}

function toggleIn() {
  isIn.value = !isIn.value
}
</script>

<style lang="scss">
.itemSelector {
  padding: 12px;
  position: fixed;
  right: 0;
  top: 200px;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 200ms ease-in-out;
  min-height: 200px;
  min-width: 200px;

  &__items {
    list-style: none;
    padding: 0;
    pointer-events: none;
  }

  &--in {
    transform: none;

    .itemSelector__items {
      pointer-events: all;
    }
  }

  &__handle {
    font-weight: bold;
    position: absolute;
    padding: 12px 3px;
    top: 0;
    bottom: 0;
    color: white;
    cursor: pointer;
    background-color: blue;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 26px;
    right: 100%;
  }

  &__title {
    transform: rotate(90deg);
  }

  .fa {
    color: blue;
    margin-right: 6px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}
</style>
