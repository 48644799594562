<template>
  <fieldset :class="getClasses">
    <legend class="row" v-html="legend"></legend>
    <component v-bind:props="pageComponent.props" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></component>
  </fieldset>
</template>
<script>

import { commonProperties } from '../../mixins/commonProperties'
import { componentFunctions } from '../../mixins/componentFunctions'

export default {
  name: 'Fieldset',
  mixins: [commonProperties,componentFunctions],
  watch: {
    props (to, from){
      this.legend = to.legend;
    },
  },
  methods: {
    initialState (){
      return {
        legend: '',
        pageComponents: ''
      }
    }
  },
  created() {
    this.legend = this.props.legend;
  }
}
</script>
<style lang="scss"></style>