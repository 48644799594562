<template>
  <div :class="{
    'beatingHeart--animate': animate
  }" :style="{
    '--bh-color': colour,
    '--bh-rate': rateSecs,
  }" class="beatingHeart">

    <Heart class="beatingHeart__heart"/>
    <div class="beatingHeart__content">
      <div v-for="item in content">{{ item }}</div>
    </div>
  </div>
</template>

<script setup>

import {computed, defineProps} from 'vue';
import Heart from "@/components/Heart.vue";

const props = defineProps({
  content: {
    type: Array,
    required: false,
    default: []
  },
  colour: {
    type: String,
    required: true
  },
  rate: {
    type: Number,
    default: 0
  },
  animate: {
    type: Boolean,
    default: true
  }
});

const rateSecs = computed(() => {
  const rateSecs = 0.6 + (100 - props.rate / 100) * .012;

  return `${rateSecs.toFixed(2)}s`;
});

</script>
