import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'forms';

const useFormStore = defineStore(STORE, () => {
  const fields = ref([]);
  const submittedForms = ref([]);

  function setField(formId, fieldName, value, isValid, validationError) {
    for (let i = 0; i < fields.value.length; i++) {
      if (fields.value[i].formId == formId && fields.value[i].fieldName == fieldName) {
        removeField(i);
      }
    }
    addField(formId, fieldName, value, isValid, validationError);
  }

  function removeField(index) {
    fields.value.splice(index, 1);
  }

  function addField(formId, fieldName, value, isValid, validationError) {
    fields.value.push({
      formId: formId,
      fieldName: fieldName,
      value: value,
      isValid: isValid,
      error: validationError
    })
  }

  function submitForm(formId) {
    for (let i = 0; i < submittedForms.value.length; i++) {
      if (submittedForms.value[i].formId == formId) {
        return
      }
    }
    submittedForms.value.push({
      formId: formId
    })
  }

  function isFormSubmitted(formId) {
    for (let i = 0; i < submittedForms.value.length; i++) {
      if (submittedForms.value[i].formId == formId) {
        return true;
      }
    }

    return false;
  }

  return {setField, fields, submitForm, isFormSubmitted, submittedForms};

});

export default useFormStore;
