import TabulatorFormatter from '../enums/TabulatorFormatter';
import TabulatorMutator from '../enums/TabulatorMutator';
import concatenate from "@/formatters/concatenate";
import array from "@/formatters/array";
import beatingHeart from "@/formatters/beatingHeart";
import fontAwesomeIcon from "@/formatters/fontAwesomeIcon";
import telephone from "@/formatters/telephone";
import email from "@/formatters/email";
import image from "@/formatters/image";
import imageMultiple from "@/formatters/imageMultiple";
import xero from "@/formatters/xero";
import condition from "@/formatters/condition";
import dateDue from "@/formatters/dateDue";
import trim from "@/mutators/trim";
import number from "@/formatters/number";
import shortNumber from "@/formatters/shortNumber";
import poStatus from "@/formatters/poStatus";
import salesOrderStatus from "@/formatters/salesOrderStatus";
import cleanedLink from "@/formatters/cleanedLink";
import financeEstimate from "@/formatters/financeEstimate";
import financeEstimateStatus from "@/formatters/financeEstimateStatus";
import createdFilter from "@/tabulator/filters/created";
import minMaxFilter from "@/tabulator/filters/min-max";
import minMaxDateFilter from "@/tabulator/filters/min-max-date";
import moneyFilter from "@/tabulator/filters/money";
import minMaxMoneyFilter from "@/tabulator/filters/min-max-money";
import money from "@/mutators/money";
import checkStatus from "@/formatters/checkStatus";
import textBlock from "@/formatters/textBlock";
import useApi from "../_core/services/api";
import useDropdownStore from "../store/useDropdownStore";

class TabulatorColumnMapper {
  constructor(column) {
    this.column = column;
  }

  static map(column) {
    const mapper = new TabulatorColumnMapper(column);

    return mapper._map();
  }

  _map() {
    const {..._column} = this.column;

    if (_column.hasOwnProperty('formatter')) {
      _column.formatter = this.mapFormatter(_column.formatter);
    }

    if (_column.hasOwnProperty('headerFilter')) {
      const {
        headerFilterLiveFilter,
        headerFilter,
        headerFilterFunc,
        headerFilterParams,
      } = this.mapHeaderFilter(_column.headerFilter, _column.headerFilterFunc, _column.headerFilterLiveFilter, _column.headerFilterParams);

      _column.headerFilterLiveFilter = headerFilterLiveFilter;
      _column.headerFilter = headerFilter;
      _column.headerFilterFunc = headerFilterFunc;
      _column.headerFilterParams = headerFilterParams;
    }

    if (_column.hasOwnProperty('tooltip')) {
      _column.tooltip = this.mapTooltip(_column.tooltip);
    }

    if (_column.hasOwnProperty('mutator') && !!_column.mutator) {
      _column.mutator = this.mapMutator(_column.mutator);
    }

    if (_column.hasOwnProperty('hiddenTitle') && _column.hiddenTitle) {
      _column.title = '';
    }

    return _column;
  }

  mapFormatter(formatter) {
    if (!formatter) {
      return formatter;
    }

    return {
      [TabulatorFormatter.ARRAY.enumKey]: array,
      [TabulatorFormatter.BEATING_HEART.enumKey]: beatingHeart,
      [TabulatorFormatter.CHECK_STATUS.enumKey]: checkStatus,
      [TabulatorFormatter.CONCATENATE.enumKey]: concatenate,
      [TabulatorFormatter.CONDITION.enumKey]: condition,
      [TabulatorFormatter.DATE_DUE.enumKey]: dateDue,
      [TabulatorFormatter.EMAIL.enumKey]: email,
      [TabulatorFormatter.FINANCE_ESTIMATE.enumKey]: financeEstimate,
      [TabulatorFormatter.FINANCE_ESTIMATE_STATUS.enumKey]: financeEstimateStatus,
      [TabulatorFormatter.FONT_AWESOME_ICON.enumKey]: fontAwesomeIcon,
      [TabulatorFormatter.IMAGE.enumKey]: image,
      [TabulatorFormatter.IMAGE_MULTIPLE.enumKey]: imageMultiple,
      [TabulatorFormatter.LINK.enumKey]: cleanedLink,
      [TabulatorFormatter.NUMBER.enumKey]: number,
      [TabulatorFormatter.PO_STATUS.enumKey]: poStatus,
      [TabulatorFormatter.SALES_ORDER_STATUS.enumKey]: salesOrderStatus,
      [TabulatorFormatter.SHORT_NUMBER.enumKey]: shortNumber,
      [TabulatorFormatter.TELEPHONE.enumKey]: telephone,
      [TabulatorFormatter.TEXT_BLOCK.enumKey]: textBlock,
      [TabulatorFormatter.XERO.enumKey]: xero,
    }[formatter.toUpperCase()] || formatter;
  }

  mapMutator(mutator) {
    return {
      [TabulatorMutator.TRIM.enumKey]: trim,
      [TabulatorMutator.MONEY.enumKey]: money,
    }[mutator.toUpperCase()] || mutator;
  }

  mapTooltip(tooltip) {
    let field;
    if (tooltip) {
      switch (typeof tooltip) {
        case 'string':
          return tooltip.replace(/\n/g, '<br />');

        case  'object':
          ({field} = tooltip);

        // noinspection FallThroughInSwitchStatementJS
        case 'boolean':
          return (e, cell) => {
            field = field || cell.getField();
            return (cell.getRow().getData()[field] || '').replace(/\n/g, '<br />') || false;
          }

        default:
          return tooltip;
      }

    }
    return tooltip;
  }

  mapHeaderFilter(
    headerFilter,
    headerFilterFunc,
    headerFilterLiveFilter,
    headerFilterParams = {}
  ) {
    switch (headerFilter) {
      case 'min_max': {
        return {
          headerFilter: minMaxFilter,
          headerFilterLiveFilter: false,
          headerFilterFunc: 'between',
          headerFilterParams
        };
      }
      case 'min_max_date': {
        return {
          headerFilter: minMaxDateFilter,
          headerFilterLiveFilter: false,
          headerFilterFunc: 'between',
          headerFilterParams
        };
      }
      case 'min_max_money': {
        return {
          headerFilter: minMaxMoneyFilter,
          headerFilterLiveFilter: false,
          headerFilterFunc: 'between',
          headerFilterParams
        };
      }
      case 'money': {
        return {headerFilter: moneyFilter, headerFilterLiveFilter: false, headerFilterFunc: '=', headerFilterParams};
      }
      case 'created': {
        return {
          headerFilter: createdFilter,
          headerFilterLiveFilter: false,
          headerFilterFunc: 'between',
          headerFilterParams
        };
      }
      case 'list': {
        if (headerFilterParams.valuesURL) {
          const valuesURL = headerFilterParams.valuesURL;
          delete headerFilterParams.valuesURL;

          const api = useApi();
          const {getDropdown, hasDropdown, setDropdown} = useDropdownStore();

          headerFilterParams.valuesLookup = async () => {
            if (!hasDropdown(valuesURL)) {
              const {data} = await api.get(valuesURL);
              setDropdown(valuesURL, data);
            }

            return getDropdown(valuesURL);
          }
        }

        return {headerFilter: 'list', headerFilterLiveFilter: false, headerFilterFunc: '=', headerFilterParams};
      }

      default:
        return {headerFilter, headerFilterLiveFilter, headerFilterFunc, headerFilterParams};
    }
  }

  mapHeaderFilterFunc(headerFilter, current) {
    switch (headerFilter) {
      case 'min_max': {
        return false;
      }

      default:
        return current;
    }
  }
}

export default TabulatorColumnMapper;
