<template>
    <div class="col" :class="classes">
        <component v-bind:props="pageComponent.props" :componentId="pageComponent.componentId" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></component>
    </div>
</template>
<script>

    function initialState (){
        return {
            pageComponents: '',
            classes: '',
        }
    }

    export default {
        name: 'ColWrapper',
        props : [
            'props',
            'components',
            'componentData'
        ],
        data: function () {
            return initialState();
        },
        watch: {
            $route (to, from){
                this.resetWindow();
            },
            components (to, from){
                this.pageComponents = to;
            }
        },
        methods: {
            getComponents() {
                this.pageComponents = this.components;
            },
            resetWindow: function (){
                Object.assign(this.$data, initialState());
            },
        },
        created(){
            this.getComponents();
          if(this.componentData){
            this.classes = this.componentData.classes;
          }
        }
    };
</script>
<style lang="scss">
</style>