<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      class="companyTypePanel"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <template v-for="{tick, label} in types">
        <dt>{{ label }}</dt>
        <dd>
          <TickOrCross :tick="tick"/>
        </dd>
      </template>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import useHydration from "@/hooks/useHydration";
import TickOrCross from "@/components/TickOrCross.vue";
import DashboardPanel from "@/components/DashboardPanel.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const types = ref([]);

const {hydrate, hydrated} = useHydration(({types: _types}) => {
  types.value = _types;
});

</script>
