<template>
<div class="icarus-filtered-child">
    <component v-bind:props="pageComponent.props" :componentId="pageComponent.componentId" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="(index+componentKey)"></component>
</div>
</template>
<script>
  import { commonProperties } from '../../mixins/commonProperties'
  import eventBus from "../../services/eventBus";
  import Api from "@/_core/services/api";
  import Global from "../../services/global";

    export default {
      name: 'FilteredChild',
      props : [
        'props',
        'components',
        'componentData',
      ],
      watch: {
        components (to, from){
          this.pageComponents = to;
        }
      },
      mixins: [commonProperties],
      methods: {
        initialState (){
            return {
              pageComponents: '',
              classes: '',
              contentSrc: '',
              componentKey: 1,
            }
        },
        async getSrcData(src) {
          Api.get(src)
              .then(response => {
                this.pageComponents = response.data.components;
                this.componentKey = this.componentKey + 1;
              })
              .catch(error => {
                    console.log(error);
                  }
              );
        },
        getComponents() {
          this.pageComponents = this.components;
        }
      },
      created() {

        if (typeof this.componentData.props.contentSrc !== 'undefined') {
          this.contentSrc = this.componentData.props.contentSrc;
        }

        eventBus.$on("wrapperFilters", (filterData) => {
          console.log('TRIGGERED');
          console.log(this.contentSrc);

          let src = this.contentSrc;

          let replacements = src.match(/[^{}]*.(?=})/g); // find/replace parameters in the url e.g. {MSMID} etc.
          if (replacements && replacements.length) {
            replacements.forEach((x, i) => {src = src.replace("{" + x + "}", filterData[x]); console.log('X '+x + ' i '+i)});

            src = src.replace("[", "");
            src = src.replace("]", "");
          }

          Global.checkAuthenticated(this.$router.currentRoute.path).then((response) => {
            this.getSrcData(src);
          });

          // this.getData();
        });
      },
    }
</script>
