export default function (cell, formatterParams, onRendered) {
  const value = cell.getValue();

  let icon = '<i class="fa fa-fw fa-check-circle text-success"></i>'
  let text = 'None';

  if (value === 'N') {
    icon = '<i class="fa fa-fw fa-times-circle text-danger"></i>'
  } else if (value === 'S') {
    text = 'Sale';
  } else if (value === 'H') {
    text = 'Hire';
  }

  const el = document.createElement('div');
  el.classList.add('d-flex', 'flex-column', 'align-items-center');

  el.innerHTML = `<div>${icon}</div><div>${text}</div>`;

  return el;
}
