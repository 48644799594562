import {ref} from "vue";

function useHydration(setter) {
  const hydrated = ref(false);

  function hydrate(data) {
    setter(data)

    hydrated.value = true;
  }

  return {
    hydrate,
    hydrated,
  }
}

export default useHydration;
