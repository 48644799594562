import useApi from "@/_core/services/api";

function mapParams(params) {
  const {..._params} = params;
  _params.sort = JSON.stringify(_params.sort || []);
  _params.filter = JSON.stringify(_params.filter || []);

  return _params;
}

function ajaxRequestViaApiService(url, config, params) {
  const api = useApi();

  return new Promise(async (resolve, reject) => {
    try {
      const urlParams = new URLSearchParams(mapParams(params));
      const {data} = await api({
        url: `${url}?${urlParams.toString()}`,
        method: config.method
      })
      
      resolve(data);

    } catch (e) {
      reject(e);
    }
  });
}


export default ajaxRequestViaApiService;
