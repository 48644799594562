export default function (cell, formatterParams, onRendered) {
  const value = cell.getValue();
  const {financeEstimatesAccepted} = formatterParams;
  const el = document.createElement('div');
  el.classList.add('d-flex', 'flex-column', 'align-items-center');

  let icon = 'times';
  let color = 'danger';

  if (financeEstimatesAccepted && value) {
    icon = 'check';
    color = 'success';
  } else if (financeEstimatesAccepted && !value) {
    icon = 'times';
    color = 'warning';
  }

  let iconEl = `<i class="fas fa-${icon}-circle text-${color}"></i>`;


  el.innerHTML = `<div>${iconEl}</div>`;
  return el;
}
