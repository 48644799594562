<template>
    <div :class="classResolver(classes)" class="messages">
        <toast-messages
                v-for="({
                content,
                icon,
                type,
                key,
                time,
                timeout,
                }) in messages"
                :key="key"
                :content="content"
                :icon="icon"
                :storeKey="key"
                :time="time"
                :timeout="timeout"
                :type="type"
        />
    </div>
</template>

<script setup>
import useMessageStore from "@/_core/store/useMessageStore";
import classResolver from "@/_core/services/classResolver";
import classesProp from "@/_core/components/_properties/classes";

const props = defineProps({...classesProp})

const {messages} = useMessageStore();
</script>
