<template>
<div>
  <component v-if="data !== null && data.length && paginationLocation == 'outside' && showPaginationTop" :props="pageInfo" :changePageFunc="changePage" :is="'Pagination'"
             :key="'pager-one-'+page"></component>

  <div class="card icarus-card-tile" :class="classes">
    <div class="card-header">
      <h3 v-html="title" class="card-title"></h3>
      <div class="d-flex" v-if="typeof pageComponents !== 'undefined' || supComponents.length > 0">
        <component :componentId="supportComponent.componentId" :class="supportComponent.classes"
                   :componentData="supportComponent" :props="supportComponent.props"
                   :components="supportComponent.components" :fixedWidth="supportComponent.fixedWidth"
                   :is="supportComponent.name" :key="index" v-bind:filters="filters"
                   v-for="(supportComponent, index) in supComponents"></component>
        <a class="btn card-refresh-button" @click="checkGetData()" v-html="refreshButton" v-if="refreshButton !== null"></a>
      </div>
    </div>
    <div class="card-body position-relative">
      <div class="request-messages">
        <LoadingSpinner v-if="gettingData"></LoadingSpinner>
        <LoadingError v-if="gettingDataError"></LoadingError>
      </div>

      <component v-if="data !== null && data.length && paginationLocation == 'inside' && showPaginationTop" :props="pageInfo" :changePageFunc="changePage" :is="'Pagination'"
                 :key="'pager-one-'+page"></component>

      <table class="table table-hover icarus-card-table">
        <thead>
          <tr>
            <th v-if="!hideIdColumn || (hideIdColumn && key !== 0)" v-for="(value, key, index) in tableHeaders" v-html="value"></th>
            <th v-if="typeof pageComponents !== 'undefined' || supComponents.length > 0"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in data">
            <td v-for="(value, field, index_col) in row" v-if="!hideIdColumn || (hideIdColumn && index_col !== 0)" @click="viewRow(row.id)" v-html="value"></td>
            <td v-if="typeof pageComponents !== 'undefined'">
              <component
                :componentId="pageComponent.componentId"
                :class="pageComponent.classes"
                :componentData="pageComponent"
                :components="pageComponent.components"
                :rowData="row"
                :is="pageComponent.name"
                :key="index"
                v-bind:props="pageComponent.props"
                v-for="(pageComponent, index) in pageComponents"></component>
            </td>
          </tr>
          <tr v-if="data !== null && data.length == 0">
            <td>No results</td>
          </tr>
        </tbody>
      </table>

      <component v-if="data !== null && data.length && paginationLocation == 'inside' && showPaginationBottom" :props="pageInfo" :changePageFunc="changePage" :is="'Pagination'"
                 :key="'pager-two-'+page"></component>

    </div>
  </div>

  <component v-if="data !== null && data.length && paginationLocation == 'outside' && showPaginationBottom" :props="pageInfo" :changePageFunc="changePage" :is="'Pagination'"
             :key="'pager-two-'+page"></component>
</div>
</template>
<script>
import useApi from "@/_core/services/api";
import {commonProperties} from '../../mixins/commonProperties'
import {componentFunctions} from "../../mixins/componentFunctions";
import axios from 'axios';
import eventBus from "@/_core/services/eventBus";
import Global from "@/_core/services/global";
import useFilterStore from "@/_core/store/useFilterStore";
import useParamsInHash from "@/_core/services/paramsInHash";
import {useRouter} from "vue-router";

export default {
  name: 'CardDataTable',
  props : [
    'props',
    'components',
    'componentData',
    "dataSrc",
    "showPagination",
    "supportComponentLocation",
    "displayShortTotals",
    "hideIdColumn",
    'showCols',
    'hideCols',
  ],
  abortController: null,
  mixins: [
    commonProperties,
    componentFunctions
  ],
  hideHeaderIx: [],
  showHeaderIx: [],
  watch: {
    dataSource(to, from) {
      this.dataSource = to;
      this.getData();
    },
    componentData(to, from) {
      this.basePath = to.basePath;
    },
    storedFilters(to, from) {
      this.filters = to;
      this.page=1;
      this.offset=0;
      this.getData();
      eventBus.$emit("filtersChanged", true);
      eventBus.$emit("closeModal", true);
    },
    compWidth(to, from) {
      this.flipTileClass(to);
    },
  },
  computed: {
    storedFilters() {
      return useFilterStore().filterParams
    },

    pageInfo() {
      return {
        currentPage: this.page,
        itemsPerPage: this.itemsPerPage,
        offset: this.offset,
        totalResults: this.totalResults,
        totalPages: this.totalPages,
        showPagination: this.showPagination,
      }
    },
  },
  methods: {
    initialState() {
      return {
        dataSource: '',
        data: null,
        idField: 'id',
        tableHeaders: [],
        page: 1,
        itemsPerPage: 20,
        offset: 0,
        totalResults: 0,
        totalPages: 1,
        basePath: '',
        pageComponents: '',
        supComponents: '',
        showPagination: false,
        showPaginationTop: false,
        showPaginationBottom: false,
        paginationLocation: 'outside',
        displayShortTotals: false,
        hideIdColumn: false,
        colWidths: {},
        totalColWidth: 0,
        filters: {},
        filterForm: null,
        rowClickable: true,
        gettingData: true,
        gettingDataError: false,
        tileBreak: 767.98,
        compWidth: 0,
        tileClass: '',
        refreshButton: null,
      }
    },
    openBlob(blob){
      if (window.navigator.msSaveOrOpenBlob) { //IE 11+
        window.navigator.msSaveOrOpenBlob(blob, "my.pdf");
      } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
        alert("Cannot display on FF iOS");
      } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
        var reader = new FileReader();
        reader.onloadend = function () { window.open(reader.result);};
        reader.readAsDataURL(blob);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    },
    resetWindow: function () {
      Object.assign(this.$data, this.initialState());
      useFilterStore().setFilterParams({})
    },
    forceRefreshComponent() {
      this.$forceUpdate();
    },

    filtersFromHash() {
      useFilterStore().setFilterParams(useParamsInHash());
    },

    flipTileClass(width) {
      this.tileClass = width <= this.tileBreak ? 'tiled' : '';
    },
    viewRow: function (id) {
      if (false && this.allowViewClick) {
        this.$router.push(this.basePath + '/' + id);
      }
    },
    async checkGetData() {

      Global.checkAuthenticated(this.$router.currentRoute.path).then((response) => {
        this.getData();
      });

    },
    async getData() {

      this.gettingData = true;
      this.gettingDataError = false;
      const queryString = this.buildQueryString();

      if (this.abortController) {
        this.abortController.cancel();
        this.abortController = null;
      }
      this.abortController = axios.CancelToken.source();

      try {
        const {data} = await this.api.get(this.dataSource + queryString, {
          cancelToken: this.abortController.token
        });

        this.data = typeof data !== 'undefined' && typeof data.rows !== 'undefined' ? this.mapRows(data.rows) : [];
console.log('DATA');
console.log(this.data);
        this.totalPages = data.totalPages;
        this.totalResults = data.totalData;
        this.tableHeaders = typeof data !== 'undefined' && typeof data.tableHeader !== 'undefined' ? this.mapHeaders(data.tableHeader) : [];
        this.gettingData = false;
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log(error);
          this.gettingData = false;
          this.gettingDataError = true;
          this.data = []
        }
      }
    },
    mapHeaders(headers) {
      let _headers = Object.values(headers);

      if (this.showCols) {
        _headers = [];
        Object.entries(headers).forEach(([col, label]) => {
          if (this.showCol(col)) {
            _headers.push(label)
          }
        })
      }

      if (this.hideCols) {
        Object.entries(headers).forEach(([col], ix) => {
          if (this.hideCol(col)) {
            _headers.splice(ix, 1);
          }
        })
      }

      return _headers;
    },
    mapRows(rows) {
      return rows.map((row) => this.mapRow(row));
    },
    mapRow(row) {
      let _row = row;

      if (this.showCols) {
        _row = {};
        Object.entries(row).forEach(([col, value]) => {
          if (this.showCol(col)) {
            _row[col] = value;
          }
        })
      }

      if (this.hideCols) {
        Object.entries(row).forEach(([col]) => {
          if (this.hideCol(col)) {
            delete _row[col];
          }
        })
      }

      return _row;
    },
    showCol(col) {
      return this.props.showCols.includes(col);
    },
    hideCol(col) {
      return this.props.hideCols.includes(col);
    },
    changePage(page, offset) {
      if (this.page !== page) {
        this.page = page;
        this.offset = offset
        this.getData();
      }
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    }
  },
  created() {
    this.resetWindow();
    this.filtersFromHash()

    eventBus.$on("sharedFilters", (filterData) => {
      this.filters = filterData;
      // this.getData();
    });

    this.title = this.componentData.props.title;

    if (!this.basePath) {
      this.basePath = this.$router.currentRoute.path;
    }

    this.rowClickable = this.allowViewClick ?? false;

    this.dataSource = this.dataSrc;
    this.api = useApi();
    //this.getData();
    this.getComponents();

  }
}
</script>
<style lang="scss">
  .icarus-card-table {
    width: 100%;
  }
</style>
