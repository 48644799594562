import {defineStore} from "pinia";
import {ref} from "vue";
import Global from "@/_core/services/global";

const STORE = 'global';

const useGlobalStore = defineStore(STORE, () => {
  const user = ref(null);
  const session = ref(null);

  async function fetchAndStoreGlobalData() {
    try {
      const globalData = await Global.getGlobalData()
      user.value = globalData.user;
      session.value = globalData.session;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    user,
    session,
    fetchAndStoreGlobalData
  };
});

export default useGlobalStore;
