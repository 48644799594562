export default {
  id: {
    type: String,
    required: false
  },
  formId: {
    type: String,
    default: '',
  },
  fieldName: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number, Boolean, null],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  validations: {
    type: Array,
    default: () => [],
  },
  help: {
    type: String,
    default: '',
  },
}
