<template>
    <div :class="classes" class="menu">
        <div v-if="title" class="menu-title" v-html="title"></div>
        <div v-for="group in menuItems" :class="`group group-${group.title.toLowerCase().replace(' ', '-')}`">
            <NavList v-if="showMenuHeader(group)" v-bind:group="group"></NavList>
        </div>
    </div>
</template>

<script setup>
import commonProps from "@/_core/components/_properties/common";
import {defineProps} from 'vue';

defineProps({
  ...commonProps,
  title: {
    type: String,
    default: '',
  },
  menuItems: {
    type: Array,
    required: true,
  }
})

const showMenuHeader = (group) => typeof group.links !== 'undefined';
</script>
