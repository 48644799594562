import telLinker from "@/services/telLinker";

function mapIcon(type) {
  switch (type) {
    case 'MOBILE':
      return '<i class="fa fa-fw fa-mobile" title="Mobile"></i>';
    case 'WORK':
      return '<i class="fa fa-fw fa-building" title="Work"></i>';
    case 'HOME':
      return '<i class="fa fa-fw fa-house" title="Home"></i>';
    case 'FAX':
      return '<i class="fa fa-fw fa-fax" title="Fax"></i>';
  }
}

export default function (cell, formatterParams) {
  let value = cell.getValue() || '';

  if (!value) {
    return '';
  }


  const data = Object.entries(value).map(([type, phone]) => {
    const {href, label} = telLinker(phone.replace(/[^0-9+]/, ''));

    const icon = mapIcon(type)

    return `${icon} <a href="${href}">${label}</a>`;
  }).join('<br/>');

  const div = document.createElement('div');
  div.innerHTML = data;

  return div;
}
