import eventBus from "../services/eventBus";

const processResponse = (response, callback) => {
  if (response.data) {
    if (response.data.hasOwnProperty('access_token')) {
      this.$store.commit('setAuthToken', response.data.access_token);
      if (response.data.hasOwnProperty('refresh_token')) {
        this.$store.commit('setRefreshToken', response.data.refresh_token);
      }
    }
    if (response.data.hasOwnProperty('messages')) {
      Object.values(response.data.messages).forEach((item) => {
        this.$store.commit('addMessage', item);
      })
    }
    if (response.data.hasOwnProperty('redirectToExternal')) {
      window.location = response.data.redirectToExternal;
    } else if (response.data.hasOwnProperty('redirect')) {
      if (response.data.redirect !== '' && response.data.redirect !== false) {
        this.$router.push(response.data.redirect);
        return null;
      }
      //return null; // removed as it was causing infinite spinner
    } else if (response.data.hasOwnProperty('refreshGlobal')) {
      eventBus.$emit("refreshGlobal")
    } else if (response.data.hasOwnProperty('refresh')) {
      this.$emit('refresh', true);
      this.$forceUpdate();
    }
  }

  if (typeof callback == "function") {
    callback();
  }

  return response;
};

const processError = (error) => {
  console.error(error);
  this.formIsSubmitting = false;
  if (typeof error.response !== 'undefined' && typeof error.response.data.messages !== 'undefined') {
    let formMessages = '';
    Object.keys(error.response.data.messages).forEach(function (key, index) {
      if (error.response.data.messages.hasOwnProperty(key)) {
        formMessages = formMessages + `<span class="error">${error.response.data.messages[key].content}</span><br/>`
      }
    });
    this.formMessages = formMessages;
  }
  this.formMessageType = 'error';
};

const apiFunctions = {
  methods: {
    processError,
    processResponse
  },
}

export {apiFunctions, processResponse, processError};
