<template>
<div class="icarus-modal">

  <div class="icarus-modal-btn">
    <a :class="buttonClass" v-html="buttonContent" @click="openModal($event)"></a>
    <span class="icarus-icon-count" v-html="iconCount" v-if="iconCount > 0"></span>
  </div>

  <div class="overlay icarus-modal-overlay" :class="{ active: isActive }">
    <div class="icarus-modal-content">
      <div class="icarus-modal-title">
        <h2 v-html="title"></h2>
        <a class="close" href="#" @click="closeModal($event)">&times;</a>
      </div>
      <div class="content icarus-modal-body">
        <!--<div v-html="">

        </div>-->
        <component v-bind:props="pageComponent.props" :componentId="pageComponent.componentId" :componentData="pageComponent" :components="pageComponent.components" :class="pageComponent.classes" :is="pageComponent.name" :fixedWidth="pageComponent.fixedWidth" v-for="(pageComponent, index) in pageComponents" :key="index"></component>

      </div>
    </div>
  </div>

</div>
</template>
<script>
  import eventBus from "../../services/eventBus";
  import {Api} from '../../services/api'
  import { commonProperties } from '../../mixins/commonProperties'
  import Global from "../../services/global";

    export default {
      name: 'Modal',
      props : [
        'props',
        'components',
        'componentData',
        'rowData'
      ],
      watch: {
        components (to, from){
          this.pageComponents = to;
        }
      },
      mixins: [commonProperties],
      methods: {
        initialState (){
            return {
              title: '',
              modalId: '',
              buttonContent: '',
              pageComponents: '',
              classes: '',
              buttonClass: 'btn',
              isActive: false,
              iconCount: 0,
              contentSrc: ''
            }
        },
        loadModal() {
          if (this.rowData) {
            let replacements = this.contentSrc.match(/[^{}]*.(?=})/g); // find/replace parameters in the url e.g. {MSMID} etc.
            if (replacements && replacements.length) {
              replacements.forEach((x, i) => this.contentSrc = this.contentSrc.replace("{" + x + "}", this.rowData[x]));
              this.contentSrc = this.contentSrc.replace("[", "");
              this.contentSrc = this.contentSrc.replace("]", "");
            }
          }
          Global.checkAuthenticated(this.$router.currentRoute.path).then((response) => {
            this.getSrcData();
          });
        },
        openModal(event) {
          if (event) {
            event.preventDefault();
          }
          this.isActive = true;
          if (this.contentSrc) { // load content over AJAX (this is a dynamic modal)
            this.loadModal();
          }
        },
        async getSrcData() {
          Api.get(this.contentSrc+'?='+Date.now())
              .then(response => {
                this.pageComponents = response.data.components;
              })
              .catch(error => {
                    console.log(error);
                  }
              );
        },
        closeModal(event) {
          if (event) {
            event.preventDefault();
          }
          this.isActive = false;
          eventBus.$emit("closeDropMenu", true);
        },
        setIconCount(iconCount) {
          this.iconCount = iconCount;
        },
        getComponents() {
          this.pageComponents = this.components;
        }
      },
      created() {
        this.title = this.componentData.props.title;
        this.modalId = this.componentData.props.modalId;
        this.buttonContent = this.componentData.props.buttonContent;
        if (typeof this.componentData.props.buttonClass !== 'undefined') {
          this.buttonClass = this.componentData.props.buttonClass;
        }
        if (typeof this.componentData.props.contentSrc !== 'undefined') {
          this.contentSrc = this.componentData.props.contentSrc;
        }
        this.getComponents();
        if(this.componentData){
          this.classes = this.componentData.classes;
        }

        eventBus.$on("closeModal", () => {
          this.closeModal(); // close modal if filters changed
        });
        eventBus.$on("reloadModal", () => {
          if (this.isActive === true) this.loadModal(); // close modal if filters changed
        });
      },
    }
</script>

<style lang="scss">

.icarus-modal-btn .btn {
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.icarus-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  text-align: center;
}


.icarus-modal-overlay:before {
  content:"";
  display:inline-block;
  width:0;
  height:100%;
  vertical-align:middle;
}

.icarus-modal-overlay.active {
  visibility: visible;
  opacity: 1;
}

.icarus-modal-content {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 1000;
  display:inline-block;
  vertical-align:middle;
  text-align: left;
}

.icarus-modal-content h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.icarus-modal-content .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.icarus-modal-content .close:hover {
  color: #06D85F;
}
.icarus-modal-content .icarus-modal-body {
  max-height: 50%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .icarus-modal-btn{
    width: 70%;
  }
  .icarus-modal-content{
    width: 70%;
  }
}

</style>