<template>
  <div class="icarus-dropmenu-wrapper">
    <div class="icarus-dropmenu">
      <span class="icarus-dropmenu-toggle" type="button" :class="{ expanded: expanded }" @click="toggleExpanded()">
        <i class="fa-solid fa-ellipsis fa-2x"></i>
      </span>
      <ul class="icarus-dropmenu-items">
        <li v-for="(item, field, index) in items" v-if="checkConstraint(item)"><a :target="item.target ? item.target : '_self'" class="dropdown-item" :class="item.class" @click="followLink(item)" :disabled="item.dynamicPdf && !document[item.title]">{{ item.title }}</a></li>
        <li v-for="(pageComponent, index) in pageComponents" >
          <component
              v-if="checkConstraint(pageComponent.props)"
              :componentId="pageComponent.componentId"
              :class="pageComponent.classes"
              :componentData="pageComponent"
              :components="pageComponent.components"
              :rowData="rowData"
              :is="pageComponent.name"
              :key="index"
              v-bind:props="pageComponent.props"
              ></component>
        </li>
        <li v-if="hasNoOptions"><small><em>No options</em></small></li>
      </ul>
    </div>
    <div class="overlay icarus-modal-overlay" :class="{ active: expanded }" @click="toggleExpanded()"></div>
  </div>
</template>
<script>

import {Api} from "../../services/api";
import { commonProperties } from '../../mixins/commonProperties'
import { apiFunctions } from "../../mixins/apiFunctions";
import {componentFunctions} from "../../mixins/componentFunctions";
import eventBus from "../../services/eventBus";

export default {
  name: 'DropMenu',
  props : [
    'rowData',
    'pageComponents'
  ],
  mixins: [
    commonProperties,
    apiFunctions,
    componentFunctions
  ],
  computed: {
    hasNoOptions() {
      let numItems = 0;
      if (typeof this.pageComponents != 'undefined') {
        for (let i = 0; i < this.pageComponents.length; i++) {
          if (this.checkConstraint(this.pageComponents[i].props)) {
            numItems++;
          }
        }
      }
      if (typeof this.items != 'undefined') {
        for (let i = 0; i < this.items.length; i++) {
          if (this.checkConstraint(this.items[i].props)) {
            numItems++;
          }
        }
      }
      return numItems == 0;
    }
  },
  methods: {
    initialState (){
      return {
        expanded: false,
        items: [],
        document: {},
        pageComponents: [],
      }
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].hasOwnProperty('dynamicPdf') && this.items[i].dynamicPdf) {
            this.document[this.items[i].title] = "";
            let url = this.items[i].route;
            let replacements = url.match(/[^{}]*.(?=})/g); // find/replace parameters in the url e.g. {MSMID} etc.
            if (replacements && replacements.length) {
              replacements.forEach((x, i) => url = url.replace("{" + x + "}", this.rowData[x]));
              url = url.replace("[", "");
              url = url.replace("]", "");
            }
            Api.get(url, {responseType: 'blob'})
                .then(response => {
                  this.document[this.items[i].title] = new Blob([response.data], { type: 'application/pdf' })
                })
                .catch(error => {
                      console.log(error);
                    }
                );
          }
        }
      }
    },
    checkConstraint(item) {

      if(typeof item != 'object' || !item.hasOwnProperty('if') || item.if === null) return true;

      var div = document.createElement("div");
      div.innerHTML = this.rowData[item.if.col];
      var text = div.textContent || div.innerText || "";
      if (item.if.hasOwnProperty('val')) {
      return item.if.val == text;
      } else if (item.if.hasOwnProperty('valLike')) {
        return text.includes(item.if.valLike);
      }

      return false;
    },
    openBlob(blob){
      if (window.navigator.msSaveOrOpenBlob) { //IE 11+
        window.navigator.msSaveOrOpenBlob(blob, "my.pdf");
      } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
        alert("Cannot display on FF iOS");
      } else if (navigator.userAgent.match('CriOS')) { //Chrome iOS
        var reader = new FileReader();
        reader.onloadend = function () { window.open(reader.result);};
        reader.readAsDataURL(blob);
      } else {
        console.log(blob);
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    },
    async followLink(item) {
      if (item.hasOwnProperty('if')) {
        var html = "<p>Some HTML</p>";
        var div = document.createElement("div");
        div.innerHTML = this.rowData[item.if.col];
        var text = div.textContent || div.innerText || "";

        console.log(text);
      }
      let url = item.route;
      if (this.event !== '') {
        url = url + '/' + this.event;
      }
      let replacements = url.match(/[^{}]*.(?=})/g); // find/replace parameters in the url e.g. {MSMID} etc.
      if (replacements && replacements.length) {
        replacements.forEach((x, i) => url = url.replace("{" + x + "}", this.rowData[x]));
        url = url.replace("[", "");
        url = url.replace("]", "");
      }
      if (item.hasOwnProperty('dynamicPdf') && item.dynamicPdf) {
        this.openBlob(this.document[item.title]);
      } else if(item.hasOwnProperty('method') && item.method == 'post'){
        this.icon = 'spinner fa-spin';
          Api.post(url)
              .then(response => {
                console.log(response);
                this.icon = 'check';
                this.classes = 'btn-success'
                this.processResponse(response, () => {

                });
              })
              .catch(error => {
                    this.url = 'cross';
                    this.classes = 'btn-error'
                  }
              );
      } else if (item.hasOwnProperty('target')) {
        window.open(url, item.target);
      } else {
        this.$router.push(url);
      }
    },
  },
  created() {
    eventBus.$on("closeDropMenu", () => {
      this.expanded = false;
    });
    if(this.props) {
      this.items = this.props.items ? this.props.items : '';
      this.event = this.props.event ? this.props.event : '';
    }
    this.getComponents();
  }
}
</script>
<style lang="scss">
.icarus-dropmenu {
  position: relative;
  overflow: visible;
  .icarus-dropmenu-items {
    position: absolute;
    right: 100%;
    top:0;
    padding: 7px 30px 7px 10px;
    background-color: #fff;
    border-radius: 5px;
    border-top-right-radius: 0;
    li {
      list-style-type: none;
      padding: 5px 0;
      a {
        font-weight: bold;
      }
    }
  }
  .icarus-dropmenu-toggle:not(.expanded) + .icarus-dropmenu-items {
    display: none;
  }
  .icarus-dropmenu-toggle.expanded + .icarus-dropmenu-items {
    display: block;
    z-index: 1000;
  }
  .icarus-dropmenu-toggle {
    padding-right: 10px;
    position: relative;
    &.expanded {
      background-color: #fff;
      border-radius: 20px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 1000;
    }
  }
}
</style>