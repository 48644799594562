export default function (cell, formatterParams) {
  let value = (cell.getValue());

  if (typeof value === 'undefined' || value === null) {
    return '';
  }

  return {
    [-3]: '---',
    [-2]: '--',
    [-1]: '-',
    [0]: 'Av',
    [1]: '+',
    [2]: '++',
    [3]: '+++',
  }[value] || '';
}
