<template>
  <div class="vue-form">
    <div v-if="title" class="formTitle" v-html="wrappedTitle"></div>
    <div v-if="formMessages" :class="formMessageType" class="generalFormMessages">
      <div class="message" v-html="formMessages"></div>
    </div>
    <form :class="formDetails.classes" @submit.prevent>
      <div v-for="(field, SinglefieldName) in formFields" class="form-group">
        <component :is="field.type + 'Field'"
                   v-if="!SinglefieldName.includes('fieldset') && !SinglefieldName.includes('fieldGroup')"
                   :key="SinglefieldName" :ref="SinglefieldName" :class="field.classes"
                   v-bind:props="{field: field}"></component>
        <div v-if="SinglefieldName.includes('fieldGroup')" class="form-row">
          <component :is="groupField.type + 'Field'" v-for="(groupField, groupFieldName) in field"
                     :key="groupFieldName" :ref="groupFieldName" :class="groupField.classes"
                     v-bind:props="{field: groupField}"></component>
        </div>
        <fieldset v-if="SinglefieldName.includes('fieldset') && field.inputType !== 'holding'"
                  :class="getFieldsetSetting(SinglefieldName, 'fieldset_classes')" class="row">
          <legend class="row" v-html="buildFieldsetLegend(SinglefieldName)"></legend>
          <div
              :id="SinglefieldName"
              :class="isCollapsible(getFieldsetSetting(SinglefieldName, 'is_collapsable'), getFieldsetSetting(SinglefieldName, 'is_collapsed'))">
            <component :is="field.type + 'Field'"
                       v-for="(field, SinglefieldName) in singleFields(field)"
                       :key="SinglefieldName" :ref="SinglefieldName"
                       :class="[field.classes]" v-bind:props="{field: field}"></component>
            <div v-for="(groupFields, groupName) in fieldGroups(field)" class="form-row">
              <component :is="groupField.type + 'Field'" v-for="(groupField, groupFieldName) in groupFields"
                         :key="groupFieldName" :ref="groupFieldName" :class="groupField.classes"
                         v-bind:props="{field: groupField}"></component>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="pollingResponse ml-5 font-weight-bold" v-html="pollMessageContent"></div>
      <div class="formButtons">
        <button v-if="(!formDetails.removeSubmit)" :disabled="formIsSubmitting" :loading="formIsSubmitting"
                class="btn btn-primary" type="submit" @click="submit">
          <img v-if="formIsSubmitting" class="form-submit loader-spinner" src="/assets/icons/loader.gif">
          <span v-else>{{ formDetails.submit }}</span>
        </button>
        <button v-if="(formDetails.clearButton)" class="btn btn-primary" @click="clearFormFields">
          {{ formDetails.clearButtonText }}
        </button>
      </div>
    </form>

    <div v-if="noticesTitle" class="contentTile mt-4">
      <div class="title"><h3 v-html="noticesTitle"></h3></div>
    </div>
    <div class="display-table__wrapper">
      <div class="display-table__content">
        <div v-for="notice in notices" class="display-table__row">
          <div v-for="content in notice" class="display-table__row-content">{{ content }}</div>
        </div>
      </div>
    </div>
    <component :is="pageComponent.name" v-for="(pageComponent, index) in pageComponents" :key="index"
               :class="pageComponent.classes" :componentData="pageComponent" :componentProps="pageComponent.props"
               :components="pageComponent.components" :fixedWidth="pageComponent.fixedWidth"
               v-bind:props="formFields"></component>
  </div>
</template>

<script>
//We also allow the api to inject custom components to handle form alterations on the fly, e.g. call backs, custom methods and so on.
import {apiFunctions} from '../../mixins/apiFunctions';
import {classFunctions} from '../../mixins/classFunctions';
import {commonProperties} from '../../mixins/commonProperties'
import {componentFunctions} from "../../mixins/componentFunctions";
import {formFunctions} from "../../mixins/formFunctions";
import {Api} from "../../services/api";

export default {
  name: 'Form',
  mixins: [
    apiFunctions,
    classFunctions,
    commonProperties,
    componentFunctions,
    formFunctions
  ],
  watch: {
    dataSource(to, from) {
      this.dataSource = to;
      this.getFormData();
    },
    formFields: {
      handler(to, from) {
        //on change, update the store.
        //we changed some fields so clear any displaying errors.
        if (this.hash(to) !== this.hash(from)) {
          this.formMessages = null;
          this.formMessageType = '';
          this.setFormSubmitTrigger();
        }
      },
      deep: true
    },
  },
  methods: {
    initialState() {
      return {
        wrappedTitle: "",
        dataSource: '',
        formFields: '',
        formDetails: {},
        header: 3,
        title: null,
        formMessages: null,
        formMessageType: '',
        formComponents: null,
        buildSubmitOnly: false,
        submittedData: '',
        asFilterString: null,
        formIsSubmitting: false,
        pollInterval: 1,
        pollTimeout: 30,
        pollSrc: '',
        pollResponseTarget: '',
        method: 'post',
        pollMessageContent: '',
        polling: null,
        notices: [],
        noticesPrev: [],
        noticesTitle: '',
      }
    },
    singleFields(field) {
      const {..._field} = field;
      Object.keys(field).forEach((key, value) => {
        if (!key.includes('fieldGroup') && !key.includes('fieldset') && key !== 'fieldset_settings') {
          _field[key] = value;
        }

      });

      return _field;
    },
    fieldGroups(field) {
      const {..._field} = field;
      Object.keys(field).forEach((key, value) => {
        if (key.includes('fieldGroup')) {
          _field[key] = value;
        }

      });

      return _field;
    },

    pollAction(eventId) {
      var pollUrl = this.pollSrc + '/' + eventId;

      this.polling = setInterval(function () {
        this.pollEndpoint(pollUrl);
      }.bind(this), this.pollInterval * 1000);

      setTimeout(function () {
        clearInterval(this.polling);
      }.bind(this), this.pollTimeout * 1000);
    },

    async pollEndpoint(pollUrl) {
      this.pollMessageContent = 'Awaiting response...'
      var response = await Api.get(pollUrl)
          .then(response => {
            this.processResponse(response);
            console.log('POLL RESPONSE:');
            console.log(response);
            if (typeof response.data.notices != 'undefined' && response.data.notices.length > 0) {
              console.log(response.data.notices);
              this.noticesPrev = this.notices;
              this.notices = response.data.notices;
              if (typeof this.notices[0][0] != "undefined" && typeof this.noticesPrev[0][0] != "undefined" && this.notices[0][0] != this.noticesPrev[0][0]) {
                this.pollMessageContent = "Response received.";
                this.formIsSubmitting = false;
                clearInterval(this.polling);
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.data = []
          });
    }
  },
  created() {
    if (this.props.header) {
      this.header = this.props.header;
    }
    if (this.props.title) {
      this.title = this.props.title;
    }
    if (this.props.buildSubmitOnly) {
      this.buildSubmitOnly = this.props.buildSubmitOnly;
    }
    this.dataSource = this.props.dataSrc;
    this.wrappedTitle = '<h' + this.header + '>' + this.title + '</h' + this.header + '>';
    if (typeof this.formDetails.verb !== 'undefined') {
      this.method = this.formDetails.verb;
    }
    if (this.props.pollSrc) {
      this.pollSrc = this.props.pollSrc;
      this.pollInterval = this.props.pollInterval;
      this.pollTimeout = this.props.pollTimeout;
    }
    if (this.props.notices) {
      this.notices = this.props.notices;
    }
    if (this.props.noticesTitle) {
      this.noticesTitle = this.props.noticesTitle;
    }
  },
};
</script>

<style lang="scss">
</style>
