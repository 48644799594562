import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'dropdowns';

const useDropdownStore = defineStore(STORE, () => {
  const dropdowns = ref({});

  function setDropdown(key, data) {
    dropdowns.value = {
      ...dropdowns.value,
      [key]: data,
    };
  }

  function hasDropdown(key) {
    return typeof dropdowns.value[key] !== 'undefined';
  }


  function getDropdown(key) {
    return dropdowns.value[key] || null;
  }

  return {
    setDropdown,
    hasDropdown,
    getDropdown,
  };

});

export default useDropdownStore;
