<template>
  <div class="form-field-container select-field-container" :class="classes">
    <label v-if="label" :for="id">{{label}}{{ required ? '*' : '' }}</label>
    <multiselect
      :id="id"
      :placeholder="placeholder ?? 'Choose one:'"
      :required="!!required"
      :disabled="!!disabled"
      v-model="fieldValue"
      :options="options"
      :custom-label="nameWithId"
      :class="{'form-input--error': !valid && isSubmitted}"
      @select="saveValue()"
      >
    </multiselect>
    <span v-if="!valid && isSubmitted" class="error text-error">{{ validationError }}</span>
    </div>
</template>

<script setup>
    import Multiselect from 'vue-multiselect'
import {onMounted, ref} from "vue";
import classesProp from '@/_core/components/_properties/classes';
import useFormStore from "@/_core/store/useFormStore";
import formFields from "../_properties/formFields";
import isValid from "@/_core/services/isValid";

let valid = ref(true);
let validationError = ref('');

const props = defineProps({
  ...classesProp,
  ...formFields,
  options: {
    type: Array,
    required: true
                },
});

const fieldValue = ref(props.value)
const fieldOptions = ref([])

const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue() {

  let value = (fieldValue.value.value ?? fieldValue.value).toString();
  console.log('VALUE: ' + value);
  const {validCheck, validError} = isValid(props.fieldName, props.validations, value);
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, value, validCheck, validationError);
}

function nameWithId ({ key, value }) {
                return `${value}`
                    }

onMounted(() => { // Initialise field in formStore - important
  saveValue();
})

</script>