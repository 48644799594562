<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId: visitId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <dt>Staff Member</dt>
      <dd>{{ staffMember }}</dd>

      <dt>Date</dt>
      <dd>{{ startTime.toDateString() }}</dd>

      <dt>Time</dt>
      <dd>{{ time(startTime) }} to {{ time(finishTime) }}</dd>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref,} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const props = defineProps({
  visitId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const staffMember = ref('');
const startTime = ref(new Date());
const finishTime = ref(new Date());

const {hydrate, hydrated} = useHydration(({staff_member, start, finish}) => {
  staffMember.value = staff_member;
  startTime.value = new Date(start);
  finishTime.value = new Date(finish);
});

function time(date) {
  return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
}

</script>
