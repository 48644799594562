const minMaxDateFilter = function (cell, onRendered, success, cancel, editorParams) {
  function buildValues(e) {
    const values = {
      start: start.value,
      end: end.value,
    };

    success(values);
  }

  const container = document.createElement("div");
  container.classList.add("min-max-filter");

  //create and style inputs
  const start = document.createElement("input");
  start.setAttribute("type", "date");
  start.setAttribute("placeholder", "Min");

  const end = document.createElement("input");
  end.setAttribute("type", "date");
  end.setAttribute("placeholder", "Max");

  start.addEventListener("change", buildValues);
  end.addEventListener("change", buildValues);

  container.appendChild(start);
  container.appendChild(end);

  return container;
};

export default minMaxDateFilter;
