<template>
  <div class="form-field-container photocapture-field-container" :class="classes">
    <div :class="[label ? 'form-group' : 'hidden-field-wrapper', {'form-group--error': false}]">
      <label v-if="label" class="form__label" :for="fieldName">{{label}}</label>

    <input
        accept="image/*"
        capture="environment"
        :class="{'form-input--error': !valid && isSubmitted}"
        v-on:change="saveValue"
        :disabled="!!disabled"
        class="form-control form__input"
        type="file"
        :name="fieldName"
        :placeholder="placeholder"
        :value="value"
        :id="id"/>
      <span v-if="!valid && isSubmitted" class="error text-error">{{ validationError }}</span>
    </div>
  </div>
</template>

<script setup>
import classesProp from '@/_core/components/_properties/classes';
import formFields from "../_properties/formFields";
import useFormStore from "@/_core/store/useFormStore";
import {onMounted, ref} from "vue";
import isValid from "@/_core/services/isValid";

const props = defineProps({
  ...classesProp,
  ...formFields,
});

let valid = ref(true);
let validationError = ref('');

const formStore = useFormStore();
const isSubmitted = ref(false);
formStore.$subscribe((mutation, state) => { // Subscribe to formSubmission via formStore
  isSubmitted.value = formStore.isFormSubmitted(props.formId);
})

function saveValue(event) {
  const {validCheck, validError} = isValid(props.fieldName, props.validations, event ? event.target.files[0].name : '');
  valid.value = validCheck;
  validationError.value = validError;
  const {setField} = formStore;
  setField(props.formId, props.fieldName, event ? event.target.files[0] : '', validCheck, validationError);
}

onMounted(() => { // Initialise field in formStore - important
  saveValue(null);
})


//move somewhere useful?
async function readFile(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      // error occurred
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    reader.onload = () => {
      // binary data
      resolve(reader.result);
    };
    reader.readAsText(file);
  });
}


</script>


