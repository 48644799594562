import {defineStore} from "pinia";
import {ref} from "vue";

const STORE = 'loading';

const useLoadingStore = defineStore(STORE, () => {
  const loading = ref(false);

  function setLoading(_loading) {
    loading.value = _loading;
  }

  return {loading, setLoading}
});

export default useLoadingStore;
