export default function (cell, formatterParams) {
  const {fields, separator = ', '} = formatterParams;
  const data = cell.getRow().getData();

  return [...new Set(fields.map(field => {
    if (typeof data[field] === 'undefined') {
      console.warn(`${field} is not present in row data`)
      return '';
    }

    return data[field].trim()
  }))].join(separator);
}
