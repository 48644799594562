<template>
    <div v-if="!!content" :class="classResolver(messageClass)" :style="`--toast-timeout: ${timeout}ms`"
         aria-atomic="true"
         aria-live="assertive" class="toast"
         role="alert">
        <button aria-label="Close" class="btn ml-2 mb-1 close" data-dismiss="toast" @click="removeMessage(storeKey)">
            <i aria-hidden="true" class="fal fa fa-times"></i>
        </button>
        <div class="toast-header">
            <strong v-if="iconClass" class="mr-auto"><i :class="iconClass"></i></strong>
            <small>{{ timeAgo }}</small>
        </div>
        <div class="toast-body" v-html="content"></div>
        <div class="toast__timeout"></div>
    </div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import useMessageStore from "@/_core/store/useMessageStore"
import moment from "moment";

const {removeMessage} = useMessageStore();

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
  },
  storeKey: {
    type: String,
    required: true,
  },
  time: {
    type: Number,
    required: true,
  },
  timeout: {
    type: Number,
    default: 5000,
  }
})

let _timeout = null;
let _interval = null;

const timeAgo = ref('');

const messageClass = computed(() => [
  `message-${props.type}`,
  `toast--${props.type}`,
]);

const iconClass = computed(() => {
  if (props.icon) {
    return `fal fa-fw  fa-${props.icon}`;
  }
  return '';
})


onMounted(() => {
  _timeout = setTimeout(() => removeMessage(props.storeKey), props.timeout)
  _interval = setInterval(() => {
    timeAgo.value = moment(props.time).from(moment(new Date()))
  }, 1000)
})

onUnmounted(() => {
  clearTimeout(_timeout);
  clearInterval(_interval);
})

</script>

<style lang="scss">
.messages {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 11;

  .toast {
    display: block;
    position: relative;
    width: 400px;
    padding: 10px 10px 10px 20px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    --toast-color: #{$brand--c1};
    border: 1px solid var(--toast-color);
    color: var(--toast-color);

    &--success {
      --toast-color: #{$brand-success};
    }

    &--error {
      --toast-color: #{$brand-danger};
    }

    &__timeout {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
      background-color: var(--toast-color);
      animation: timeout;
      animation-duration: var(--toast-timeout);
      animation-timing-function: linear;

      @keyframes timeout {
        from {
          right: 100%;
        }

        to {
          right: 0;
        }
      }
    }

    button {
      appearance: none;
      right: 20px;
      position: absolute;
      background: #fff;
      border: none;

      &:hover {
        color: $brand-danger;
      }

      button {

      }
    }
  }

}
</style>
