export default function (cell, formatterParams) {
  const value = cell.getValue();
  if (typeof value === 'number') {
    if (value < 1e3) return Number.isInteger(value) ? value : value.toFixed(2);
    if (value >= 1e3 && value < 1e6) return `${+(value / 1e3).toFixed(1)}K`;
    if (value >= 1e6 && value < 1e9) return `${+(value / 1e6).toFixed(1)}M`;
    if (value >= 1e9 && value < 1e12) return `${+(value / 1e9).toFixed(1)}B`;
    if (value >= 1e12) return `${+(value / 1e12).toFixed(1)}T`;
  }

  return value;
}
