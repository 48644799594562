<template>
  <div class="DataTable__wrapper">
    <div v-if="displayShortTotals" class="DataTable__ShortTotals">
      <span class="showing">Showing results</span>
      <span class="showing-numbers">{{ data.length }} of {{ pageInfo.totalResults }}</span>
    </div>

    <div class="data-content">
      <DynamicComponents :components="componentData.supportComponents || []"/>
      <component
          :is="'Pagination'"
          v-if="data.length"
          :key="`pager-one-${pageInfo.currentPage}`"
          :changePageFunc="changePage"
          :props="pageInfo"/>
      <div :id="componentId" :class="tileClass" class="table__div">
        <div class="request-messages">
          <LoadingSpinner v-if="gettingData"/>
          <LoadingError v-if="gettingDataError"/>
        </div>
        <div class="table__div__table-header">
          <div class="row table__div__table-row m-0">
            <div v-for="(value, key) in filteredTableHeaders"
                 :class="getColClasses([`table__div__col-${key}`], key)"
                 :style="{'min-width': colWidths[key] + 'px'}"
                 class="table__div__table-cell col"
                 v-html="value"
            />
          </div>
        </div>
        <div class="table__div__table-body">
          <div v-for="(row) in data" :class='row.active === "1" ? "active" : ""'
               class="table__div__table-row row"
          >
            <div v-for="(value, field, index) in filterRow(row)"
                 :class="[!allowViewClick ? 'no-click' : '', `table__div__col-${index}`] "
                 :style="{'min-width': colWidths[index] + 'px'}"
                 class="table__div__table-cell col"
                 @click="viewRow(row.id)"
            >
              <span v-if="typeof value === 'object' && value != null">
                  <router-link
                      v-if="value.href"
                      v-bind:to="value.href"
                      v-html="value.title"
                  />
                  <component
                      :is="value.component.name"
                      v-if="value.component"
                      :class="value.component.classes"
                      :components="value.component.components"
                      v-bind="value.component.props"
                  />
              </span>
              <span
                  v-else
                  :class="getColClasses(['table__div__table-cell__content'], index)"
                  v-html="value"
              />
            </div>
            <div class="table__div__col__components table__div__table-cell col">

              <DynamicComponents
                  :components="componentData.components"
                  :customData="{row: row}"
                  classes="table__div__col__components table__div__table-cell col"
              />
            </div>
          </div>
        </div>
      </div>
      <component
          :is="'Pagination'"
          v-if="data.length"
          :key="`pager-two-${pageInfo.currentPage}`"
          :changePageFunc="changePage"
          :props="pageInfo"
      />
    </div>
  </div>
</template>
<script>

import {useRouter} from "vue-router";
import commonProps from "@/_core/components/_properties/common";
import {computed, onMounted, ref, watch} from "vue";
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import LoadingSpinner from "@/_core/components/partials/LoadingSpinner.vue";
import LoadingError from "@/_core/components/partials/LoadingError.vue";
import useApi from "@/_core/services/api";
import eventBus from "@/_core/services/eventBus";
import useFilterStore from "@/_core/store/useFilterStore";
import useParamsInHash from "@/_core/services/paramsInHash";

/** How many px to assume per char for column widths */
const PX_PER_CHAR = 9;

export default {
  components: {
    LoadingError,
    LoadingSpinner,
    DynamicComponents
  },
  props: {
    ...commonProps,
    showPagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayShortTotals: {
      type: Boolean,
      required: false,
      default: false,
    },
    basePath: {
      type: String,
      required: false,
      default: null,
    },
    hideIdColumn: {
      type: Boolean,
      required: false,
      default: false
    },
    allowViewClick: {
      type: Boolean,
      required: false,
      default: true
    },
    tileBreak: {
      type: Number,
      required: false,
      default: 767.98
    },
    showCols: {
      type: Array,
      required: false,
      default: () => []
    },
    hideCols: {
      type: Array,
      required: false,
      default: () => []
    },
    dataSrc: {
      type: String,
      required: true,
    }
  },

  setup(props) {
    const {router, route} = useRouter();
    const filterStore = useFilterStore();

    const {paramsFromHash} = useParamsInHash();
    let abortController = null;
    const api = useApi();

    const colClasses = ref({});
    const colWidths = ref({});
    const compWidth = ref(0);
    const data = ref([]);
    const filters = ref({});
    const gettingData = ref(true);
    const gettingDataError = ref(false);
    const itemsPerPage = ref(20);
    const offset = ref(0);
    const page = ref(1);
    const tableHeaders = ref([]);
    const tileClass = ref('');
    const totalColWidth = ref(0);
    const totalPages = ref(1);
    const totalResults = ref(0);

    const basePath = computed(() => props.basePath || route.path);
    const filteredTableHeaders = computed(() => tableHeaders.value.filter(
        (val, ix) => !props.hideIdColumn || (props.hideIdColumn && ix !== 0)
    ));
    const filteredColumns = computed(() => tableHeaders.value.filter(
        (val, ix) => !props.hideIdColumn || (props.hideIdColumn && ix !== 0)
    ));

    filterStore.$subscribe((mutation, state) => {

      let props = {};
      Object.entries(state.filterParams).forEach(([field, value]) => {
        if (value) {
          props[field] = value;
        }
      });

      filters.value = props;
      getData();
    })

    const pageInfo = computed(() => ({
      currentPage: page.value,
      itemsPerPage: itemsPerPage.value,
      offset: offset.value,
      totalResults: totalResults.value,
      totalPages: totalPages.value,
      showPagination: props.showPagination
    }));

    function changePage(_page, _offset) {
      if (page.value !== _page) {
        page.value = _page;
        offset.value = _offset
        getData()
      }
    }

    function viewRow(id) {
      if (props.allowViewClick) {
        router.push(`${basePath}/${id}`);
      }
    }

    function detectComponentWidth() {
      let container = document.getElementById(props.componentId);
      compWidth.value = container !== null ? container.offsetWidth : compWidth.value;
    }

    function showCol(col) {
      return props.showCols.includes(col);
    }

    function hideCol(col) {
      return props.hideCols.includes(col);
    }

    function mapHeaders(headers) {
      let _headers = Object.values(headers);

      if (props.showCols.length) {
        _headers = [];
        Object.entries(headers).forEach(([col, label]) => {
          if (showCol(col)) {
            _headers.push(label)
          }
        })
      }

      if (props.hideCols.length) {
        Object.keys(headers).forEach((col, ix) => {
          if (hideCol(col)) {
            _headers.splice(ix, 1);
          }
        })
      }

      return _headers;
    }

    function mapRows(rows) {
      function mapRow(row) {
        let _row = row;

        if (props.showCols.length) {
          _row = {};
          Object.entries(row).forEach(([col, value]) => {
            if (showCol(col)) {
              _row[col] = value;
            }
          })
        }

        if (props.hideCols.length) {
          Object.entries(row).forEach(([col]) => {
            if (hideCol(col)) {
              delete _row[col];
            }
          })
        }

        return _row;
      }

      return rows.map((row) => mapRow(row));
    }


    async function getData() {
      function buildQueryString() {
        const params = new URLSearchParams();

        if (props.dataSrc.indexOf('page') === -1) {
          params.append('page', page.value)
        }
        if (props.dataSrc.indexOf('offset') === -1) {
          params.append('offset', offset.value)
        }
        if (props.dataSrc.indexOf('limit') === -1) {
          params.append('limit', itemsPerPage.value)
        }

        Object.entries(filters.value).forEach(([key, value]) => params.append(key, value));

        return (`${props.dataSrc.indexOf('?') === -1 ? '?' : '&'}${params.toString()}`);
      }

      gettingData.value = true;
      gettingDataError.value = false;
      const queryString = buildQueryString();

      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();

      try {
        const response = await api.get(`${props.dataSrc}${queryString}`, {
          signal: abortController.signal
        });

        data.value = mapRows(response.data.rows);
        totalPages.value = response.data.totalPages;
        totalResults.value = response.data.totalData;
        tableHeaders.value = mapHeaders(response.data.tableHeader);
        getMinColWidths();
        gettingData.value = false;
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error);
          gettingData.value = false;
          gettingDataError.value = true;
          data.value = []
        }
      }
    }

    function setColClasses(col, className) {
      colClasses.value[col] = [];

      if (!colClasses.value[col].includes(className)) {
        colClasses.value[col].push(className);
      }
    }

    function getMinColWidths() {
      if (data.value.length) {
        data.value.forEach(row => {
          Object.entries(row).forEach(([col, value], columnNo) => {
            if (typeof value === 'string') {
              const div = document.createElement("div");
              div.innerHTML = value;
              const cellText = div.textContent || div.innerText || "";
              const maxWidth = (cellText.length * PX_PER_CHAR);

              if (colWidths.value[columnNo]) {
                if (maxWidth > colWidths.value[columnNo]) {
                  colWidths.value[columnNo] = maxWidth;
                }
              } else {
                colWidths.value[columnNo] = (cellText.length * PX_PER_CHAR) + 20;
                //enforce no more than 320px min width.
              }
              if (colWidths.value[columnNo] && colWidths.value[columnNo] > 280) {
                colWidths.value[columnNo] = 280;
                setColClasses(columnNo, 'wrap-anywhere')
              }
            }
          });

          // All cols set? Set the max width for break points.
          totalColWidth.value = Object.values(colWidths.value).reduce((acc, cell) => acc + cell, 0);
          detectComponentWidth();
        });
      }
    }

    function getColClasses(classes, col) {
      return [
        ...classes,
        ...(colClasses.value[col] || [])
      ]
    }

    function filterRow(row) {
      if (!props.hideIdColumn) {
        return row;
      }

      const {..._row} = row;
      Object.keys(row).forEach(([field, value]) => {
        if (field !== 'id') {
          _row[field] = value;
        }
      });

      return _row;
    }

    function filtersFromHash() {
      filterStore.setFilterParams(paramsFromHash());
    }

    onMounted(() => {
      filtersFromHash();

      eventBus.$on("sharedFilters", (filterData) => {
        filters.value = filterData;
        getData();
      });

      getData();
    });

    return {
      changePage,
      colWidths,
      data,
      filteredColumns,
      filterRow,
      filteredTableHeaders,
      getColClasses,
      gettingData,
      gettingDataError,
      pageInfo,
      tileClass,
      viewRow,
    };
  },

}

</script>
