const moneyFilter = function (cell, onRendered, success, cancel, editorParams) {
  function buildValues(e) {
    const values = 100*(money.value.replace(/,/g, ""));

    success(values);
  }

  const container = document.createElement("div");
  container.classList.add("min-max-filter");

  const money = document.createElement("input");
  money.setAttribute("placeholder", "£");

  money.addEventListener("change", buildValues);
  container.appendChild(money);


  return container;
};

export default moneyFilter;
