<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId: parcelforceShipmentId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <ContactDetails
        :addressCountry="addressCountry"
        :addressLine1="addressLine1"
        :addressLine2="addressLine2"
        :addressLine3="addressLine3"
        :addressPostcode="addressPostcode"
        :addressTown="addressTown"
        :businessName="businessName"
        :contactName="contactName"
        :emailAddress="emailAddress"
        :fax="fax"
        :mobilePhone="mobilePhone"
        :telephone="telephone"
    />

    <template v-if="notifications.length">
      <label><strong>Notifications</strong></label>
      <ul>
        <li v-for="notification in notifications">{{ notification }}</li>
      </ul>
    </template>

    <dl>

      <dt>Address Only</dt>
      <dd>
        <TickOrCross :tick="addressOnly"/>
      </dd>

      <template v-if="specifiedNeighbour">
        <dt>Neighbour</dt>
        <dd>{{ specifiedNeighbour }}</dd>
      </template>

      <dt>Safe Place</dt>
      <dd>{{ safePlace || 'None' }}</dd>

      <template v-if="personalParcel">
        <dt>Personal</dt>
        <dd>{{ personalParcel }}</dd>
      </template>

    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref,} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";
import TickOrCross from "@/components/TickOrCross.vue";
import ContactDetails from "@/components/ContactDetails.vue";

const props = defineProps({
  parcelforceShipmentId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);


const contactName = ref('');
const businessName = ref('');
const addressLine1 = ref('');
const addressLine2 = ref('');
const addressLine3 = ref('');
const addressTown = ref('');
const addressPostcode = ref('');
const addressCountry = ref('');
const emailAddress = ref('');
const telephone = ref('');
const fax = ref('');
const mobilePhone = ref('');
const notifications = ref([]);
const addressOnly = ref(false);
const specifiedNeighbour = ref('');
const safePlace = ref('');
const personalParcel = ref('');


const {hydrate, hydrated} = useHydration(({
                                            contact_name: _contact_name,
                                            business_name: _business_name,
                                            address_line_1: _address_line_1,
                                            address_line_2: _address_line_2,
                                            address_line_3: _address_line_3,
                                            address_town: _address_town,
                                            address_postcode: _address_postcode,
                                            address_country: _address_country,
                                            email_address: _email_address,
                                            telephone: _telephone,
                                            fax: _fax,
                                            mobile_phone: _mobile_phone,
                                            notifications: _notifications,
                                            address_only: _address_only,
                                            specified_neighbour: _specified_neighbour,
                                            safe_place: _safe_place,
                                            personal_parcel: _personal_parcel,
                                          }) => {
  contactName.value = _contact_name;
  businessName.value = _business_name;
  addressLine1.value = _address_line_1;
  addressLine2.value = _address_line_2;
  addressLine3.value = _address_line_3;
  addressTown.value = _address_town;
  addressPostcode.value = _address_postcode;
  addressCountry.value = _address_country;
  emailAddress.value = _email_address;
  telephone.value = _telephone;
  fax.value = _fax;
  mobilePhone.value = _mobile_phone;
  notifications.value = _notifications;
  addressOnly.value = _address_only;
  specifiedNeighbour.value = _specified_neighbour;
  safePlace.value = _safe_place;
  personalParcel.value = _personal_parcel;
});

</script>
