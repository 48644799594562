<template>
  <DashboardPanel
      :hydrated="hydrated || null"
      :refreshParams="{entityId}"
      @refreshed="hydrate"
      @removed="emit('removed')"
  >
    <dl>
      <dt>Size</dt>
      <dd>{{ size }}</dd>

      <dt>Area</dt>
      <dd>{{ area }}</dd>

      <dt>Segments</dt>
      <dd>
        <ul>
          <li v-for="segment in segments" :key="segment.id">
            <a :href="segment.href">{{ segment.name }}</a>
          </li>
        </ul>
      </dd>
    </dl>
  </DashboardPanel>
</template>

<script setup>
import {defineProps, ref} from "vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import useHydration from "@/hooks/useHydration";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['removed']);

const size = ref('');
const area = ref('');
const segments = ref([]);

const {hydrate, hydrated} = useHydration(({size: _size, area: _area, segments: _segments}) => {
  size.value = _size;
  area.value = _area;
  segments.value = _segments;
});

</script>
